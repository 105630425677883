import styled from '@emotion/styled'
import React from 'react';
import Draggable from "react-draggable";

export const RaisedRectangle = styled.div`
    padding: 20px;
    background: ${(props) => props.theme.solidbg};
    border-radius: 16px;
    overflow: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;


// Assuming your existing FlatRectangle component
export const FlatRectangle = styled.div`
    position: relative; /* Add this line */
    display: inline-block;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    background: ${(props) => props.theme.outerbg};
    border-radius: 16px;
    overflow: auto;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
`;


export const DraggableWindow = ({children, defaultPosition, defaultSize}) => {
    return (
        <Draggable handle=".dragHandle">
            <Content>{children}</Content>
        </Draggable>
    );
};


// Updated FlatRectangleWithTitle component
export const FlatRectangleWithTitle = ({title, children, onClose, ...props}) => {
    return (
        <FlatRectangle {...props}>
            <TitleBar className="dragHandle">
                {title}
                <div className="close-button" onClick={() => onClose?.()}>×</div>
            </TitleBar>
            <Content>{children}</Content>
        </FlatRectangle>
    );
};


// Updated TitleBar for absolute positioning
export const TitleBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.titlebg};
    color: ${(props) => props.theme.titlefg};
    font-size: 16px;
    padding: 5px 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; /* Adjusted for centering */
    font-family: 'Nunito', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 16px 16px 0 0;

    /* Adjustments for centering title with close button */

    &:before, .close-button {
        content: ""; /* Invisible mirror element */
        display: inline-block;
    }

    .close-button {
        content: "×"; /* Visible close button */
        cursor: pointer;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        position: absolute;
        right: 0; /* Position the close button on the right */
    }
`;

const Content = styled.div`
    padding-top: 5px; /* Add padding to avoid content overlapping with the absolutely positioned TitleBar */
    // Additional styling for content if needed
`;
