import styled from '@emotion/styled'
import {keyframes} from "@emotion/css";

export const fadeInAnimation = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
`;

export const Circle = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    background: conic-gradient(
        color1 0deg 30deg,
        color2 30deg 60deg,
        color3 60deg 90deg,
        color4 90deg 120deg,
        color5 120deg 150deg,
        color6 150deg 180deg,
        color7 180deg 210deg,
        color8 210deg 240deg,
        color9 240deg 270deg,
        color10 270deg 300deg,
        color11 300deg 330deg,
        color12 330deg 360deg
    );
    &.large {
        width: 98%;
        height: 98%;
    }
    &.small {
        width: 70%;
        height: 70%;
    }
    background-color: white;
`;

export const CalendarLabel = styled.div`
    position: absolute;
    font-size: 2.5vw;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    color: #555555;
`;

export const CalendarNumbers = styled.div``;

export const HourCircleContainer = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin: 0 auto;
`;


export const CenteredContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(-10%);
    text-align: center;
    font-size: 0.8vw;
`;