import React, {createContext, useState, useContext, useEffect} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import {Avatar, AvatarGroup, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {useUsers} from "../ListBoxes/userService";
import {calculateTimeDifferenceInMinutes} from "../ListBoxes/userListBox";

export const OnlineUsersContext = createContext();

export const OnlineUsersProvider = ({user, children}) => {
    const [onlineUsers, setOnlineUsers] = useState({});
    const me = () => user;

    useEffect(() => {
        const presenceRef = firebase.database().ref('/presence/');
        presenceRef.on('value', snapshot => {
            if (snapshot.exists()) {
                const updatedOnlineUsers = {};

                snapshot.forEach(userSnapshot => {
                    const userId = userSnapshot.key;
                    const userInfo = userSnapshot.val();
                    const sessions = userInfo.sessions || {};

                    let mostRecentLastActive = null;
                    let isUserOnline = false; // Assume the user is not online initially

                    Object.values(sessions).forEach(session => {
                        const isOnline = session.status === 'online';
                        if (isOnline) {
                            isUserOnline = true; // User is online if any session is online
                            const lastActive = parseInt(session.lastActive, 10);
                            if (!isNaN(lastActive)) {
                                // Update if this session's lastActive is the most recent
                                if (mostRecentLastActive === null || lastActive > mostRecentLastActive) {
                                    mostRecentLastActive = lastActive;
                                }
                            }
                        }
                    });

                    if (isUserOnline && mostRecentLastActive !== null) {
                        updatedOnlineUsers[userId] = {
                            ...userInfo,
                            status: 'online',
                            lastActive: mostRecentLastActive,
                        };
                        // Optionally, remove session data if not needed
                        delete updatedOnlineUsers[userId].sessions;
                    }
                });

                setOnlineUsers(updatedOnlineUsers);
            }
        });

        // Cleanup on unmount
        return () => presenceRef.off('value');
    }, [user]);


    return (
        <OnlineUsersContext.Provider value={{onlineUsers, me}}>
            {children}
        </OnlineUsersContext.Provider>
    );
};

export const useOnlineUsers = () => {
    const context = useContext(OnlineUsersContext);
    if (context === undefined) {
        throw new Error('useOnlineUsers must be used within an OnlineUsersProvider');
    }
    return context;
};

export const isUserOnline = (user, onlineUsers) => {
    return onlineUsers[user.id] && onlineUsers[user.id].status === 'online';
};

export const userLastActive = (user, onlineUsers) => {
    return onlineUsers[user.id]?.lastActive;
};

export const UsersWithSameActivity = ({ currentActivity, email = '',setUserData = null}) => {
    const [usersHere, setUsersHere] = useState([]);
    const [userPositions, setUserPositions] = useState([]);
    const { users } = useUsers(true);
    const { onlineUsers } = useOnlineUsers();


        useEffect(() => {
        const presenceRef = firebase.database().ref('/presence/');

        const handlePresenceUpdate = snapshot => {
            const allPresenceData = snapshot.val() || {}; // Ensure it's an object
            const filteredUsers = users.filter(user => {
                const userInfo = allPresenceData[user.id];
                // Added check for null/undefined userInfo
                if (!userInfo || typeof userInfo !== 'object' || !userInfo.sessions) return false;

                // Iterate over sessions to check for activity and optional email match
                return Object.values(userInfo.sessions).some(session => {
                    const activityMatch = session.activity === currentActivity;
                    const emailMatch = email ? session.email === email : true;
                    return activityMatch && emailMatch;
                });
            });

            setUsersHere(filteredUsers);

            const usersAndPositions = filteredUsers.map(user => {
                // Extract sessions and create an array of objects containing both position and session ID
                const positions = Object.entries(allPresenceData[user.id].sessions)  // Using Object.entries to access session keys
                    .filter(([sessionId, session]) => session.position)  // Ensure the session has a position
                    .map(([sessionId, session]) => {
                        return {
                            start: session.position.start,
                            end: session.position.end,
                            sessionId: sessionId,
                        };
                    });
                return { id: user.id, photoURL: user.photoURL, positions: positions };
            });

            setUserPositions((usersAndPositions))
        };

        presenceRef.on('value', handlePresenceUpdate);

        // Cleanup on component unmount
        return () => presenceRef.off('value', handlePresenceUpdate);
    }, [currentActivity, users, email]); // Rerun when currentActivity, users, or email changes


    useEffect(() => {
        if(setUserData !== null){
            setUserData(userPositions)
        }
    },[userPositions]);

    return (
        <AvatarGroup max={8}>
            {usersHere.map(user => {
                const lastActive = userLastActive(user, onlineUsers);
                const timeDiff = calculateTimeDifferenceInMinutes(lastActive);
                const isInactive = timeDiff > 10; // Consider user inactive if last active was more than 10 minutes ago
                return (
                    <Avatar
                        key={user.id}
                        alt={user.name || 'Unknown'}
                        src={user.photoURL}
                        sx={{
                            width: '32px',
                            height: '32px',
                            filter: isInactive ? 'grayscale(100%)' : 'none',
                        }}
                        title={user.name || 'Unknown User'}
                    />
                );
            })}
        </AvatarGroup>
    );
};