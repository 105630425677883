import React, {useState, useEffect, createContext, useContext} from 'react';
import '../TabStyles.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/database';
import CodeList from "../Codes/codesForm";
import AuditLogViewer from "../Logs/logsform";
import {Monthly} from "../Booking/monthly";
import {AppContainer, MainContent} from "../Styles/app";
import {Video} from "../Styles/videobackground";
import {Menu} from "../Menu/menudrawer";
import {Today} from "../Booking/today";
import QuoteOfTheDay from "../Quote/quote";
import Daily from "../Booking/daily";
import {Profile} from "../Profile/profile";
import {OverviewPieChart} from "../Reports/monthlyoverview";
import {getFunctions} from "firebase/functions";
import Authentication, {getUserWithRetries} from "../Authentication/Authentication";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import {getUserByEmail} from "../ListBoxes/userService";
import {getAuth, onAuthStateChanged} from 'firebase/auth';
import {UserManagementApp} from "../Users/userSetup";
import {OnlineUsersProvider} from "../Presence/presence";
import UAParser from 'ua-parser-js';
import {doc, onSnapshot} from "firebase/firestore";
import {unpackWorkingHours} from "../Users/data";

export const ssUserType = 'userType'
export const ssUserSessionId = 'userSessionId'

export const useFirebase = () => useContext(FirebaseContext);

const FirebaseContext = createContext({
    app: null, // Firebase app instance
    functions: null, // Cloud Functions instance
    isInitialized: false, // Initialization state
});

export const FirebaseProvider = ({ children }) => {
    const [app, setApp] = useState(null);
    const [functions, setFunctions] = useState(null); // Add this line
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        // Initialize Firebase
        // eslint-disable-next-line no-undef
        const app = firebase.initializeApp(__firebaseConfig);
        setApp(app);
        // Initialize functions after app
        const functionsInstance = getFunctions(app, 'europe-west2');
        setFunctions(functionsInstance); // Set functions state
        setIsInitialized(true);
    }, []);

    return (
        <FirebaseContext.Provider value={{ app, functions, isInitialized }}>
            {children}
        </FirebaseContext.Provider>
    );
};



export async function updateUserActivity(activity) {
    const sessionId = sessionStorage.getItem(ssUserSessionId);
    const user = firebase.auth().currentUser;
    if (user && sessionId) {
        const sessionSnapshot = await firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/isValid`).get();
        // Check if the session exists and is valid
        if (sessionSnapshot.exists() && sessionSnapshot.val() === true) {
            const userActivityRef = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/activity`);
            await userActivityRef.set(activity);
            const userLastActive = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/lastActive`);
            await userLastActive.set(firebase.database.ServerValue.TIMESTAMP)
        }
    }
}

export async function updateUserSelected(email) {
    const sessionId = sessionStorage.getItem(ssUserSessionId);
    const user = firebase.auth().currentUser;
    if (user && sessionId) {
        const sessionSnapshot = await firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/isValid`).get();
        // Check if the session exists and is valid
        if (sessionSnapshot.exists() && sessionSnapshot.val() === true) {
            const userEmailRef = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/email`);
            await userEmailRef.set(email);
            const userLastActive = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/lastActive`);
            await userLastActive.set(firebase.database.ServerValue.TIMESTAMP)
        }
    }
}

export async function updateUserPosition(position) {
    const sessionId = sessionStorage.getItem(ssUserSessionId);
    const user = firebase.auth().currentUser;
    if (user && sessionId) {
        const sessionSnapshot = await firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/isValid`).get();
        // Check if the session exists and is valid
        if (sessionSnapshot.exists() && sessionSnapshot.val() === true) {
            const userPosition = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/position`);
            await userPosition.set(position);
            const userLastActive = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}/lastActive`);
            await userLastActive.set(firebase.database.ServerValue.TIMESTAMP)
        }
    }
}








async function cleanUpInvalidSessions(userId) {
    const sessionsRef = firebase.database().ref(`/presence/${userId}/sessions`);
    const sessionsSnapshot = await sessionsRef.once('value');
    if (sessionsSnapshot.exists()) {
        let cleanupPromises = [];
        sessionsSnapshot.forEach((sessionSnapshot) => {
            if (!sessionSnapshot.child('isValid').val()) {
                console.log(`Cleaning up session: ${sessionSnapshot.key}`);
                cleanupPromises.push(sessionSnapshot.ref.remove());
            }
        });
        await Promise.all(cleanupPromises);
        console.log("Session cleanup complete.");
    }
}


function getBrowserAndOS() {
    const parser = new UAParser();
    const result = parser.getResult();

    const browserName = result.browser.name;
    const browserVersion = result.browser.version;
    const osName = result.os.name;
    const osVersion = result.os.version;

    return {
        browser: `${browserName} ${browserVersion}`,
        os: `${osName} ${osVersion}`
    };
}

function addSessionIdToLocalStorage(sessionId) {
    let sessionIds = JSON.parse(localStorage.getItem('sessionIds')) || [];
    if (!sessionIds.includes(sessionId)) {
        sessionIds.push(sessionId);
        localStorage.setItem('sessionIds', JSON.stringify(sessionIds));
    }
}


async function invalidateFirebaseSessions() {
    const sessionIds = JSON.parse(localStorage.getItem('sessionIds')) || [];
    const updates = {};

    sessionIds.forEach(sessionId => {
        updates[`/presence/${firebase.auth().currentUser.uid}/sessions/${sessionId}/isValid`] = false;
    });

    await firebase.database().ref().update(updates);

    // Clear the stored session IDs after updating
    localStorage.removeItem('sessionIds');
}


function App() {
    const [sessionUser, setSessionUser] = useState(null);
    const [selectedContent, setSelectedContent] = useState('');
    const [loading, setLoading] = useState(true);
    const [mode, setModeInternal] = useState('default');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(true);

    const listenForAuthChanges = () => {
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                console.log('Presence detected:', user.email);

                await cleanUpInvalidSessions(user.uid)

                let sessionId = sessionStorage.getItem(ssUserSessionId);

                if (!sessionId) {
                    sessionId = firebase.database().ref().push().key;
                    sessionStorage.setItem(ssUserSessionId, sessionId);
                }

                addSessionIdToLocalStorage(sessionId)

                const sessionUser = await getUserWithRetries(user.email, 5, 2000); // 5 retries, 2000ms delay
               // const sessionUser = await getUserByEmail(user.email);
                sessionStorage.setItem(ssUserType, sessionUser.userType);

                const userSessionRef = firebase.database().ref('/presence/' + user.uid + '/sessions/' + sessionId);

                // Listen for the session's isValid property changes
                const sessionValidRef = userSessionRef.child('isValid');
                sessionValidRef.on('value', async snapshot => {
                    if (snapshot.exists() && snapshot.val() === false) {
                        // If session is marked as invalid, perform logout
                        console.log('Session marked as invalid. Logging out...');
                        await handleSignOut();
                    }
                });

                firebase.database().ref('.info/connected').on('value', function (snapshot) {
                    if (snapshot.val() === true) {
                        userSessionRef.onDisconnect().remove()
                            .then(() => {
                                const { browser, os } = getBrowserAndOS();
                                userSessionRef.set({
                                    status: 'online',
                                    lastActive: firebase.database.ServerValue.TIMESTAMP,
                                    browser: browser,
                                    os: os,
                                    isValid: true
                                });
                            });
                    }
                });


            } else {
                console.log('User is signed out');
            }
        });
    };


    async function handleSignOut() {
        // Additional safety checks can be performed here
        const sessionId = sessionStorage.getItem(ssUserSessionId);
        const user = firebase.auth().currentUser;
        if (user && sessionId) {
            const userSessionRef = firebase.database().ref(`/presence/${user.uid}/sessions/${sessionId}`);
            await userSessionRef.remove(); // Ensure this operation completes
            sessionStorage.removeItem(ssUserSessionId);
            sessionStorage.removeItem(ssUserType);
            await invalidateFirebaseSessions();
            await cleanUpInvalidSessions(user.uid)
            firebase.auth().signOut().then(() => {
                sessionStorage.clear()
                localStorage.clear()
                window.location.reload();
                window.location.href = '/login';
            });
        }
    }


    // A function to encapsulate setting the mode
    const setMode = (newMode) => {
        if (newMode === 'default') {
            // System preference
            const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
            setModeInternal(prefersDarkMode ? 'dark' : 'light');
        } else {
            // User preference (dark/light)
            setModeInternal(newMode);
        }
    };


    // Listen for changes to userType and refresh the Token
    useEffect(() => {
        const unsubscribeAuth = firebase.auth().onAuthStateChanged(user => {
            if (user) {
                const userRef = firebase.firestore().collection('users').doc(user.uid);
                const unsubscribeFirestore = userRef.onSnapshot(doc => {
                    const userData = doc.data();
                    const userType = sessionStorage.getItem('ssUserType')
                    if (userData && userData?.userType !== userType) {
                        user.getIdToken(true).then(() => {
                            console.log('Token refreshed due to userType change.');
                            sessionStorage.setItem('ssUserType', userData.userType);
                        }).catch(error => {
                            console.error('Error refreshing token:', error);
                        });
                    }
                });
                return () => unsubscribeFirestore();
            }
        });
        return () => unsubscribeAuth();
    }, []);


    useEffect(() => {
        listenForAuthChanges();
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                // Start of login process
                setIsLoggingIn(true);

                const fetchUserData = async (email, attemptsLeft = 5) => {
                    const userData = await getUserByEmail(email);
                    if (userData) {
                        setSessionUser(userData);
                        setMode(userData?.darkmode || 'default');
                        setLoading(false);
                        setIsLoggingIn(false); // End of login process
                    } else if (attemptsLeft > 0) {
                        setTimeout(() => fetchUserData(email, attemptsLeft - 1), 3000);
                    } else {
                        setSessionUser(null);
                        setLoading(false);
                        setIsLoggingIn(false); // End of login process after retries
                    }
                };
                fetchUserData(currentUser.email);
            } else {
                setSessionUser(null);
                setLoading(false);
                setIsLoggingIn(false); // No user detected
            }
        });
        return () => unsubscribe();
    },[]);


    useEffect(() => {
        updateUserActivity(selectedContent)
        updateUserSelected("")
    },[selectedContent]);


    useEffect(() => {
        const handleChange = (e) => {
            if (mode === 'default') {
                setModeInternal(e.matches ? 'dark' : 'light');
            }
        };

        const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
        mediaQuery.addEventListener('change', handleChange);
        // Initialize mode based on current system preference if mode is 'default'
        if (mode === 'default') {
            setMode('default');
        }

        return () => mediaQuery.removeEventListener('change', handleChange);
    }, [mode]);

    useEffect(() => {
        document.title = "Thrive Therapy";
    }, []);


    const handleClose = () => {
        setSelectedContent('')
        setDrawerOpen(true)
    };

    useEffect(() => {
        let unsubscribe = () => {};

        if (sessionUser?.id) {
            const userRef = doc(firebase.firestore(), "users", sessionUser.id);
            unsubscribe = onSnapshot(userRef, async (doc) => {
                if (doc.exists()) {
                    // Update sessionUser with the new data
                    const data = doc.data()
                    const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
                    setSessionUser({...sessionUser, ...data,workingHours});
                    setMode(doc.data().darkmode)
                } else {
                    // Handle the case where the document does not exist
                    console.log("No such document!");
                }
            }, (error) => {
                // Handle any errors
                console.error("Error getting document:", error);
            });
        }

        // Cleanup subscription on component unmount
        return () => unsubscribe();
    }, [sessionUser?.id]);

    const renderContent = (onCloseWindow) => {

        document.title = "Thrive Therapy"

        switch (selectedContent) {
            case 'chart':
                document.title = "Thrive Therapy - Reporting"
                return <OverviewPieChart onCloseWindow={onCloseWindow}/>
            case 'therapists':
                document.title = "Thrive Therapy - Monthly"
                return <Monthly onCloseWindow={onCloseWindow}/>
            case 'triage':
                document.title = "Thrive Therapy - Daily"
                return <Daily onCloseWindow={onCloseWindow}/>
            case 'today':
                document.title = "Thrive Therapy - Today"
                return <Today onCloseWindow={onCloseWindow}/>;
            case 'profile':
                document.title = "Thrive Therapy - Profile"
                return <Profile onCloseWindow={onCloseWindow} setUserMode={setMode}
                                handleSignOut={handleSignOut}/>
            case 'codes':
                document.title = "Thrive Therapy - Codes"
                return <CodeList onCloseWindow={onCloseWindow}/>
            case 'audit':
                document.title = "Thrive Therapy - Logs"
                return <AuditLogViewer onCloseWindow={onCloseWindow}/>
            case 'users':
                document.title = "Thrive Therapy - Users"
                return <UserManagementApp onCloseWindow={onCloseWindow}/>
            default:
                return <div></div>;
        }
    };

    const file = mode === 'dark' ? 'darkbackground.mp4' : 'background.mp4'


    const theme = createTheme({
        palette: {
            mode: mode,
        }
    });

   //const theme = getTheme(mode)




    if (loading) {
        return  <FirebaseProvider/>
    }

    const defaultBackgroundColors = { top: '#FFFFFF', bottom: '#FFFFFF' };
    const backgroundStyle = {
        backgroundImage: `linear-gradient(to bottom, ${sessionUser?.backgroundColors?.top || defaultBackgroundColors.top}, ${sessionUser?.backgroundColors?.bottom || defaultBackgroundColors.bottom})`
    };

    if (sessionUser) {
        sessionUser.movieBackground = sessionUser.hasOwnProperty('movieBackground') ? sessionUser.movieBackground : true;
        sessionUser.dailyQuote = sessionUser.hasOwnProperty('dailyQuote') ? sessionUser.dailyQuote : true;
    }

    return (
        <FirebaseProvider>
            <ThemeProvider theme={theme}>
                <OnlineUsersProvider user={sessionUser}>
                    <CssBaseline>
                        {sessionUser && !sessionUser?.movieBackground && (
                            <div style={{
                                ...backgroundStyle,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: -1
                            }}/>)}

                        {sessionUser && sessionUser?.movieBackground && (
                            <Video autoPlay muted loop playsInline className="video" key={mode} preload="metadata">
                                <source src={file} type="video/mp4"/>
                                Your browser does not support the video tag.
                            </Video>
                        )}

                        {/* <Overlay/> */}
                        {sessionUser && sessionUser.dailyQuote && (<QuoteOfTheDay user={sessionUser}/>)}

                        <AppContainer>
                            {sessionUser === null ? (
                                <Authentication mode={mode} login={isLoggingIn}/>
                            ) : (
                                <>
                                    <Menu user={sessionUser}
                                          selectedContent={selectedContent}
                                          setSelectedContent={setSelectedContent}
                                          drawerOpen={drawerOpen}
                                          setDrawerOpen={setDrawerOpen}
                                    />
                                    <MainContent>
                                        {renderContent(handleClose)}
                                    </MainContent>
                                </>
                            )}
                        </AppContainer>
                    </CssBaseline>
                </OnlineUsersProvider>
            </ThemeProvider>
        </FirebaseProvider>
    );

}

export default App;
