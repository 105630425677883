import {addDoc, collection, deleteDoc, doc, getDocs, onSnapshot, updateDoc} from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import {useEffect, useState} from "react";

// Fetch Codes
export const fetchCodes = async () => {
    const db = firebase.firestore(); // Get a reference to the Firestore.js service
    const codesCol = collection(db, 'codes'); // Assuming 'codes' is the Firestore.js collection name
    const snapshot = await getDocs(codesCol);
    return snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));
};

export const useCodes = () => {
    const [codes, setCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const db = firebase.firestore();
        const codesCol = firebase.firestore().collection('codes');

        const unsubscribe = codesCol.onSnapshot((snapshot) => {
            const codesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
                .sort((a, b) => a.text.localeCompare(b.text)); // Sorting here by text property

            setCodes(codesList);
            setLoading(false);
        }, (error) => {
            console.error("Error fetching codes: ", error);
            setError(error);
            setLoading(false);
        });

        // Cleanup function to unsubscribe from the collection when the component unmounts
        return () => unsubscribe();
    }, []); // Empty dependency array means this effect runs once on mount

    return { codes, loading, error };
};



// Add New Code with Code Number
export const addCode = async (newCodeData) => {
    try {
        const db = firebase.firestore();
        const codesCol = collection(db, 'codes');
        await addDoc(codesCol, newCodeData);
    } catch (error) {
        console.error('Error adding code:', error);
        throw error; // Handle the error as needed
    }
};

// Edit Existing Code with Code Number
export const editCode = async (codeId, updatedCodeData) => {
    try {
        const db = firebase.firestore();
        const codeDocRef = doc(db, 'codes', codeId);
        await updateDoc(codeDocRef, updatedCodeData);
    } catch (error) {
        console.error('Error editing code:', error);
        throw error; // Handle the error as needed
    }
};

// Delete Code
export const deleteCode = async (codeId) => {
    try {
        const db = firebase.firestore();
        const codeDocRef = doc(db, 'codes', codeId); // Assuming 'codes' is the Firestore.js collection name
        await deleteDoc(codeDocRef);
    } catch (error) {
        console.error('Error deleting code:', error);
        throw error; // Handle the error as needed
    }
};

export const newCode = {
    code : "--",
    foreground: "#000000",
    background: "#ffffff",
    reserved: false,
    text:"New Code",
    emoji:''
};


export const createDefaultCode = async (e) => {
    e.preventDefault();
    try {
        const db = firebase.firestore();
        const docRef = await db.collection('codes').add(newCode);
        console.log("Document written with ID: ", docRef.id);
        return docRef.id; // Returns the new document's ID
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};



