import styled from '@emotion/styled'
import {sidebarDefaultWidth} from "./sidebar";

export const AppContainer = styled.div`
    display: flex; 
    position: relative;
    z-index: 1; 
    height:100vh;
    width:100vw;
`;

export const MainContent = styled.div`
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const BookingContent = styled.div`
    flex-grow: 1;
    margin-right: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


