export function hexToRgb(hex) {
    let r = 0, g = 0, b = 0;
    // 3 digits
    if (hex.length === 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
    }
    // 6 digits
    else if (hex.length === 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
    }
    return [r, g, b];
}

function luminance(r, g, b) {
    const a = [r, g, b].map(function(v) {
        v /= 255;
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

export function getContrastingColor(hex) {
    const [r, g, b] = hexToRgb(hex);
    return luminance(r, g, b) > 0.5 ? '#000000' : '#FFFFFF';
}


export function calculateMidpointColor(color1, color2, alpha = 1) {
    // Convert hex to RGB
    const hexToRgb = hex => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    };

    // Get RGB values for both colors
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    // Calculate the midpoint
    const midRgb = rgb1.map((val, index) => Math.round((val + rgb2[index]) / 2));

    // Return as rgba
    return `rgba(${midRgb[0]}, ${midRgb[1]}, ${midRgb[2]}, ${alpha})`;
}
