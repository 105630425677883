import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {userCollectionPath} from "../Firestore/paths";


// Packs working hours into an integer
export const packWorkingHours = (hours) => {
    let packedValue = 0;
    for (let hour = 0; hour < 24; hour++) {
        if (hours && hours[hour]) {
            packedValue |= (1 << hour); // Set bit to 1 if working hour is true
        }
    }
    return packedValue;
};



export const saveUser = async (originalUser) => {
    try {
        // Create a deep copy of the user object to avoid modifying the original
        const user = JSON.parse(JSON.stringify(originalUser));

        const db = firebase.firestore();
        const userCollection = db.collection("users");

        // Pack the working hours in the copied user object
        const packedWorkingHours = {
            Sunday: packWorkingHours(user?.workingHours?.Sunday),
            Monday: packWorkingHours(user?.workingHours?.Monday),
            Tuesday: packWorkingHours(user?.workingHours?.Tuesday),
            Wednesday: packWorkingHours(user?.workingHours?.Wednesday),
            Thursday: packWorkingHours(user?.workingHours?.Thursday),
            Friday: packWorkingHours(user?.workingHours?.Friday),
            Saturday: packWorkingHours(user?.workingHours?.Saturday),
        };

        // Assign packedWorkingHours to the copied user object
        user.workingHours = packedWorkingHours;

        // Proceed with the Firestore operations using the copied and modified user object
        if (user.id) {
            const docRef = userCollection.doc(user.id);
            const doc = await docRef.get();
            if (doc.exists) {
                await docRef.update(user);
            } else {
                await docRef.set(user);
            }
            return user.id;
        } else {
            const userRef = await userCollection.add(user);
            return userRef.id;
        }
    } catch (error) {
        console.error("Error saving user: ", error);
        throw error;
    }
};


const defaultUserData = {
    name: 'New User',
    workingHours: {
        Sunday: 0,
        Monday: 1048320, // 8 AM to 8 PM
        Tuesday: 1048320,
        Wednesday: 1048320,
        Thursday: 1048320,
        Friday: 1048320,
        Saturday: 0
    },
    active: true,
};

// Function to add a new therapist to Firestore.js
export const createDefaultUser = async (e) => {
    e.preventDefault();
    try {
        const db = firebase.firestore();
        const docRef = await db.collection(userCollectionPath).add(defaultUserData);
        console.log("Document written with ID: ", docRef.id);
        return docRef.id; // Returns the new document's ID
    } catch (e) {
        console.error("Error adding document: ", e);
    }
};
