import React, {useState, useEffect, useRef, useCallback} from "react";
import {Draggable, FlatRectangleWithTitle} from "../Styles/rectangle";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import EditorState from "draft-js/lib/EditorState";
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import ContentState from "draft-js/lib/ContentState";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";

export function createTitleFromHour(hour) {
    // Ensure hour is within 0 - 23 range
    const startHour = hour % 24;
    const endHour = (startHour + 1) % 24; // Wrap around at 24 to 0

    // Format hours to be two digits
    const formattedStartHour = startHour.toString().padStart(2, '0');
    const formattedEndHour = endHour.toString().padStart(2, '0');

    // Create title string
    return 'Booking Notes for ' + `${formattedStartHour}:00 - ${formattedEndHour}:00`;
}


export const NotePopup = ({ visible, position, onClose, onSave, title, noteInfo }) => {
    const [localContent, setLocalContent] = useState(noteInfo?.content);
    const [accesscode, setAccessCode] = useState(noteInfo?.accesscode);
    const [email, setEmail] = useState(noteInfo?.email)

    const [editorState, setEditorState] = useState(() => {
        if (noteInfo?.content) {
            let convertedToHTML = decodeURIComponent(noteInfo.content);
            const blocksFromHtml = htmlToDraft(convertedToHTML);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
            );
            return EditorState.createWithContent(contentState);
        }
        return EditorState.createEmpty()
    });

    const popupRef = useRef(null);

    useEffect(() => {
        if (visible && popupRef.current) {
            const {innerWidth: viewportWidth, innerHeight: viewportHeight} = window;
            const {offsetWidth: popupWidth, offsetHeight: popupHeight} = popupRef.current;

            // Calculate adjusted position to ensure the popup fits in the viewport
            let adjustedLeft = position.x + 10;
            let adjustedTop = position.y + 10;

            // Adjust if the popup runs off the right of the viewport
            if (adjustedLeft + popupWidth > viewportWidth) {
                adjustedLeft = viewportWidth - popupWidth - 20; // 20 for a little margin
            }

            // Adjust if the popup runs off the bottom of the viewport
            if (adjustedTop + popupHeight > viewportHeight) {
                adjustedTop = viewportHeight - popupHeight - 20; // Adjust upwards to fit
            }

            // Apply adjusted position
            popupRef.current.style.left = `${adjustedLeft}px`;
            popupRef.current.style.top = `${adjustedTop}px`;
        }
    }, [visible, position]);

    const updatedLocalContent = (state) => {
        setEditorState(state);
        const rawContentState = convertToRaw(state.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        setLocalContent(markup);
    };

    const handleSave = () => {
        updatedLocalContent(editorState);
        noteInfo.accesscode = accesscode
        noteInfo.email = email
        onSave(localContent,noteInfo);
        onClose();
    };

    useEffect(() => {
        if (noteInfo != null){
            const currentContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            if ( noteInfo.content !== undefined && noteInfo.content !== currentContent) {
                const blocksFromHtml = htmlToDraft(noteInfo.content);
                if (blocksFromHtml) {
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    const newEditorState = EditorState.createWithContent(contentState);
                    setEditorState(newEditorState);
                    setLocalContent(noteInfo.content)
                }
            }
        }
    }, [noteInfo]);


    const effectiveTitle = (title === undefined || title === null || title === '') ? "Notes" : title;

    const editorStyle = {
        height: '270px',
        border: '2px solid #F1F1F1', // Add a border around the editor
        padding: '5px', // Add some padding inside the editor
        borderRadius: '2px', // Optional: border radius
    };

    const toolbarStyle = {
        borderBottom: '2px solid #F1F1F1', // Style the toolbar border
        marginBottom: '5px', // Space between toolbar and editor
    };

    if (!visible) return null;

    return (
        <Dialog open={visible} onClose={onClose} aria-labelledby="note-dialog-title" maxWidth="sm" fullWidth>
            <DialogTitle id="note-dialog-title">{effectiveTitle}</DialogTitle>
            <DialogContent>

                <Editor
                    editorState={editorState}
                    editorStyle={editorStyle}
                    toolbarStyle={toolbarStyle}
                    onEditorStateChange={updatedLocalContent}
                    toolbar={{
                        options: ['inline', 'blockType','list', 'textAlign'],
                        inline: {
                            options: ['bold', 'italic', 'underline'],
                        }, blockType: {
                            inDropdown: true,
                            options: ['Normal', 'H1', 'H2', 'H3'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        list: {
                            inDropdown: true,
                            options: ['unordered', 'ordered', 'indent', 'outdent'],
                        },
                        textAlign: {
                            inDropdown: true,
                            options: ['left', 'center', 'right'],
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button variant = 'contained' onClick={handleSave}>Save</Button>
                <Button variant = 'contained' onClick={onClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};


/*
<Typography>Email</Typography>
<TextField
    id="email"
    type="email"
    fullWidth
    margin="dense"
    variant="outlined"
    onChange={(e) => setEmail(e.target.value)}
    value={email}
/>
<Typography>Access Code</Typography>
<TextField
    id="accessCode"
    type="text"
    fullWidth
    margin="dense"
    variant="outlined"
    onChange={(e) => setAccessCode(e.target.value)}
    value={accesscode}
/>
*/