import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useUsers } from "../ListBoxes/userService";
import { Typography, Box, Avatar } from '@mui/material';

export const TherapistSelect = ({ onOptionsChange, selectall = false }) => {
    const { users: therapists } = useUsers(true);
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        if(therapists) {
            const formattedOptions = therapists.map(therapist => ({
                value: therapist.id,
                label: therapist.name,
                profile: therapist.profile, // Assuming 'profile' is the property you want to include
                photoURL: therapist.photoURL // Assuming 'photoURL' is the property for the avatar image
            }));
            setSelectOptions(formattedOptions);
        }
    }, [therapists]);

    return (
        <Autocomplete
            multiple
            options={selectOptions}
            getOptionLabel={(option) => option.label}
            onChange={(event, newValue) => {
                onOptionsChange(newValue);
            }}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.value} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={option.photoURL} alt={option.label} sx={{ marginRight: 2 }} />
                    <Box>
                        <Typography variant="body1">{option.label}</Typography>
                        <Typography variant="body2" color="textSecondary">
                            {option.profile} {/* Displaying the profile */}
                        </Typography>
                    </Box>
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Therapist"
                    placeholder="Select Therapist"
                />
            )}
        />
    );
};
