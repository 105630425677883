export const transformCountsToChartData = (idCounts, codes) => {
    // Map the idCounts object to the desired array format
    const chartData = Object.entries(idCounts).map(([code, count]) => {

        const thisCode = codes.find(c => c.id === code)

        return {
            id: thisCode.text,
            label: thisCode.code,
            value: count,
            color: thisCode.background
        };
    });

    return chartData;
};

export const countTherapistDataIDs = (allTherapistsData, inclusionList) => {
    const idCounts = {};
    allTherapistsData.forEach(therapistData => {
        therapistData.data.forEach(dayData => {
            // Now dayData.hours is an object, not an array
            Object.values(dayData.hours).forEach(hourData => {
                // Assuming hourData includes a 'status' property
                const statusId = hourData.status[0].codeId;
                if (inclusionList.find(code => code.value === statusId)) {
                    if (idCounts[statusId]) {
                        idCounts[statusId] += 1;
                    } else {
                        idCounts[statusId] = 1;
                    }
                }
            });
        });
    });

    return idCounts;
};


export const countTherapistDataPerID = (allTherapistsData, inclusionList) => {
    const therapistCounts = {};

    allTherapistsData.forEach(therapistData => {
        therapistData.data.forEach(dayData => {
            // Now dayData.hours is an object with keys representing hours
            Object.values(dayData.hours).forEach(hourData => {
                // Extracting status from each hourData object
                const statusId = hourData.status[0].codeId;
                if (inclusionList.find(code => code.value === statusId)) {
                    if (!therapistCounts[therapistData.therapistId]) {
                        therapistCounts[therapistData.therapistId] = {};
                    }
                    if (therapistCounts[therapistData.therapistId][statusId]) {
                        therapistCounts[therapistData.therapistId][statusId] += 1;
                    } else {
                        therapistCounts[therapistData.therapistId][statusId] = 1;
                    }
                }
            });
        });
    });

    return therapistCounts;
};



export const transformForStackedBarChart = (therapistData, therapists, codes) => {
    let transformedData = [];

    // Iterate over therapistData if it exists
    Object.entries(therapistData || {}).forEach(([therapistId, countsByCode]) => {
        // Find the therapist's name using the therapistId
        const therapistName = therapists.find(t => t.id === therapistId)?.name || 'Unknown';
        let transformedTherapistData = {
            therapist: therapistName,
        };

        // Ensure countsByCode exists and is an object before iterating
        if (countsByCode && typeof countsByCode === 'object') {
            Object.entries(countsByCode).forEach(([codeId, count]) => {
                const codeDetail = codes.find(c => c.id === codeId);

                if (codeDetail) {
                    transformedTherapistData[codeDetail.text] = count;
                    transformedTherapistData[`${codeDetail.text}Color`] = codeDetail.background;
                }
            });
        }

        transformedData.push(transformedTherapistData);
    });

    return transformedData;
};
