import React, {useState} from "react";
import {useUsers} from "../ListBoxes/userService";

import './Grid.css';
import CodesListBox from "../ListBoxes/codesListbox";
import {DatePickerComponent} from "./DatePicker";
import {setHourCode, useFetchAndSubscribeDayData} from "./dataServce";
import {useCodes} from "../Codes/data";
import {getCodeDataForHour} from "./MonthlyGrid";
import {NoteEditor} from "../Notes/noteeditor";
import {createTitleFromHour} from "../Notes/notes";
import {getContrastingColor} from "../Color/color";
import {CustomTableCell} from "../Styles/grid";
import {
    AppBar,
    Box, Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableRow,
    Toolbar,
    Typography, useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import {OnlineAvatar} from "../ListBoxes/userListBox";
import {isUserOnline, useOnlineUsers, userLastActive, UsersWithSameActivity} from "../Presence/presence";
import GridActionsComponent from "../Components/gridAction";
import CloseButton from "../Components/closeButton";
import {getCellCodeDataForHour, GridCell} from "./gridcell";


const DailyRow = ({selectedDate,therapist, codes, year, month, day, displayMode, selectedItem, onSelectItem, onDoubleClick,isDarkMode = false}) => {
    const theme = useTheme();
    const {data: dayData, loading, error} = useFetchAndSubscribeDayData(therapist, year, month, day);
    const { onlineUsers } = useOnlineUsers();

    if (loading) return <tr>
        <td>Loading...</td>
    </tr>;
    if (error) return <tr>
        <td>Error: {error.message}</td>
    </tr>;

    if (dayData == null) {
        return null
    }

    const stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 100,
        minWidth: 160, maxWidth: 200,
        borderRight: `1px solid ${theme.palette.divider}`,
    };


    return (
        <TableRow>
            <TableCell sx={stickyColumnStyle} >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <OnlineAvatar user={therapist} online={isUserOnline(therapist, onlineUsers)} lastActive={userLastActive(therapist,onlineUsers)} size={'small'} />
                    <Typography noWrap sx={{ fontSize:'12px', maxWidth: '100%' }}> {/* Use Typography for better control */}
                        {therapist.name}
                    </Typography>
                </Box>
            </TableCell>

            {[...Array(24).keys()].map(hour => {
                const isSelected = (() => {
                    if (!selectedItem || !selectedItem.therapist) {
                        return { selected: false, source: null };
                    }

                    const isTherapistSelected = selectedItem.therapist.id === therapist.id;
                    const isHourSelected = selectedItem.selectedHour === hour;

                    if (isTherapistSelected && isHourSelected) {
                        return { selected: true, source: 'me' };
                    } else {
                        return { selected: false, source: null };
                    }
                })();

                const note = dayData.hours[hour]?.notes || '';
                const accessCode = dayData.hours[hour]?.accesscode || '';
                const email = dayData.hours[hour]?.email || '';

                return (<GridCell
                    selectedDate={selectedDate.toISOString()}
                    day={day}
                    dayData={dayData}
                    hour={hour}
                    codes={codes}
                    displayMode={displayMode}
                    isDarkMode={isDarkMode}
                    onClick={() => onSelectItem(therapist, hour)}
                    onDoubleClick={(e) => onDoubleClick(e, {note, accessCode, email})}
                    isSelected = {isSelected}
                    isHeatMap={false}
                    cellSize = {48}
                />);
            })}
        </TableRow>

    );
};


const Daily = ({onCloseWindow}) => {
    const { me } = useOnlineUsers();
    const sessionUser = me();

    const {users} = useUsers(true);
    const [selectedItem, setSelectedItem] = useState({therapist: null, selectedHour: -1});
    const [displayMode, setDisplayMode] = useState('code');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [selectedCode, setSelectedCode] = useState(null);
    const {codes} = useCodes();

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    const toggleDisplayMode = () => {
        setDisplayMode(prevMode => prevMode === 'code' ? 'emoji' : 'code');
    };

    const handleDateChange = (year, month, day) => {
        const newDate = new Date(Date.UTC(year, month, day));

        // Compare the time values of the dates
        if (newDate.getTime() !== selectedDate.getTime()) {
            setSelectedDate(newDate);
        }
    };


    const applyCodeToSelection = async (e) => {
        e.preventDefault();
        const isoDate = selectedDate.toISOString().substr(0, 10)
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10);
        await setHourCode(sessionUser,selectedItem.therapist, year, month, day, selectedItem.selectedHour, [{idx:0,code:selectedCode,minutes:60}])
    };

    const clearSelection = async (e) => {
        e.preventDefault();
        const isoDate = selectedDate.toISOString().substr(0, 10)
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10);
        await setHourCode(sessionUser, selectedItem.therapist, year, month, day, selectedItem.selectedHour, [{idx:0,code:null,minutes:60}])
    };

    // Function to handle selecting an item in the grid
    const handleSelectItem = (therapist, hour) => {
        setSelectedItem({therapist: therapist, selectedHour: hour});
    };

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({x: 0, y: 0});
    const [noteInfo, setNoteInfo] = useState(null);


    const handleDoubleClick = (e, data) => {
        const isoDate = selectedDate.toISOString().substr(0, 10)
        const year = isoDate.substring(0, 4);
        const month = isoDate.substring(5, 7);
        const day = isoDate.substring(8, 10).toString().padStart(2, '0');

        setPopupPosition({x: e.clientX, y: e.clientY});
        setPopupVisible(true);

        setNoteInfo({
            content: data.note,
            selectedTherapist: selectedItem.therapist,
            year,
            month,
            day,
            hour: selectedItem.selectedHour,
            accesscode: data.accesscode,
            email: data.email
        })
    };

    const stickyColumnStyleBlocker = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 200,
    };

    return (
        <Paper sx={{width: '80%', minWidth: '900px', padding: 2}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                <AppBar position="static">
                    <Toolbar variant='dense' sx={{padding:1}}>
                        <CalendarViewDayIcon/>
                        <Grid container alignItems="center" spacing={2} padding={1}>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    Daily Overview
                                </Typography>
                            </Grid>
                            <Grid item>
                                <UsersWithSameActivity currentActivity={'triage'}/>
                            </Grid>
                            <Grid item>
                                <GridActionsComponent
                                    selectedCode={selectedCode}
                                    setSelectedCode={setSelectedCode}
                                    onApply={applyCodeToSelection}
                                    onClear={clearSelection}
                                    displayMode={displayMode}
                                    toggleDisplayMode={toggleDisplayMode}
                                />
                            </Grid>
                        </Grid>
                        <CloseButton onCloseWindow={onCloseWindow}></CloseButton>
                    </Toolbar>
                </AppBar>

                <TableContainer component={Paper} sx={{maxHeight: '700px'}}>
                    <Table sx={{ minWidth: 650 }} size="small" stickyHeader>
                        <TableRow sx={{ '& > *': {  padding: '2px', height: '12px' } }}>
                            <TableCell sx={stickyColumnStyleBlocker} >
                                {selectedDate.toLocaleDateString('en-GB', {
                                    weekday: 'long', // e.g., Monday
                                    day: 'numeric', // e.g., 21
                                    month: 'long' // e.g., March
                                })}
                            </TableCell>
                            {[...Array(24).keys()].map(hour => (
                                <TableCell key={hour}>{hour}:00</TableCell>
                            ))}
                        </TableRow>
                        <TableBody>
                            {users.map((therapist) => (
                                <DailyRow
                                    selectedDate={selectedDate}
                                    key={therapist.id}
                                    therapist={therapist}
                                    codes={codes}
                                    year={selectedDate.toISOString().substring(0, 4)}
                                    month={selectedDate.toISOString().substring(5, 7)}
                                    day={selectedDate.toISOString().substring(8, 10)}
                                    displayMode={displayMode}
                                    selectedItem={selectedItem}
                                    onSelectItem={handleSelectItem}
                                    onDoubleClick={handleDoubleClick}
                                    isDarkMode={isDarkMode}
                                />
                            ))}
                        </TableBody>
                        <NoteEditor
                            title={selectedItem?.selectedHour ? createTitleFromHour(selectedItem?.selectedHour) : ''}
                            visible={popupVisible}
                            position={popupPosition}
                            onClose={() => setPopupVisible(false)}
                            noteInfo={noteInfo}
                        />
                    </Table>
                </TableContainer>
                <AppBar position="static" color="primary" sx={{ top: 'auto', bottom: 0 }}>
                    <Toolbar variant='dense'>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <DatePickerComponent onDateChange={handleDateChange} mode={'day'}/>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Box>
        </Paper>
    );
};

export default Daily;


