import React, {useState} from "react";
import EmojiPicker from 'emoji-picker-react';
import {Button} from "@mui/material";

export default function PickEmoji({chosenEmoji, setChosenEmoji}) {
    const [isPickerOpen, setIsPickerOpen] = useState(true);

    const onEmojiClick = (event) => {
        setChosenEmoji(event.emoji);
    };

    const toggleEmojiPicker = () => {
        setIsPickerOpen(!isPickerOpen); // Toggling the state
    };

    const clearEmoji = () => {
        setChosenEmoji(null)// Toggling the state
    };

    return (
        <div>
            <EmojiPicker
                open={isPickerOpen}
                onEmojiClick={onEmojiClick}
                searchPlaceholder={""}
                skinTonesDisabled={true}
                searchDisabled={false}
                height={"400px"}
            />
            <Button type="button" style={{backgroundColor: 'red', color: 'white'}} onClick={clearEmoji}>Clear Emoji</Button>
        </div>
    );
}
