import {Avatar, Box, Divider, duration, Typography} from "@mui/material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator
} from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {halfLuminance, RenderCodeBadge} from "./codebadge";



export function CustomTimelineItem({ status, codes, isLast, position, displayMode }) {
    if (!status || Object.keys(status).length === 0) {
       return <Box
            sx={{
                display: 'flex',
                height: '100%', // Take up the full height of the viewport
                justifyContent: 'center', // Center horizontally
                alignItems: 'center' // Center vertically
            }}
        >
            <Typography variant="h6" textAlign="center">
                This pane was intentionally left blank.
            </Typography>
        </Box>
    }

    // Creating a list of status codes with timestamps and other details
    const statusEntries = Object.entries(status).map(([index, entry]) => {
        const timestamp = entry?.author?.ts;
        const dateStr = new Date(timestamp?.seconds * 1000).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: '2-digit'
        });
        const timeStr = new Date(timestamp?.seconds * 1000).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
        const codeId = entry?.codeId;
        const foundCode = codes.find(c => c.id === codeId) || { text: "Cleared", background: 'transparent', foreground: 'black', code: '' };

        return {
            dateStr,
            timeStr: timeStr === "Invalid Date" ? "" : timeStr,
            codeText: foundCode.text,
            author: entry?.author,
            background: foundCode.background,
            foreground: foundCode.foreground,
            code: foundCode.code,
            duration: entry?.minutes
        };
    });

    // Create an array for RenderCodeBadge with all codes from the status

    let begin = 0
    const badgeCodes = Object.entries(status).map(([index, entry]) => {
        const codeId = entry?.codeId;
        const foundCode = codes.find(c => c.id === codeId) || { text: "Cleared", background: 'transparent', foreground: 'black', code: '' };

        const startTime = begin

        return {
            code: displayMode === "code" ? foundCode.code : foundCode.emoji,
            duration: entry?.minutes,
            background: foundCode.background,
            foreground: foundCode.foreground,
            halfLuminance: halfLuminance(foundCode.background),
        };
    });

    const flexDirection = position === 'left' ? 'row-reverse' : 'row';

    return (
        <div>
            <Timeline align="alternate">
                {statusEntries.reverse().map((entry, idx) => (
                    <TimelineItem key={idx}>
                        {/* Timeline Content that alternates sides */}
                        <TimelineOppositeContent
                            sx={{width: '50%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}
                            align={idx % 2 === 0 ? 'right' : 'left'}
                        >
                            <Box sx={{maxWidth: '200px', textAlign: 'right', marginBottom: '20px'}}>
                                <Typography variant="body2" color="textPrimary" sx={{fontWeight: 'bold'}}>
                                    {entry.codeText} ({entry.duration} mins)
                                    <br/>
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {entry.dateStr}
                                    <br/>
                                    {entry.timeStr}
                                </Typography>
                            </Box>
                        </TimelineOppositeContent>

                        <TimelineSeparator>
                            <TimelineDot variant="outlined" sx={{padding: 0, border: 'none'}}>
                                <RenderCodeBadge
                                    width={64}
                                    height={64}
                                    radius={30}
                                    codes={badgeCodes.slice(0, statusEntries.length - idx)}
                                    hasBackground={false}
                                />
                            </TimelineDot>
                            {idx < statusEntries.length - 1 && <TimelineConnector/>}
                        </TimelineSeparator>

                        <TimelineContent
                            sx={{width: '50%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                            <Box sx={{maxWidth: '200px', textAlign: 'left', marginBottom: '20px'}}>
                                <Avatar alt={entry?.author?.name} src={entry?.author?.photo}
                                        sx={{width: 32, height: 32, marginRight: '8px'}}/>
                                <Typography variant="caption" color="textSecondary">
                                    {entry?.author?.name}
                                </Typography>
                            </Box>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
            <Divider sx={{width: '100%', marginTop: '10px', marginBottom: '10px'}}/>
        </div>


    );
}

export default CustomTimelineItem;

/*
<TimelineItem>
    <TimelineOppositeContent
        sx={{ m: 'auto 0' }}
        align="right"
        variant="body2"
        color="text.secondary">
        {statusEntries.map((entry, idx) => (
            <Box key={idx} sx={{ width: '150px', marginBottom: '10px' }}>
                <Typography variant="body" color="textPrimary"
                            sx={{ fontWeight: 'bold', textAlign: { position }, whiteSpace: 'nowrap', width: '100%' }}>
                    {entry.codeText}
                    <br />
                </Typography>
                <Typography variant="caption" color="textSecondary" sx={{ whiteSpace: 'nowrap', width: '100%' }}>
                    {entry.dateStr}
                    <br />
                    {entry.timeStr}
                </Typography>
            </Box>
        ))}
    </TimelineOppositeContent>
    <TimelineSeparator>
        <TimelineDot variant="outlined" style={{ padding: 0, border: 'none' }}>
            <RenderCodeBadge width={48} height={48} radius={22} codes={badgeCodes} hasBackground={false} />
        </TimelineDot>
        {!isLast && <TimelineConnector />}
    </TimelineSeparator>
    <TimelineContent>
        {statusEntries.map((entry, idx) => (
            <Box key={idx} sx={{
                display: 'flex',
                flexDirection: flexDirection,
                alignItems: 'center',
                gap: '8px',
                marginBottom: '50px',
                width: '150px'
            }}>
                <Typography variant="body2" color="textPrimary" sx={{whiteSpace: 'nowrap', width: '100%'}}>
                    {entry?.author?.name}
                </Typography>
                <Avatar alt={entry?.author?.name} src={entry?.author?.photo} style={{ width: 28, height: 28 }} />
            </Box>
        ))}
    </TimelineContent>
</TimelineItem>

*/