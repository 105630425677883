import styled from '@emotion/styled'

export const ColorInput = styled.input`
    width: 100px;
    height: 40px;
`;

export const ColorInputsContainer = styled.div`
    display: flex;
    gap: 20px; /* Adjust the gap between the inputs as needed */
`;
