import React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';

// Define the component with props
const CloseButton = ({ onCloseWindow }) => {
    return (
        <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
            <Tooltip title="Get me out of here" arrow>
                <CloseIcon />
            </Tooltip>
        </IconButton>
    );
};

export default CloseButton;
