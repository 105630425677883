import React, {useState, useEffect, useRef} from 'react';
import {NoteEditor} from "../Notes/noteeditor";
import {createTitleFromHour} from "../Notes/notes";
import './Grid.css';

import {
    Box, Divider, IconButton,
    Paper, SvgIcon,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, Typography,
    useTheme
} from "@mui/material";

import {
    Timeline,
} from '@mui/lab';

import {CustomTimelineItem} from "./timeline";
import {getCellCodeDataForHour, GridCell} from "./gridcell";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {ssUserSessionId} from "../App/App";
import {SvgIconProps} from "@mui/material";

const defaultCellSize = 48

export const getDataForDayHour = (year, month, day, hour, datesWithHours) => {
    if (day === undefined || hour === undefined || datesWithHours.length < 1) {
        return null
    }
    const dateStr = `${year}-${(month).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    const data = datesWithHours.find(d => d.id === dateStr);
    const hourData = data?.hours[hour]
    return hourData
};


export function getFormattedDate(year, month, day, hour, startMinute, endMinute) {
    // Convert string inputs to integers
    year = parseInt(year, 10);
    month = parseInt(month, 10);
    day = parseInt(day, 10);
    hour = parseInt(hour, 10);
    startMinute = startMinute !== undefined ? parseInt(startMinute, 10) : undefined;
    endMinute = endMinute !== undefined ? parseInt(endMinute, 10) : undefined;

    // Create a Date object
    const date = new Date(year, month - 1, day, hour);

    // Function to determine the ordinal suffix of a day
    function getOrdinalSuffix(day) {
        if (day > 3 && day < 21) return day + 'th';
        switch (day % 10) {
            case 1:
                return day + 'st';
            case 2:
                return day + 'nd';
            case 3:
                return day + 'rd';
            default:
                return day + 'th';
        }
    }

    // Function to format a single time value
    function formatTime(hour, minute) {
        const date = new Date(0, 0, 0, hour, minute);
        return date.toLocaleTimeString('en-GB', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });
    }

    // Get the ordinal suffix for the day
    const dayWithSuffix = getOrdinalSuffix(day);

    // Format the date parts
    let formattedDate;

    // Append time range if startMinute and endMinute are provided
    if (startMinute !== undefined && endMinute !== undefined) {
        // Calculate adjusted hours for start and end
        const startHourAdjusted = hour + Math.floor(startMinute / 60);
        const endHourAdjusted = hour + Math.floor(endMinute / 60);
        startMinute %= 60;
        endMinute %= 60;

        const startTime = formatTime(startHourAdjusted, startMinute);
        const endTime = formatTime(endHourAdjusted, endMinute);
        formattedDate = `${date.toLocaleString('en-GB', {
            month: 'long',
            year: 'numeric'
        })}, ${dayWithSuffix} from ${startTime} to ${endTime}`;
    } else {
        // Include the base hour and minute
        formattedDate = date.toLocaleString('en-GB', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }) + `, ${dayWithSuffix} of ` + date.toLocaleString('en-GB', {
            month: 'long',
            year: 'numeric'
        });
    }

    return formattedDate;
}


function MonthlyGrid({
                         onContextMenu,
                         selectedTherapist,
                         selectedDate,
                         onSelectionChange,
                         datesWithHours,
                         codes,
                         displayMode,
                         isDarkMode = false,
                         isHeatMapView = false,
                         setOverlay,
                         overlay,
                         zoom,
                         updateData,
                         onDoubleClick,
                         activeUsers,
                         historyVisible,
                     }) {
    const initialDateParts = selectedDate.split('-');
    const initialYear = parseInt(initialDateParts[0], 10);
    const initialMonth = parseInt(initialDateParts[1], 10) - 1; // Convert to 0-based index
    const [year, setYear] = useState(initialYear);
    const [month, setMonth] = useState(initialMonth);
    const [daysInMonth, setDaysInMonth] = useState(new Date(year, month, 0).getUTCDate());
    const [selection, setSelection] = useState({start: null, end: null});
    const [isSelecting, setIsSelecting] = useState(false);

    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based index
    const currentDate = today.getDate();
    const theme = useTheme();
    const gridRef = useRef(null);

    useEffect(() => {
        if (gridRef.current) {

            const grid = gridRef.current;

            const updateScrollPosition = () => {
                const viewportWidth = grid.clientWidth;
                const viewportHeight = grid.clientHeight;

                // Determine average positions of the selection's start and end
                const avgDay = ((selection.start?.day + selection.end?.day) / 2) - 1; // Average day, converted to 0-based index
                const avgHour = (selection.start?.hour + selection.end?.hour) / 2; // Average hour

                // Calculate the center position of the average day and hour, accounting for zoom
                const newTopPosition = Math.round(avgHour * defaultCellSize * zoom);
                const newLeftPosition = Math.round(avgDay * defaultCellSize * zoom);

                // Center these positions in the viewport
                const centeredTop = Math.round(newTopPosition - (viewportHeight / 2) + (defaultCellSize * zoom / 2));
                const centeredLeft = Math.round(newLeftPosition - (viewportWidth / 2) + (defaultCellSize * zoom / 2));

                // Ensure scrolling does not exceed bounds of the grid
                const safeScrollTop = Math.max(0, Math.min(centeredTop, grid.scrollHeight - viewportHeight));
                const safeScrollLeft = Math.max(0, Math.min(centeredLeft, grid.scrollWidth - viewportWidth));

                grid.scrollTo({
                    top: safeScrollTop,
                    left: safeScrollLeft,
                    behavior: 'instant'
                });
            };

            requestAnimationFrame(updateScrollPosition);
        }
    }, [zoom]);


    useEffect(() => {
        // Update year and month based on the selectedDate changes
        const dateParts = selectedDate.split('-');
        const year = parseInt(dateParts[0], 10)
        const month = parseInt(dateParts[1], 10) - 1
        setYear(year);
        setMonth(month);
        const days = new Date(Date.UTC(year, month + 1, 0)).getUTCDate();
        setDaysInMonth(days)
    }, [selectedDate]);


    useEffect(() => {
        // Function to be called when mouse is released
        const finalizeSelection = () => setIsSelecting(false);

        if (isSelecting) {
            // Add mouseup event listener to the window to finalize selection when mouse is released
            window.addEventListener('mouseup', finalizeSelection);
        }

        // Cleanup function to remove the event listener
        return () => window.removeEventListener('mouseup', finalizeSelection);
    }, [isSelecting]);


    const handleMouseEnter = (event, day, hour) => {
        if (isSelecting) {
            const newSelection = {...selection, end: {day, hour}};
            setSelection(newSelection);
            // Update the parent component about the new selection
            if (onSelectionChange) {
                onSelectionChange(newSelection);
            }
        }
    };

    // Determine if a cell is within the selected range
    const isCellSelected = (day, hour) => {
        if (!selection.start || !selection.end) {
            return {selected: false, source: null};
        }

        const startDay = Math.min(selection.start.day, selection.end.day);
        const endDay = Math.max(selection.start.day, selection.end.day);
        const startHour = Math.min(selection.start.hour, selection.end.hour);
        const endHour = Math.max(selection.start.hour, selection.end.hour);

        // First, check the general selection
        if (day >= startDay && day <= endDay && hour >= startHour && hour <= endHour) {
            return {selected: true, source: 'me'};
        }

        const currentSessionId = sessionStorage.getItem(ssUserSessionId);

        // Then check if any user has this specific cell selected
        const userWithSelection = activeUsers.find(user =>
            user.positions.some(position => {
                if (position.sessionId === currentSessionId) {
                    return false;
                }

                const positionStartDay = Math.min(position.start.day, position.end.day);
                const positionEndDay = Math.max(position.start.day, position.end.day);
                const positionStartHour = Math.min(position.start.hour, position.end.hour);
                const positionEndHour = Math.max(position.start.hour, position.end.hour);

                return day >= positionStartDay && day <= positionEndDay && hour >= positionStartHour && hour <= positionEndHour;
            })
        );
        if (userWithSelection) {
            return {selected: true, source: 'user', userId: userWithSelection.id, photoURL: userWithSelection.photoURL};
        }

        // Return false if no selection is found
        return {selected: false, source: null};
    };


    const handleDoubleClick = (e) => {
        onDoubleClick()
    };

    const [lastClickTime, setLastClickTime] = useState(0);

    const updateOverlay = async (year, month, day, hour, datesWithHours) => {
        let hourData = getDataForDayHour(year, month, day, hour, datesWithHours)
        if (hourData?.status === null) {
            hourData = undefined
        }

        const formattedDate = getFormattedDate(year, month, day, hour)

        setOverlay({
            data: hourData,
            title: formattedDate,
            day: day,
            hour: hour,
        });
    }


    useEffect(() => {
        if (selection.start !== null) {
            const day = selection?.start?.day
            const hour = selection?.start?.hour
            updateOverlay(year, month + 1, day, hour, datesWithHours)
        }
    }, [updateData, selection, datesWithHours]);


    const handleMouseDown = (e, day, hour) => {
        e.preventDefault()

        const now = Date.now();
        const delta = now - lastClickTime;
        if (delta < 300) {
            handleDoubleClick(e, day, hour);
        } else {
            const newSelection = {start: {day, hour}, end: {day, hour}}
            setIsSelecting(true);
            setSelection(newSelection);
            if (onSelectionChange) {
                onSelectionChange(newSelection);
            }
        }
        setLastClickTime(now);
    };

    let stickyColumnStyle = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 100,
        borderRight: `1px solid ${theme.palette.divider}`,
        userSelect: 'none', // Standard syntax
        WebkitUserSelect: 'none', // Safari
        MozUserSelect: 'none', // Firefox
        msUserSelect: 'none', // Internet Explorer/Edge
    };


    let stickyColumnStyleBlocker = {
        position: 'sticky',
        left: 0,
        background: `${theme.palette.background.paper}`,
        zIndex: 200,
        borderRight: `1px solid ${theme.palette.divider}`,
        width: '48px'
    };

    let cellBack = `${theme.palette.background.paper}`
    let weekendColor = '#eeeeee'

    if (isDarkMode) {
        weekendColor = '#888888'
    }

    const cellSize = defaultCellSize * zoom


    const [isCollapsed, setIsCollapsed] = React.useState(false);

    useEffect(() => {
        if (!isCollapsed) {
            setIsCollapsed(true);
        } else {
            setIsCollapsed(false);
        }
    }, [historyVisible]);


    return (
        <Grid container spacing={2} alignItems="stretch" >
            <Grid item xs={12} sm={isCollapsed ? 12 : 8} >
                <TableContainer ref={gridRef} component={Paper} sx={{maxHeight: 'calc(100vh - 250px)', willChange: 'transform, scroll-position'}}>
                    <Table size="small" stickyHeader>
                        <TableHead>
                            <TableRow sx={{'& > *': {padding: '0px'}}}>
                                <TableCell sx={stickyColumnStyleBlocker}></TableCell>
                                {Array.from({length: daysInMonth}, (_, index) => {
                                    const date = new Date(year, month, index + 1);
                                    const isToday = currentYear === year && currentMonth === month && currentDate === (index + 1);
                                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
                                    return (
                                        <TableCell
                                            key={index}
                                            align="center"
                                            sx={{
                                                backgroundColor: isToday ? '#4CAF50' : isWeekend ? weekendColor : cellBack,
                                                color: isToday ? 'white' : isWeekend ? 'black' : undefined,
                                                zIndex: 150,
                                                userSelect: 'none', // Standard syntax
                                                WebkitUserSelect: 'none', // Safari
                                                MozUserSelect: 'none', // Firefox
                                                msUserSelect: 'none', // Internet Explorer/Edge
                                                width: `${cellSize}px`,
                                                minWidth: `${cellSize}px`,
                                                margin: 0,
                                                padding: 0,
                                            }}>
                                            {date.getDate()}<br/>
                                            <span>{date.toLocaleDateString('en-GB', {weekday: 'short'})}
                                    </span>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from({length: 24}, (_, hour) => (
                                <TableRow key={hour}>
                                    <TableCell sx={stickyColumnStyle}>{`${hour}:00`}</TableCell>
                                    {Array.from({length: daysInMonth}, (_, dayIndex) => (
                                        <GridCell
                                            key={`day-${dayIndex + 1}-hour-${hour}`}
                                            selectedDate={selectedDate}
                                            day={dayIndex + 1}
                                            dayData={datesWithHours[dayIndex]}
                                            hour={hour}
                                            codes={codes}
                                            displayMode={displayMode}
                                            isDarkMode={isDarkMode}
                                            onContextMenu={(e) => onContextMenu(e, {day: dayIndex + 1, hour})}
                                            onMouseDown={(e) => {
                                                if (e.button === 0) {
                                                    handleMouseDown(e, dayIndex + 1, hour);
                                                }
                                            }}
                                            onMouseEnter={(event) => handleMouseEnter(event, dayIndex + 1, hour)}
                                            onDoubleClick={() => handleDoubleClick(dayIndex + 1, hour)}
                                            isSelected={isCellSelected(dayIndex + 1, hour)}
                                            isHeatMap={isHeatMapView}
                                            cellSize={cellSize}
                                        />
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>


            <Grid item xs={12} sm={isCollapsed ? 0 : 4} maxHeight = 'calc(100vh - 300px)'>
                {!isCollapsed && (
                    <div>
                        <AppBar position="static">
                            <Toolbar style={{minHeight: '48px'}}>

                                <Box sx={{flexGrow: 1, display: 'flex', justifyContent: 'center'}}>
                                    <Typography variant="h6" component="div"
                                                style={{display: isCollapsed ? 'none' : 'block'}}>
                                        {overlay.title}
                                    </Typography>
                                </Box>
                            </Toolbar>
                        </AppBar>

                        <Paper elevation={1} sx={{
                            maxHeight : 'calc(100vh - 300px)',
                            overflowY: 'auto',
                            justifyContent: 'centre',
                            alignItems: 'centre'
                        }}>
                            {selection && (
                                <Timeline position="alternate">
                                    <CustomTimelineItem
                                        status={overlay.data?.status}
                                        codes={codes}
                                        isLast={!overlay.data?.changes.length}
                                        position={'left'}
                                        displayMode={displayMode}
                                    />

                                    {overlay.data?.changes.slice().reverse().map((change, index, reversedArray) => {
                                        const position = index % 2 === 0 ? 'right' : 'left';
                                        return (
                                            <CustomTimelineItem
                                                key={index}
                                                status={change.status}
                                                codes={codes}
                                                isLast={index === reversedArray.length - 1}
                                                position={position}
                                                displayMode={displayMode}
                                            />

                                        );
                                    })}
                                </Timeline>
                            )}
                        </Paper>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};


export default MonthlyGrid;
