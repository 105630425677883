import React, {useState, useEffect} from 'react';
import {fetchCodes, addCode, editCode, deleteCode, createDefaultCode} from './data';
import PickEmoji from "./emojiPicker";
import CodesListBox from "../ListBoxes/codesListbox";
import {getContrastingColor} from "../Color/color";
import {AppBar, Box, Button, IconButton, Input, Paper, Popover, TextField, Toolbar} from "@mui/material";
import CalendarViewDayIcon from "@mui/icons-material/CalendarViewDay";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ColorInput, ColorInputsContainer} from "../Styles/colorinput";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {UsersWithSameActivity} from "../Presence/presence";


const CodeList = ({onCloseWindow}) => {
    const [selectedCode, setSelectedCode] = useState('');


    const handleSelectCode = (code) => {
        setSelectedCode(code)
    };

    const handleInputChange = (event) => {
        const {name, value, type, checked} = event.target;
        setSelectedCode(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const saveButtonClicked = async (e) => {
        e.preventDefault();
        if (selectedCode.id === -1) {
            await addCode(selectedCode).then(value => setSelectedCode(selectedCode)
            );
        } else {
            if (selectedCode.id !== '') {
                await editCode(selectedCode.id, selectedCode).then(value => setSelectedCode(selectedCode));
            }
        }
    };

    const handleDeleteCode = async (codeId) => {
        if (!codeId) {
            console.log("No code selected for deletion");
            return;
        }
        await deleteCode(codeId);
    };


    const [chosenEmoji, setChosenEmojiState] = useState('');

    // Wrapper function with extra logic
    const setChosenEmoji = (emoji) => {
        console.log('Setting emoji:', emoji);
        // Directly modifying the state like this: selectedCode.emoji = emoji; won't trigger a re-render
        setSelectedCode(prevState => ({
            ...prevState,
            emoji: emoji, // Update the emoji property correctly
        }));
        setChosenEmojiState(emoji); // This is fine for any other logic that depends on the emoji state directly
    };

    const [anchorEl, setAnchorEl] = useState(null); // For Popover control
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl); // Popover open state

    return (
        <Paper sx={{width: '60%', minWidth: '900px', padding: 2, marginTop: 2}}>
            <AppBar position="static">
                <Toolbar>
                    <InsertEmoticonIcon/>
                    <Grid container alignItems="center" padding={1} spacing={2}>
                        <Grid item sx={{flexGrow: 1}}>
                            <Typography variant="h6">
                                Booking Codes
                            </Typography>
                        </Grid>
                        <Grid item>
                            <UsersWithSameActivity currentActivity={'codes'}/>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                            <CloseIcon/>
                        </IconButton>
                    </Grid>
                </Toolbar>

            </AppBar>
            <Box mt={4}>
                <Grid container spacing={2}>


                    {/* Left Column for CodesListBox and Buttons */}
                    <Grid item xs={12} sm={4}>
                        <Box border={1} borderColor="grey.500" borderRadius={2} position="relative" p={2}
                             sx={{height: '500px', mb: 2}}>
                            <Typography variant="h7"
                                        sx={{position: 'absolute', top: -14, left: '10px', background: 'white', px: 1}}>
                                Booking Codes
                            </Typography>
                            <CodesListBox selectedCode={selectedCode} setSelectedCode={handleSelectCode} size={15}/>
                        </Box>
                        <Button variant='contained' type="button" onClick={createDefaultCode} sx={{marginRight: 1}}>New</Button>
                        <Button variant='contained' type="button" onClick={() => handleDeleteCode(selectedCode.id)}
                                color="error">Remove</Button>
                    </Grid>

                    {/* Right Column for Details */}
                    <Grid item xs={12} sm={8}>
                        <Box border={1} borderColor="grey.500" borderRadius={2} p={2} sx={{height: 'auto'}}>
                            <Grid container spacing={2} direction="column">
                                <Grid item>
                                    <TextField label="Code" variant="filled" name="code" value={selectedCode.code || ''}
                                               onChange={(e) => setSelectedCode({
                                                   ...selectedCode,
                                                   code: e.target.value.toUpperCase()
                                               })} inputProps={{maxLength: 2}} sx={{width: "100px"}} required/>
                                </Grid>
                                <Grid item>
                                    <TextField label="Description" variant="filled" name="text"
                                               value={selectedCode.text || ''} onChange={(e) => setSelectedCode({
                                        ...selectedCode,
                                        text: e.target.value
                                    })} fullWidth required/>
                                </Grid>

                                <Grid item container spacing={2}>
                                    <Grid item>
                                        {selectedCode.emoji ? (
                                            <div>
                                                <Typography>Emoji</Typography>
                                                <span style={{fontSize: '32px'}}>{selectedCode.emoji}</span>
                                            </div>
                                        ) : (
                                            <div>
                                                <Typography>Emoji</Typography>
                                                <span style={{fontSize: '12px'}}>None</span>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item>
                                        <Button  startIcon={<AddIcon/>} aria-describedby="emoji-popover"
                                                onClick={handlePopoverOpen}>
                                            Add Emoji
                                        </Button>
                                    </Grid>

                                    <Popover
                                        id="emoji-popover"
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handlePopoverClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                    >
                                        <Typography sx={{p: 2}}>
                                            <PickEmoji chosenEmoji={chosenEmoji} setChosenEmoji={setChosenEmoji}/>
                                        </Typography>
                                    </Popover>

                                </Grid>
                                {/* Color Inputs Container for Background and Foreground */}
                                <Grid item container spacing={2}>
                                    <Grid item xs={4}>
                                        <Typography>Background</Typography>
                                        <TextField type="color" name="backgroundColor" value={selectedCode.background}
                                                   onChange={(e) => {
                                                       const newBackground = e.target.value;
                                                       const newForeground = getContrastingColor(newBackground);
                                                       setSelectedCode({
                                                           ...selectedCode,
                                                           background: newBackground,
                                                           foreground: newForeground
                                                       });
                                                   }} fullWidth/>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography>Foreground</Typography>
                                        <TextField type="color" name="foregroundColor" value={selectedCode.foreground}
                                                   onChange={(e) => setSelectedCode({
                                                       ...selectedCode,
                                                       foreground: e.target.value
                                                   })} fullWidth/>
                                    </Grid>
                                </Grid>
                                <Grid item sx={{mt: 2, alignSelf: 'flex-end'}}>
                                    <Button variant='contained' onClick={saveButtonClicked}>Save</Button>
                                </Grid>
                            </Grid>
                        </Box>


                    </Grid>
                </Grid>
            </Box>
        </Paper>
    );
};

export default CodeList;