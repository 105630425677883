import React, {useEffect, useState} from 'react';
import {useUsers} from './userService';
import {
    Avatar, Badge,
    Box,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem, Paper,
    Select, Tooltip, Typography, useTheme
} from '@mui/material';
import {updateUserSelected} from "../App/App";
import {isUserOnline, useOnlineUsers, userLastActive} from "../Presence/presence";

const UserListBox = ({size, ...otherProps}) => {
    return size > 1 ? <UserListSelect {...otherProps} /> : <UserListCombo {...otherProps} />;
};

export const calculateTimeDifferenceInMinutes = (lastActiveTimestamp) => {
    const currentTime = new Date().getTime(); // Current time in milliseconds
    const timeDifference = currentTime - lastActiveTimestamp; // Difference in milliseconds
    return Math.floor(timeDifference / 60000); // Convert milliseconds to minutes
};

export function OnlineAvatar({user, online, lastActive, size}) {
    const theme = useTheme();
    const timeDiff = calculateTimeDifferenceInMinutes(lastActive)
    let background = '#00aa00'
    if (timeDiff > 10) {
        background = '#aaaaaa'
    }

    let drawSize = '48px'
    let onlineBadgeStyle = {
        height: '14px',
        width: '14px',
        borderRadius: '50%',
        backgroundColor: background, // Green for online
        border: `2px solid ${theme.palette.background.paper}`,
        right: 4,
        bottom: 4,
    };

    if (size === 'small') {
        onlineBadgeStyle.height = '10px'
        onlineBadgeStyle.width = '10px'
        onlineBadgeStyle.right = 1;
        onlineBadgeStyle.bottom = 1;
        drawSize = '24px'
    }

    // Render the Avatar with a badge only if the user is online
    return online ? (
        <Badge
            overlap="circular"
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            variant="dot"
            badgeContent=" "
            sx={{'& .MuiBadge-dot': onlineBadgeStyle}}>
            <Avatar src={user?.photoURL} sx={{width: drawSize, height: drawSize}}
                    imgProps={{referrerPolicy: "no-referrer"}}/>
        </Badge>
    ) : (
        // Render just the Avatar without a badge if the user is offline
        <Avatar src={user?.photoURL} sx={{width: drawSize, height: drawSize}}
                imgProps={{referrerPolicy: "no-referrer"}}/>
    );
}


const UserListCombo = ({isActive, onSelectUser, selectedUser, size}) => {
    const {users} = useUsers(isActive);
    users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    const [hasInitialSelectionBeenMade, setHasInitialSelectionBeenMade] = useState(false);
    const {onlineUsers} = useOnlineUsers();


    useEffect(() => {
        if (users.length > 0 && !selectedUser && !hasInitialSelectionBeenMade) {
            onSelectUser(users[0]);
            updateUserSelected(users[0].email);
            setHasInitialSelectionBeenMade(true); // Prevent further auto-selection
        }
    }, [users, selectedUser, hasInitialSelectionBeenMade, onSelectUser]);

    return (
        <Paper sx={{width: '100%', padding: 1}}>
            <FormControl sx={{minWidth: 240}} variant="standard" size={size}>
                <Tooltip title="Select the therapist, whose data you would like to see." arrow>
                    <InputLabel id="therapist-select-label">Therapist</InputLabel>
                </Tooltip>
                <Select
                    labelId="therapist-select-label"
                    id="therapist-select"
                    value={selectedUser?.id || ''}
                    onChange={(e) => {
                        const selected = users.find(user => user.id === e.target.value);
                        updateUserSelected(selected.email);
                        onSelectUser(selected);
                    }}
                    label="Therapist">
                    {users.map((user) => (
                        <MenuItem key={user.id} value={user.id}>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                                <OnlineAvatar user={user} online={isUserOnline(user, onlineUsers)}
                                              lastActive={userLastActive(user, onlineUsers)} size={'small'}/>
                                <Typography noWrap>{user?.name}</Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Paper>
    );
};

const UserListSelect = ({isActive, onSelectUser, selectedUser}) => {
    const {users} = useUsers(isActive);
    users.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())); // Sort users alphabetically
    const [hasInitialSelectionBeenMade, setHasInitialSelectionBeenMade] = useState(false);
    const {onlineUsers} = useOnlineUsers();

    const isUserOnline = (user) => {
        return onlineUsers[user.id] && onlineUsers[user.id].status === 'online';
    };

    useEffect(() => {
        if (users.length > 0 && !selectedUser && !hasInitialSelectionBeenMade) {
            onSelectUser(users[0]);
            updateUserSelected(users[0].email);
            setHasInitialSelectionBeenMade(true);
        }
    }, [users, selectedUser, hasInitialSelectionBeenMade, onSelectUser]);

    return (
        <div style={{
            height: '600px',
            overflow: 'auto'
        }}>  {/* Ensure the parent has a defined height and overflow control */}
            <List sx={{
                maxHeight: '100%',  // This will now be relative to the 500px height of the parent
                overflowY: 'auto'
            }}>
                {users.map((user) => (
                    <ListItem
                        button
                        key={user.id}
                        selected={selectedUser?.id === user.id}
                        onClick={() => {
                            updateUserSelected(user.email);
                            onSelectUser(user);
                        }}>
                        <ListItemAvatar>
                            <OnlineAvatar user={user} online={isUserOnline(user)}
                                          lastActive={userLastActive(user, onlineUsers)} size={'large'}/>
                        </ListItemAvatar>
                        <ListItemText primary={user.name}/>
                    </ListItem>
                ))}
            </List>
        </div>

    );
};


export default UserListBox;
