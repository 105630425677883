import React, {useEffect, useState} from 'react';
import {useCodes} from '../Codes/data';
import {
    Avatar,
    Box,
    FormControl,
    InputLabel,
    List,
    ListItem,
    ListItemAvatar, ListItemText,
    MenuItem,
    Paper,
    Select, Tooltip,
    Typography
} from '@mui/material';
import {useUsers} from "./userService";
import {halfLuminance, RenderCodeBadge} from "../Booking/codebadge";


const CodesListBox = ({size, ...otherProps}) => {
    return size > 1 ? <CodesListSelect {...otherProps} /> : <CodesListCombo {...otherProps} />;
};

const renderCode = (selected) => {
    if (!selected) return null;
    const codes=[{
            code: selected.code,
            duration: 60,
            background: selected.background,
            foreground: selected.foreground,
            halfLuminance: halfLuminance(selected.background),
        }]

    return (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
            <RenderCodeBadge width={32} height={32} radius={15} codes={codes} hasBackground={false}/>
            <Typography>{selected.emoji}</Typography>
            <Typography>{selected.text}</Typography>
        </Box>
    );
};

const CodesListCombo = ({selectedCode, setSelectedCode, size = 0}) => {
    const {codes} = useCodes();
    const [hasInitialSelectionBeenMade, setHasInitialSelectionBeenMade] = useState(false);

    useEffect(() => {
        if (codes.length > 0 && !selectedCode && !hasInitialSelectionBeenMade) {
            setSelectedCode(codes[0]);
            setHasInitialSelectionBeenMade(true);
        }
    }, [codes, selectedCode, hasInitialSelectionBeenMade, setSelectedCode]);


    const renderValue = (selectedId) => {
        const selected = codes.find(code => code.id === selectedId);
        if (!selected) return null;
        return (
            renderCode(selected)
        );
    };

    return (
        <Paper sx={{width: '100%', padding: 1}}>
            <FormControl variant="standard" sx={{minWidth: 240}} size="small">
                <Tooltip title="Select a predefined code to be applied to a time slot. Codes are managed from Booking Codes." arrow>
                <InputLabel id="code-select-label">Select Code To Apply</InputLabel>
                </Tooltip>
                <Select
                    labelId="code-select-label"
                    id="code-select"
                    value={selectedCode?.id || ''}
                    onChange={(e) => setSelectedCode(codes.find(code => code.id === e.target.value))}
                    renderValue={renderValue}
                >
                    {codes.map((code) => (
                        <MenuItem key={code.id} value={code.id}>
                            {renderValue(code.id)}
                        </MenuItem>
                    ))}
                </Select>

            </FormControl>

        </Paper>
    );
};


const CodesListSelect = ({selectedCode, setSelectedCode}) => {
    const {codes} = useCodes();
    const [hasInitialSelectionBeenMade, setHasInitialSelectionBeenMade] = useState(false);

    useEffect(() => {
        if (codes.length > 0 && !selectedCode && !hasInitialSelectionBeenMade) {
            setSelectedCode(codes[0]);
            setHasInitialSelectionBeenMade(true);
        }
    }, [codes, selectedCode, hasInitialSelectionBeenMade]);

    return (
        <List sx={{
            width: '100%', // Adjust width as needed
            maxHeight: '100%',
            overflowY: 'auto' // Enable vertical scrolling
        }}>
            {codes.map((code) => (
                <ListItem
                    button
                    key={code.id}
                    selected={selectedCode?.id === code.id}
                    onClick={() => (setSelectedCode(code))}
                >{renderCode(code)}
                </ListItem>
            ))}
        </List>
    );
};

export default CodesListBox;
