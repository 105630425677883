import React, {useMemo, useState} from "react";
import {useTheme} from "@mui/material";

function isTextOnly(str) {
    // Regex to match common text characters: letters, numbers, punctuation, and some symbols.
    const textRegex = /^[a-zA-Z0-9\s.,!?;:'"(){}[\]/\\@#$%^&*+=-_`~]+$/;
    return textRegex.test(str);
}


const MemoizedBadge = React.memo(Badge);


const Highlight = ({id, x, y, rx, ry}) => {
    return (
        <>
            <defs>
                <radialGradient id={id} cx="50%" cy="50%" r="100%" fx="50%" fy="50%">
                    <stop offset="0%" stopColor="#ffffff" stopOpacity="0.4"/>
                    <stop offset="100%" stopColor="#ffffff" stopOpacity="0"/>
                </radialGradient>
            </defs>
            <ellipse
                cx={x}
                cy={y}
                rx={rx}
                ry={ry}
                fill={`url(#${id})`}
            />

        </>
    );
};

const MemoizedHighlight = React.memo(Highlight, (prevProps, nextProps) => {
    return prevProps.x === nextProps.x &&
        prevProps.y === nextProps.y &&
        prevProps.rx === nextProps.rx &&
        prevProps.ry === nextProps.ry &&
        prevProps.id === nextProps.id;
});


function RadialGradient({gradientId, color, hl}) {
    return (
        <>
            <defs>
                <radialGradient id={gradientId} cx="50%" cy="50%" r="50%" fx="50%" fy="30%">
                    <stop offset="0%" style={{stopColor: color, stopOpacity: 1}}/>
                    <stop offset="85%" style={{stopColor: color, stopOpacity: 1}}/>
                    <stop offset="100%" style={{stopColor: hl, stopOpacity: 1}}/>
                </radialGradient>
            </defs>
        </>
    );
}

function adjustTextRadius(segmentWidth, baseRadius, maxRadius) {
    // As the segment gets narrower (smaller segmentWidth), increase the radius.
    // Use a formula to ensure that as segmentWidth decreases, the multiplier increases.
    const factor = 360 / Math.max(segmentWidth, 1); // Avoid division by zero
    return baseRadius + (maxRadius - baseRadius) * Math.min(factor / 10, 1); // Limit the maximum effect
}






export function RenderCodeBadge({width, height, radius, codes, hasBackground = true}) {
    const highlightId = useMemo(() => `highlight-${Date.now()}`, []);
    const highlightOffset = radius * 0.5; // Offset from center towards top
    const highlightRadius = radius * 0.3; // Highlight radius as a fraction of main radius
    const strokeWidth = 1; // Width of the stroke for the border
    const theme = useTheme();

    const circumRadius = Math.sqrt(width ** 2 + height ** 2) / 2;

    const svgStyles = useMemo(() => ({
        width: `${width}px`,
        height: `${height}px`,
        viewBox: `0 0 ${width} ${height}`,
    }), [width, height]);

    const clipPathId = useMemo(() => `clip-${Date.now()}`, []);
    const cornerRadius = height / 10;
    const centerx = width / 2
    const centery = height / 2
    let startAngle = 0;
    const minimumMidpointWidth = width / 4;
    const minFontSize = 5;
    const slices = codes.map((code, index) => {
        const endAngle = startAngle + (code.duration / 60) * 360;
        const slicePath = describeArc(centerx, centery, radius * 0.97, startAngle, endAngle);
        const segmentWidth = endAngle - startAngle;
        let arcMidpointRadius = radius * 0.5;
        let chordLength = 2 * arcMidpointRadius * Math.sin(segmentWidth * Math.PI / 360); // Chord length formula
        let textRadius = arcMidpointRadius
        if (chordLength < minimumMidpointWidth) {
            let baseRadius = radius * 0.5;
            textRadius = adjustTextRadius(segmentWidth, baseRadius, radius * 0.75);
        }
        const midAngle = (startAngle + endAngle) / 2
        const midPoint = polarToCartesian(centerx, centery, textRadius, midAngle);

        const iconMidPoint = polarToCartesian(centerx, centery, textRadius * 0.4, midAngle);

        startAngle = endAngle; // update startAngle for next slice
        const sliceArea = (Math.PI * ((radius * 0.97) ** 2)) * (code.duration / 360);
        let fontSize = Math.sqrt(sliceArea) / 1.8; // Example heuristic for scaling text size
        fontSize = Math.max(fontSize, minFontSize);
        const iconSize = fontSize * 3

        const iconPath = "M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"
        return (
            <>
                <path
                    key={index}
                    d={slicePath}
                    fill={code.background}
                    stroke={'#000'}
                    strokeWidth={strokeWidth}
                    opacity={0.7}
                />
                <text
                    x={midPoint.x}
                    y={midPoint.y}
                    fill={code.foreground}
                    fontSize={`${fontSize}px`}
                    textAnchor="middle"
                    dominantBaseline="central">
                    {code.code}

                </text>
                {/*    <path
                    d={iconPath}
                    transform={`translate(${midPoint.x - iconSize / 2}, ${midPoint.y - iconSize / 2}) scale(${iconSize / 24})`}
                    fill={code.foreground}
                    opacity={0.25}
                />*/}
            </>
        );
    });

    startAngle = 0
    const sliceslarge = codes.map((code, index) => {
        let endAngle = startAngle + (code.duration / 60) * 360;
        const slicePath = describeArc(centerx, centery, circumRadius, startAngle, endAngle);
        startAngle = endAngle;
        let color = code.halfLuminance
        if(code.code === "") {
            color = theme.palette.background.paper
        }
        return (
            <path
                key={index}
                d={slicePath}
                fill={color}
                stroke={color}
                opacity={1.0}
                strokeWidth={strokeWidth}
            />
        );
    });

    if (codes.length === 1 && codes[0].duration === 60) {
        return (
            <svg {...svgStyles}>
                <defs>
                    <clipPath id={clipPathId}>
                        <rect x="0" y="0" width={width} height={height} rx={cornerRadius} ry={cornerRadius}/>
                    </clipPath>
                </defs>
                <g clipPath={`url(#${clipPathId})`}>
                    {hasBackground && <circle cx={centerx} cy={centery} r={circumRadius} opacity={1.0} fill={codes[0].halfLuminance}/>}
                    <MemoizedBadge cx={centerx} cy={centery} r={radius * 0.97} color={'#fff'} hl={'#000'}/>
                    <circle cx={centerx} cy={centery} r={radius} opacity={0.7} fill={codes[0].background}/>
                    <text
                        x={centerx}
                        y={centery}
                        fill={codes[0].foreground}
                        fontSize={isTextOnly(codes[0].code) ? radius * 0.6 : radius * 1.2}
                        textAnchor="middle"
                        dominantBaseline="central">
                        {codes[0].code}
                    </text>
                    <MemoizedHighlight
                        id={highlightId}
                        x={centerx}
                        y={centery - highlightOffset}
                        rx={highlightRadius * 2.5}
                        ry={highlightRadius * 1.5}
                    />
                    <circle cx={centerx} cy={centery} r={radius} fill="none" stroke="black" strokeWidth="1"/>
                </g>
            </svg>
        );
    }
    return (
        <svg {...svgStyles}>
            <defs>
                <clipPath id={clipPathId}>
                    <rect x="0" y="0" width={width} height={height} rx={cornerRadius} ry={cornerRadius}/>
                </clipPath>
            </defs>
            <g clipPath={`url(#${clipPathId})`}>
                {hasBackground && sliceslarge}
                <MemoizedBadge cx={centerx} cy={centery} r={radius * 0.95} color={'#fff'} hl={'#000'}/>
                {slices}
                <MemoizedHighlight
                    id={highlightId}
                    x={centerx}
                    y={centery - highlightOffset}
                    rx={highlightRadius * 2.5}
                    ry={highlightRadius * 1.5}
                />
            </g>
        </svg>
    );
}


function Badge({
    cx, cy, r, color, hl
}) {
    const gradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;
    return (
        <>
            <RadialGradient gradientId={gradientId} color={color} hl={hl}/>
            <circle cx={cx} cy={cy} r={r} fill={`url(#${gradientId})`}/>
        </>
    );
}


export function describeArc(x, y, radius, startAngle, endAngle) {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    const d = [
        "M", x, y,
        "L", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
        "L", x, y,
        "Z"  // Close the path
    ].join(" ");

    return d;
}

function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}


export function halfLuminance(color) {
    // Convert hex to RGB
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(3, 5), 16);
    let b = parseInt(color.substring(5, 7), 16);

    // Convert each RGB component to a linear value
    r = (r / 255) ** 2.2;
    g = (g / 255) ** 2.2;
    b = (b / 255) ** 2.2;

    // Reduce luminance by half
    r *= 0.5;
    g *= 0.5;
    b *= 0.5;

    //Convert linear RGB back to gamma-corrected RGB
    r = (r ** (1 / 2.2)) * 255;
    g = (g ** (1 / 2.2)) * 255;
    b = (b ** (1 / 2.2)) * 255;

    //Convert RGB back to hex
    r = Math.round(r).toString(16).padStart(2, '0');
    g = Math.round(g).toString(16).padStart(2, '0');
    b = Math.round(b).toString(16).padStart(2, '0');

    return `#${r}${g}${b}`;
}


