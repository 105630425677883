import React, {useCallback, useEffect, useRef, useState} from 'react';
import GridActionsComponent from "../Components/gridAction";
import {setHourCode} from "../Booking/dataServce";
import {describeArc, halfLuminance, RenderCodeBadge} from "../Booking/codebadge";

const MergeThreshold = 10;

const ControlPointRadius = 8;
const svgSize = 256 + 128; // SVG dimensions
const MaxSegments = 12;

const CircleRadius = (svgSize - 32) / 2;
const CircleCenter = {x: svgSize / 2, y: svgSize / 2};

// Define the radius limits
const innerRadius = CircleRadius * 0.75;
const outerRadius = CircleRadius;

export const polarToCartesian = (angleInDegrees, radius = CircleRadius) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: CircleCenter.x + radius * Math.cos(angleInRadians),
        y: CircleCenter.y + radius * Math.sin(angleInRadians),
    };
};

const Overlay = ({onMouseMove, onMouseUp}) => (
    <div
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            cursor: 'pointer',
            zIndex: 1000, // Ensure it's above other content
        }}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
    />
);


export const TimeCircle = ({
                               sessionUser,
                               selectedActiveTherapist,
                               year,
                               month,
                               day,
                               hour,
                               data,
                               codes,
                               onSaveProp,
                               onSegmentSelect
                           }) => {
    const svgRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedCode, setSelectedCode] = useState(null);


    const sortHandlesByAngle = (handles) => {
        // Sort handles by angle
        handles.sort((a, b) => {
            let aAngle = a.angle % 360;
            let bAngle = b.angle % 360;
            return aAngle - bAngle;
        });

        // Filter out handles with duplicate angles
        return handles.reduce((unique, handle) => {
            if (!unique.length || unique[unique.length - 1].angle % 360 !== handle.angle % 360) {
                unique.push(handle);
            }
            return unique;
        }, []);
    };

    const parseDataToHandles = (data) => {
        let handles = [];
        let startAngle = 0; // Initialize start angle

        if (!data || !data.status) {
            const newHandle = {
                angle: 0,
                moving: false
            };
            handles.push(newHandle)
            return handles;
        }

        // Traverse through each key in data.status
        for (const key in data.status) {
            const status = data.status[key];
            if (status && status.minutes) {
                const thisCode = codes.find(c => c.id === status.codeId)
                const endAngle = (status.minutes / 60) * 360;
                const newHandle = {
                    angle: startAngle, // Set the handle's angle to the current start angle
                    moving: false,
                    code: thisCode
                };
                handles.push(newHandle); // Add the new handle to the array
                startAngle += endAngle; // Increment the start angle by the end angle of the current segment
            }
        }
        return handles; // Return the list of handles
    };

    // Initialize handles using the parsing function
    const initialHandles = parseDataToHandles(data);
    const [handles, setHandles] = useState(initialHandles);

    // useEffect to update handles if data changes
    useEffect(() => {
        setHandles(parseDataToHandles(data));
    }, [data]);


    // Function to add a new handle based on click position
    const handleSvgClick = useCallback(async (e) => {

        const rect = svgRef.current.getBoundingClientRect();
        const clickX = e.clientX - rect.left - CircleCenter.x;
        const clickY = e.clientY - rect.top - CircleCenter.y;
        let clickAngle = (Math.atan2(clickY, clickX) * (180 / Math.PI) + 360 + 90) % 360;

        // Calculate the distance from the center to the click point
        const clickDistance = Math.sqrt(clickX * clickX + clickY * clickY);

        if (clickDistance > outerRadius) {
            return
        }

        // Check proximity to existing handles to avoid overlaps
        const isTooClose = handles.some(handle => Math.abs(handle.angle - clickAngle) < MergeThreshold);

        let foundSegment = {within: false}

        foundSegment = handles.reduce((acc, handle, index, array) => {
            const nextHandleAngle = (index < array.length - 1 ? array[index + 1].angle : 360);
            if ((handle.angle <= clickAngle && clickAngle < nextHandleAngle) ||
                (handle.angle > nextHandleAngle && (clickAngle < nextHandleAngle || clickAngle >= handle.angle))) {
                return {index: index, within: true, code: handle.code};
            }
            return acc;
        }, {index: -1, within: false, code: null});

        if (clickDistance > innerRadius && handles.length < MaxSegments && !isTooClose) {

            // Snap clickAngle to the nearest 1/12th position (every 30 degrees)
            clickAngle = Math.round(clickAngle / 30) * 30 % 360;

            const newHandle = {
                angle: clickAngle,
                moving: false,
                code: foundSegment.within ? foundSegment.code : null,
                color: '#FFFFFF'
            };
            await setHandles(prevHandles => sortHandlesByAngle([...prevHandles, newHandle]));

            onSave(false);

        }

        if (foundSegment.within) {
            console.log(foundSegment.index, clickDistance, innerRadius)
            updateSelectedSegmentIndex(foundSegment.index);
        } else {
            console.log("Segment not found")
        }



    }, [handles]);



    // Improved handle dragging using provided logic
    const startDragging = useCallback((index) => {
        setIsDragging(true)

        setHandles(prevHandles => prevHandles.map((handle, i) => ({
            ...handle,
            moving: i === index ? true : handle.moving
        })));

    }, []);




    const stopDragging = useCallback(async () => {
        setIsDragging(false)
        await setHandles(prevHandles => {
            // Only process if a handle was actually moving
            const movingHandleIndex = prevHandles.findIndex(handle => handle.moving);
            if (movingHandleIndex === -1) return prevHandles;

            // Calculate the nearest 1/12th position
            const movingHandle = prevHandles[movingHandleIndex];
            const closestSnapAngle = Math.round(movingHandle.angle / 30) * 30 % 360;

            // Update the moving handle to snap to the closest position
            const updatedHandles = prevHandles.map((handle, index) => {
                if (index === movingHandleIndex) {
                    return {...handle, angle: closestSnapAngle, moving: false}; // Also, stop the movement
                }
                return handle;
            });
            return sortHandlesByAngle(updatedHandles);
        });

        onSave(false);

    }, [handles]);



    const drawClockDivisions = () => {
        const divisions = [];
        const labels = [];
        const totalDivisions = 60; // One for each minute
        const labelEvery = 5; // Label every 5 minutes

        const radius = CircleRadius

        for (let i = 0; i < totalDivisions; i++) {
            const angle = (i / totalDivisions) * 360;
            const isMajor = i % labelEvery === 0;
            const {x: startX, y: startY} = polarToCartesian(angle, radius - (isMajor ? 10 : 5));
            const {x: endX, y: endY} = polarToCartesian(angle, radius);

            divisions.push(
                <line key={i} x1={startX} y1={startY} x2={endX} y2={endY} stroke="black" strokeWidth={isMajor ? 2 : 1}/>
            );

            if (isMajor) {
                const labelAngle = angle - 90; // Adjust for SVG coordinate system
                const {x, y} = polarToCartesian(labelAngle + 90, radius - 20);
                const label = ((i / labelEvery) * 5).toString();
                labels.push(
                    <text key={i} x={x} y={y} fill="black" fontSize="12" textAnchor="middle" alignmentBaseline="central"
                          style={{
                              pointerEvents: 'none',
                              userSelect: 'none'
                          }} // Prevent text from being selectable or interactive
                          transform={`rotate(${0},${x},${y})`}>
                        {label === "0" ? "60" : label}
                    </text>
                );
            }
        }

        return {divisions, labels};
    };

    const {divisions, labels} = drawClockDivisions();

    const onSave = async (recordHistory = true) => {
        console.log("Executing onSave");
        let changes = [];
        const currentHandles = handlesRef.current;
        const sortedHandles = [...currentHandles].sort((a, b) => a.angle - b.angle);
        for (let i = 0; i < sortedHandles.length; i++) {
            const currentHandle = sortedHandles[i];
            const nextHandle = sortedHandles[(i + 1) % sortedHandles.length];
            let angleDifference = nextHandle.angle - currentHandle.angle;
            if (angleDifference < 0) angleDifference += 360;
            let minutes = (angleDifference / 360) * 60;
            if (minutes === 0) {
                minutes = 60
            }

            changes.push({
                idx: i,
                code: currentHandle.code || null,
                minutes: Math.round(minutes)
            });
        }

        await setHourCode(sessionUser, selectedActiveTherapist, year, month, day, hour, changes, recordHistory);
    };


    const dragHandle = useCallback((e) => {
        const movingHandleIndex = handles.findIndex(handle => handle.moving);
        if (movingHandleIndex === -1 || movingHandleIndex === 0) return;

        const rect = svgRef.current.getBoundingClientRect();
        const dragX = e.clientX - rect.left;
        const dragY = e.clientY - rect.top;

        const dragAngle = (Math.atan2(dragY - CircleCenter.y, dragX - CircleCenter.x) * (180 / Math.PI) + 360 + 90) % 360;

        setHandles(prevHandles => {
            let newHandles = [...prevHandles];
            newHandles[movingHandleIndex].angle = dragAngle;

            const movingHandlePos = polarToCartesian(dragAngle);
            let minDistance = Infinity;
            let closestHandleIndex = -1;

            newHandles.forEach((handle, index) => {
                if (index !== movingHandleIndex) {
                    const handlePos = polarToCartesian(handle.angle);
                    const distance = Math.sqrt((handlePos.x - movingHandlePos.x) ** 2 + (handlePos.y - movingHandlePos.y) ** 2);

                    if (distance < minDistance) {
                        minDistance = distance;
                        closestHandleIndex = index;
                    }
                }
            });

            if (minDistance < ControlPointRadius && closestHandleIndex !== -1) {
                if (newHandles[closestHandleIndex].angle === 0) {
                    if (newHandles[movingHandleIndex].angle >= (360 - newHandles[movingHandleIndex].angle)) {
                        newHandles[movingHandleIndex].code = newHandles[closestHandleIndex].code;
                    }
                } else if (newHandles[movingHandleIndex].angle < newHandles[closestHandleIndex].angle) {
                    newHandles[movingHandleIndex].code = newHandles[closestHandleIndex].code;
                }

                newHandles[movingHandleIndex].angle = newHandles[closestHandleIndex].angle;

                newHandles.splice(closestHandleIndex, 1);
                let idx = selectedSegmentIndex - 1;
                idx = idx < 0 ? 0 : idx;
                updateSelectedSegmentIndex(idx);
            }

            return sortHandlesByAngle(newHandles);
        });
    }, [handles]);


    useEffect(() => {
        const mouseUpHandler = () => {
            setHandles(prevHandles => {
                if (prevHandles.length === 1) {
                    return [{...prevHandles[0], angle: 0}];
                }
                return prevHandles;
            });
        };

        window.addEventListener('mouseup', mouseUpHandler);
        return () => {
            window.removeEventListener('mouseup', mouseUpHandler);
        };
    }, [setHandles]);


    const drawHandleLinesToCenter = () => {
        return handles.map((handle, index) => {
            const handlePosition = polarToCartesian(handle.angle, CircleRadius * 0.9);
            return (
                <line
                    key={index}
                    x1={handlePosition.x}
                    y1={handlePosition.y}
                    x2={CircleCenter.x}
                    y2={CircleCenter.y}
                    stroke="grey" // Choose a color that fits the design
                    strokeWidth="1" // Adjust the thickness as needed
                />
            );
        });
    };

    const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(0);

    const [trigger, setTrigger] = useState(0);


    const updateSelectedSegmentIndex = (newIndex) => {
        setSelectedSegmentIndex(newIndex)
        setTrigger(prev => prev + 1)
    };


    const SegmentAngles = (index) => {
        let startAngle = 0
        let endAngle = 360
        if (handles.length > 0) {
            startAngle = handles[index]?.angle;

            if (selectedSegmentIndex === handles.length - 1) {
                endAngle = 360;
            } else {
                endAngle = handles[(index + 1) % handles.length].angle;
            }
        }
        return {start: startAngle, end: endAngle}
    }

    useEffect(() => {
        let angles = SegmentAngles(selectedSegmentIndex)
        angles.start *= 1 / 6
        angles.end *= 1 / 6
        onSegmentSelect(angles);
    }, [trigger]);


    const applyCode = async (e) => {
        e.preventDefault();

        const updatedHandles = handles.map((handle, index) => {
            if (index === selectedSegmentIndex) {
                return {...handle, color: selectedCode.background, code: selectedCode};
            }
            return handle;
        });
        await setHandles(updatedHandles);
        await onSave()
    };

    const clearCode = async (e) => {
        e.preventDefault();
        // Create a new array with updated handle colors
        const updatedHandles = handles.map((handle, index) => {
            if (index === selectedSegmentIndex) {
                return {...handle, color: '#FFFFFF', code: null};
            }
            return handle;
        });
        await setHandles(updatedHandles);
        await onSave()
    };


    const handlesRef = useRef(handles);

    useEffect(() => {
        handlesRef.current = handles;
    }, [handles]);


    useEffect(() => {
        console.log("Passing onSave to Parent")
        onSaveProp(onSave);
    }, [onSaveProp]);



    const drawSelectedSegment = () => {
        if (handles.length === 0 || selectedSegmentIndex === null) {
            return null;
        }

        const segmentAngles = SegmentAngles(selectedSegmentIndex);

        if (handles.length > 1) {
            // Draw segment arc for multiple handles
            return (
                <path
                    d={describeArc(CircleCenter.x, CircleCenter.y, innerRadius, segmentAngles.start, segmentAngles.end)}
                    fill="none"
                    opacity="1.0"
                    stroke="#000"
                    strokeWidth="4"
                />
            );
        } else {
            // Draw a full circle for a single handle
            return (
                <circle
                    cx={CircleCenter.x}
                    cy={CircleCenter.y}
                    r={innerRadius}
                    fill="none"
                    stroke="#000"
                    strokeWidth="4"
                />
            );
        }
    };


    const drawCircularSegments = () => {
        if (handles.length === 1) {
            // If there's only one handle, draw a full circle
            const handle = handles[0];
            const fill = handle.code?.background || '#FFFFFF'; // Use the color from the handle's code
            return (
                <circle
                    cx={CircleCenter.x}
                    cy={CircleCenter.y}
                    r={innerRadius}
                    fill={fill}
                    stroke="black"
                    strokeWidth="1"
                    onClick={() => updateSelectedSegmentIndex(0)}
                />
            );
        } else {
            // More than one handle, draw segments
            return handles.map((handle, index) => {
                const nextIndex = (index + 1) % handles.length;
                const nextHandle = handles[nextIndex];
                const startAngle = handle.angle;
                let endAngle = (index === handles.length - 1) ? 360 : nextHandle.angle;

                const fill = handle.code?.background || '#FFFFFF'; // Use the color from the handle's code

                return (
                    <path key={index}
                          d={describeArc(CircleCenter.x, CircleCenter.y, innerRadius, startAngle, endAngle)}
                          fill={fill}
                          stroke="black"
                          strokeWidth="1"
                          opacity="0.7"
                          onClick={() => updateSelectedSegmentIndex(index)}
                    />
                );
            });
        }
    };

    const transformSegmentData = (handles, codes, displayMode) => {
        return handles.map((handle, index) => {
            const codeId = handle.code?.id;
            const foundCode = codes.find(c => c.id === codeId) || {
                text: "Cleared",
                background: 'transparent',
                foreground: 'black',
                code: '',
                emoji: ''
            };

            if (handles.length === 1) {
                // If there is only one handle, set duration to 60 minutes
                return {
                    code: displayMode === "code" ? foundCode.code : foundCode.emoji,
                    duration: 60,
                    background: foundCode.background,
                    foreground: foundCode.foreground,
                    halfLuminance: foundCode.code ? halfLuminance(foundCode.background) : "#ffffff",
                };
            }

            // Calculate angle to next handle
            const nextIndex = (index + 1) % handles.length;
            const nextHandle = handles[nextIndex];
            let endAngle = (index === handles.length - 1) ? 360 : nextHandle.angle;
            const angleDifference = (endAngle - handle.angle + 360) % 360;  // Ensuring positive angle difference
            const minutes = angleDifference * (60 / 360);  // Convert angle difference to minutes

            return {
                code: displayMode === "code" ? foundCode.code : foundCode.emoji,
                duration: minutes,
                background: foundCode.background,
                foreground: foundCode.foreground,
                halfLuminance: halfLuminance(foundCode.background),
            };
        });
    };


    const badgeData = transformSegmentData(handles, codes, "code");

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center'}}>

                {isDragging && <Overlay onMouseMove={dragHandle} onMouseUp={stopDragging}/>}
                <svg ref={svgRef} width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}
                     onClick={handleSvgClick}
                     onMouseMove={dragHandle} onMouseUp={stopDragging}>

                    {transformSegmentData &&
                        <RenderCodeBadge width={svgSize} height={svgSize} radius={innerRadius} codes={badgeData}
                                         hasBackground={true}/>}

                    {/*{drawCircularSegments()}*/}
                    <circle cx={CircleCenter.x} cy={CircleCenter.y} r={CircleRadius} fill="none" stroke="black"
                            strokeWidth="2"/>

                    <circle cx={CircleCenter.x} cy={CircleCenter.y} r={innerRadius} fill="none" stroke="black"
                            strokeWidth="2"/>
                    {drawHandleLinesToCenter()}
                    {divisions}
                    {labels}
                    {handles.map((handle, index) => {
                        const isPartOfSelectedSegment = index === selectedSegmentIndex || index === (selectedSegmentIndex + 1) % handles.length;
                        const handleColor = index === 0 ? "black" : "white"; // 'orange' for selected, 'red' for others
                        const handlePosition = polarToCartesian(handle.angle, CircleRadius);
                        const radius = index === 0 ? ControlPointRadius : ControlPointRadius * 1.2

                        return (
                            <React.Fragment key={index}>
                                <circle
                                    cx={handlePosition.x}
                                    cy={handlePosition.y}
                                    r={radius}
                                    fill={handleColor}
                                    onMouseDown={() => startDragging(index)}
                                    style={{cursor: 'pointer'}}
                                />
                                <circle
                                    cx={handlePosition.x}
                                    cy={handlePosition.y}
                                    r={radius * 0.6}
                                    fill={"black"}
                                    onMouseDown={() => startDragging(index)}
                                    style={{cursor: 'pointer'}}
                                />
                                {   <text
                                    x={handlePosition.x}
                                    y={handlePosition.y}
                                    fill="white"
                                    fontSize="8"
                                    textAnchor="middle"
                                    alignmentBaseline="middle"
                                    style={{pointerEvents: 'none', userSelect: 'none'}}>
                                    {index}
                                </text>}
                            </React.Fragment>
                        );
                    })}

                    {handles.length > 0 && (
                        drawSelectedSegment()
                    )}

                </svg>
                <GridActionsComponent
                    selectedCode={selectedCode}
                    setSelectedCode={setSelectedCode}
                    onApply={applyCode}
                    onClear={clearCode}
                    centerButtons={true}
                />
            </div>
        </>

    );
};

