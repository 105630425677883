import styled from '@emotion/styled'
import React, {useState} from "react";

export const MenuItem = styled.a`
    display: block;
    padding: 10px;
    padding-left: 30px;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

 
    color: ${({ theme, $isSelected }) => $isSelected ? theme.selectedText : theme.textprimary};
    background-color: ${({ theme, $isSelected }) => $isSelected ? theme.selectedbg : 'transparent'};

    &:hover {
        background-color: ${({ theme, $isSelected }) =>
                $isSelected ? theme.selectedbg : theme.unselectedbg};
        color: ${({ theme, $isSelected }) =>
                $isSelected ? theme.selectedText : theme.unselectedText};
    }
`;

export const CollapsibleMenuItems = styled.div`
    display: ${({$isOpen}) => ($isOpen ? 'block' : 'none')};
`;

export const MenuCategory = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-top: 1px solid #ccc;
    color: ${(props) => props.theme.textprimary};
    background: ${(props) => props.theme.solidbg};

    &:last-child {
        border-bottom: 1px solid #ccc;
    }
 
`;

export const Arrow = styled.span`
    display: inline-block;
    transition: transform 0.3s ease;
    transform: ${({$isOpen}) => ($isOpen ? 'rotate(90deg)' : 'rotate(0)')};
    margin-right: 5px;
    cursor: pointer;
`;

export const CollapsibleMenu = ({title, children, style}) => {
    const [$isOpen, setIsOpen] = useState(true);
    const toggleOpen = () => setIsOpen(!$isOpen);
    return (
        <div style={style}>
            <MenuCategory onClick={toggleOpen}>
                <Arrow $isOpen={$isOpen}>▶</Arrow>
                {title}
            </MenuCategory>
            <CollapsibleMenuItems $isOpen={$isOpen}>
                {children}
            </CollapsibleMenuItems>
        </div>
    );
};
