import React, { useEffect, useState } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { Typography, Box } from '@mui/material';
import firebase from "firebase/compat/app";
import {hexToRgb} from "../Color/color";

function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

const QuoteOfTheDay = ({ user }) => {

    const [quote, setQuote] = useState('');
    const [author, setAuthor] = useState('');

    useEffect(() => {
        const db = firebase.firestore();
        if(user) {
            const unsubscribe = onSnapshot(doc(db, 'quotes', 'currentQuote'), (doc) => {
                if (doc.exists()) {
                    const data = doc.data();
                    setQuote(decodeHtml(data.quote));
                } else {
                    setQuote('');
                }
            });
            // Clean up subscription on unmount
            return () => unsubscribe();
        }
    }, [user]);


    const fontColor = `rgba(${hexToRgb(user?.dailyQuoteColor || '#FFFFFF', 0.7)})`;

    console.log(fontColor)

    return (
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: '-1',
            color: fontColor,
            fontFamily: 'Nunito, sans-serif',
        }}>
            <Typography variant="h4" component="p" sx={{ fontSize: '1.5rem', mb: 2 }}>
                {quote}
            </Typography>
        </Box>
    );
};

export default QuoteOfTheDay;
