import React, { useEffect } from 'react';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';

const options = [
    { value: 'pie', label: 'Pie' },
    { value: 'bar', label: 'Bar' },
    { value: 'stacked', label: 'Stacked' },
    { value: 'group', label: 'Grouped' },
];

const defaultSelectedOption = 'pie'; // Use the value directly for Material-UI Select

export const ReportOptions = ({ onOptionsChange, customStyles }) => {
    useEffect(() => {
        // Trigger initial option change with the default option
        onOptionsChange({ value: defaultSelectedOption, label: options.find(option => option.value === defaultSelectedOption).label });
    }, []);

    return (
        <FormControl variant="outlined" style={customStyles} fullWidth>
            <InputLabel id="report-option-select-label">Chart Options</InputLabel>
            <Select
                labelId="report-option-select-label"
                id="report-option-select"
                defaultValue={defaultSelectedOption}
                onChange={(e) => onOptionsChange(options.find(option => option.value === e.target.value))}
                label="Chart Options"
            >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
