import styled from '@emotion/styled'

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-around;
  gap: 15px; 
`;


export const Button = styled.button`
    background-color: ${props => props.color || "#007bff"};
    color: white;
    padding: 10px;
    max-width: 200px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    &:hover {
        background-color: #0056b3;
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.small {   
        max-width: 75px;
    }
    
`;
