import styled from '@emotion/styled'


export const Video = styled.video`
    object-fit: cover;
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    overflow: hidden;
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${({ theme }) => theme.mode === 'dark' ? 'rgba(0, 0, 0, 0.4)' : 'transparent'};
    z-index: 1;
    pointer-events: none;
`;

export const Quote = styled.div`
    margin: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
`;
