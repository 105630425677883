
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { unpackWorkingHours } from "../Users/data";
import {useEffect, useState} from "react";
import {userCollectionPath} from "../Firestore/paths";

export const getUserById = async (userID) => {
    try {
        const db = firebase.firestore();
        const doc = await db.collection(userCollectionPath).doc(userID).get();
        if (doc.exists) {
            const data = doc.data();
            const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
            return { id: doc.id, ...data, workingHours };
        } else {
            console.log("No such User : ", userID);
            return null;
        }
    } catch (error) {
        console.error("Error getting user : ", error);
        return null;
    }
};

export const useUsers = (isActive) => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const db = firebase.firestore();
        const unsubscribe = db.collection(userCollectionPath)
            .where('active', '==', isActive)
            .onSnapshot(snapshot => {
                const updatedTherapists = snapshot.docs.map(doc => {
                    const data = doc.data();
                    const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
                    return { id: doc.id, ...data, workingHours };
                }).sort((a, b) => a.name.localeCompare(b.name));
                setUsers(updatedTherapists);
            });

        return () => unsubscribe();
    }, [isActive]);

    return { users };
};

export const getUserByEmail = async (email) => {
    console.log("Fetch : ",email)
    try {
        const db = firebase.firestore();
        const querySnapshot = await db.collection(userCollectionPath).where('email', '==', email).get();
        if (!querySnapshot.empty) {
            const doc = querySnapshot.docs[0]; // Assuming email is unique and only returns one document
            const data = doc.data();
            const workingHours = data.workingHours ? unpackWorkingHours(data.workingHours) : {};
            return { id: doc.id, ...data, workingHours };
        } else {
            console.log("No user found with the email:", email);
            return null;
        }
    } catch (error) {
        console.error("Error getting user by email:", error);
        return null;
    }
};
