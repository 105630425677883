import React, {useEffect, useRef, useState} from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import UserListBox from "../ListBoxes/userListBox";
import CodesListBox from "../ListBoxes/codesListbox";
import {DatePickerComponent} from "./DatePicker";
import {CalendarLabel, CalendarNumbers, Circle, HourCircleContainer} from "../Styles/clock";
import {setHourCode, useFetchAndSubscribeDayData} from "./dataServce";
import {useCodes} from "../Codes/data";
import {createTitleFromHour} from "../Notes/notes";
import {TodayOptions} from "./todayoptions";
import {NoteEditor} from "../Notes/noteeditor";
import SettingsIcon from '@mui/icons-material/Settings';
import {
    AppBar,
    Button,
    IconButton,
    Paper, Popover,
    Toolbar, Tooltip,
    Typography, useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import TodayIcon from "@mui/icons-material/Today";
import styled from "@emotion/styled";
import {useOnlineUsers, UsersWithSameActivity} from "../Presence/presence";
import {getStatus} from "./MonthlyGrid";
import GridActionsComponent from "../Components/gridAction";
import CloseButton from "../Components/closeButton";
import {getCellStatus} from "./gridcell";


export const OuterHourCircleContainer = styled.div`
    position: relative;
    width: 50%; // or whatever percentage you desire
    margin: auto; // this will help center the container
    height: 0;
    padding-bottom: 50%; // this keeps the aspect ratio square
`;


const defaultSelectedOptions = [
    { label: 'Emojis', value: 'emojis' },
    { label: 'Labels', value: 'labels' },
    { label: 'Smooth', value: 'smooth' },
];

export const Today = ({onCloseWindow}) => {
    const { me } = useOnlineUsers();
    const sessionUser = me();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [selectedCode, setSelectedCode] = useState('');
    const {codes} = useCodes();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [day, setDay] = useState('')
    const [hour, setHour] = useState(-1)
    const [displayOptions, setDisplayOptions] = useState(defaultSelectedOptions);
    const [selectedTherapist, setSelectedTherapist] = useState('');
    const {data: dayData, loading, error} = useFetchAndSubscribeDayData(selectedTherapist, year, month, day);

    const [combinedData, setCombinedData] = useState([]);

    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';



    useEffect(() => {
        if (dayData && codes) {
            const combinedArray = Object.keys(dayData.hours).map(hour => {
                const hourData = dayData.hours[hour];
                const status = getCellStatus(hourData,0)
                const codeInfo = codes.find(code => code.id === status);
                return {
                    hour: hour,
                    code: codeInfo ? codeInfo.code : null,
                    background: codeInfo ? codeInfo.background : '#eeeeee',
                    note: hourData.notes || '',
                    label: codeInfo?.text,
                    foreground: codeInfo?.foreground,
                    emoji: codeInfo?.emoji
                };
            });

            const fullCombinedArray = [];
            for (let i = 0; i < 24; i++) {
                const existingEntry = combinedArray.find(entry => parseInt(entry.hour) === i);
                if (existingEntry) {
                    fullCombinedArray.push(existingEntry);
                } else {

                    let entry = {
                        hour: i.toString(),
                            code: null,
                        background: '#eeeeee',
                        note: '',
                        label: '',
                        foreground: '#000000',
                        emoji: ''
                    }

                    if(isDarkMode){
                        entry.background = '#1e1e1e'
                        entry.foreground = 'white'
                    }

                    fullCombinedArray.push(entry);
                }
            }

            setCombinedData(fullCombinedArray);
        } else {

            let baseEntry = {
                hour: 0,
                code: null,
                background: '#FFFFFF',
                note: '',
                label: '',
                foreground: '#000000',
                emoji: ''
            };

            if (isDarkMode) {
                baseEntry.background = '#1e1e1e';
                baseEntry.foreground = 'white';
            }

            const defaultArray = new Array(24).fill(null).map((_, i) => ({
                ...baseEntry, // Spread the baseEntry to clone its properties
                hour: i.toString() // Set the current hour
            }));

            setCombinedData(defaultArray);
        }
    }, [dayData, codes, isDarkMode]);


    const handleDateChange = (year, month, day) => {
        const newDate = new Date(Date.UTC(year, month, day));
        // Compare the time values of the dates
        if (newDate.getTime() !== selectedDate.getTime()) {
            setSelectedDate(newDate);
            const isoDate = newDate.toISOString().substr(0, 10)
            const year = isoDate.substring(0, 4);
            const month = isoDate.substring(5, 7);
            const day = isoDate.substring(8, 10);
            setYear(year)
            setMonth(month)
            setDay(day)
        }
    };

    const handleActiveTherapistSelection = (therapist) => {
        setSelectedTherapist(therapist);
    };

    const applyCodeToSelection = async (e) => {
        e.preventDefault();
        await setHourCode(sessionUser,selectedTherapist, year, month, day, hour, [{idx:0,code:selectedCode,minutes:60}])
    };

    const clearSelection = async (e) => {
        e.preventDefault();
        await setHourCode(sessionUser,selectedTherapist, year, month, day, hour, [{idx:0,code:null,minutes:60}])
    };

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({x: 0, y: 0});
    const [noteInfo, setNoteInfo] = useState(null);

    const handleDoubleClick = (e) => {
        if (hour >= 0) {
            console.log("--> ", hour)
            setPopupPosition({x: e.clientX, y: e.clientY});

            const content = dayData?.hours[hour]?.notes;
            const accesscode = dayData?.hours[hour]?.accesscode;
            const email = dayData?.hours[hour]?.email;

            setNoteInfo({content, selectedTherapist, year, month, day, hour, accesscode, email})
            setPopupVisible(true);
        }
    };

    const handleOptions = (options) => {
        setDisplayOptions(options)
    };

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;



    return (
        <Paper sx={{width: '80%', padding: 2}}>
            <AppBar position="static">
                <Toolbar variant='dense' sx={{padding: 1}}>
                    <TodayIcon/>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item sx={{flexGrow: 1}}>
                            <Typography variant="h6" component="div" sx={{flexGrow: 1, padding: 1}}>
                                Today
                            </Typography>
                        </Grid>
                        <Grid item>
                            <UsersWithSameActivity currentActivity={'today'} email={selectedTherapist.email}/>
                        </Grid>
                        <Grid item>
                            <UserListBox
                                isActive={true}
                                onSelectUser={handleActiveTherapistSelection}
                                selectedUser={selectedTherapist}
                                size={0}
                            />
                        </Grid>
                        <Grid item>
                        <GridActionsComponent
                            selectedCode={selectedCode}
                            setSelectedCode={setSelectedCode}
                            onApply={applyCodeToSelection}
                            onClear={clearSelection}
                        />
                        </Grid>
                        <Grid item>
                            <Tooltip title="Customise the view" arrow>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
                                <SettingsIcon/>
                            </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <CloseButton onCloseWindow={onCloseWindow}/>
                </Toolbar>
            </AppBar>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                padding={2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div style={{padding: '20px',minWidth:'250px'}}> {/* Inline style for padding */}
                    <TodayOptions selectedOptions={displayOptions}
                                 setSelectedOptions={setDisplayOptions}/>
                </div>

            </Popover>

            <div style={{padding: '20px'}}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <OuterHourCircleContainer margin={10}>
                        <HourCircle style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}
                                    data={combinedData}
                                    hasLabels={true} currentIndex={currentIndex}
                                    currentDate={selectedDate} selectHour={setHour} onDoubleClick={handleDoubleClick}
                                    displayOptions={displayOptions}
                                    isDarkMode = {isDarkMode}/>
                    </OuterHourCircleContainer>
                </div>
            </div>
                <AppBar position="static" color="primary" sx={{top: 'auto', bottom: 0}}>
                    <Toolbar variant='dense'>
                        <Grid container alignItems="center" spacing={2}>
                            <Grid item sx={{flexGrow: 1}}>
                                <DatePickerComponent onDateChange={handleDateChange} mode={'day'}/>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <NoteEditor
                    title={hour >= 0 ? createTitleFromHour(noteInfo?.hour) : ''}
                    visible={popupVisible && noteInfo?.hour >= 0}
                    position={popupPosition}
                    onClose={() => setPopupVisible(false)}
                    noteInfo={noteInfo}
                />
        </Paper>

);
};


const HourCircle = ({
                        data,
                        hasLabels,
                        currentIndex,
                        currentDate,
                        selectHour,
                        onDoubleClick,
                        displayOptions,
                        isDarkMode = false
                    }) => {
    const segmentMap = [11, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 23, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
    const containerRef = useRef(null);
    const [radius, setRadius] = useState(0);
    const [selectedSegment, setSelectedSegment] = useState(null);
    const [centerX, setCenterX] = useState(null);
    const [centerY, setCenterY] = useState(null);

    const isDisplayOption = (valueToCheck) => {
        const result =  displayOptions.some(option => option.value === valueToCheck);
        return result
    };

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            // Define a function to update the radius
            const updateRadius = () => {
                const width = container.offsetWidth;
                const height = container.offsetHeight; // Assuming a square, but adjust if not
                // Update state for radius, centerX, and centerY
                setRadius(width / 2);
                setCenterX(width / 2);
                setCenterY(height / 2); // Adjust this if your container is not a squar
            };

            // Create a ResizeObserver to observe the container
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    updateRadius(); // Call updateRadius whenever the container size changes
                }
            });

            // Start observing the container
            resizeObserver.observe(container);

            // Cleanup function to stop observing when the component unmounts
            return () => resizeObserver.disconnect();
        }
    }, []); // Empty dependency array ensures this effect runs only on mount and unmount

    const calculateLabelPosition = (hour, radius, isSmallCircle) => {
        const adjustmentFactor = isSmallCircle ? 0.55 : 0.85; // Adjust if the smaller circle is a different size
        const adjustedRadius = radius * adjustmentFactor;

        // Assuming the circle's center is (0,0)
        const angleRad = ((hour % 12) * 30) * (Math.PI / 180) - (90 * (Math.PI / 180)); // Convert degrees to radians
        const x = adjustedRadius * Math.cos(angleRad);
        const y = adjustedRadius * Math.sin(angleRad);

        return {
            left: `calc(50% + ${x}px)`,
            top: `calc(50% + ${y}px)`,
            transform: 'translate(-50%, -50%)'
        };
    };

    // const [debugLine, setDebugLine] = useState({x1: 0, y1: 0, x2: 0, y2: 0, show: false});


    const handleCircleClick = (e) => {
        const containerRect = e.currentTarget.getBoundingClientRect();
        const centerX = containerRect.left + containerRect.width / 2;
        const centerY = containerRect.top + containerRect.height / 2;
        const clickX = e.clientX;
        const clickY = e.clientY;

        const dx = clickX - centerX;
        // Original: const dy = centerY - clickY; Adjusting back for clarity
        const dy = clickY - centerY;

        const distanceFromCenter = Math.sqrt(dx * dx + dy * dy);
        const outerCircleRadius = radius;
        const innerCircleRadius = radius * 0.7; // Inner circle radius

        if (distanceFromCenter <= outerCircleRadius && distanceFromCenter >= radius * 0.4) {
            // Correcting angle to start from the top and increase clockwise
            // Inside handleCircleClick, after calculating dx and dy
            let angle = Math.atan2(dy, dx) * (180 / Math.PI)
            angle = (angle + 360 + 60) % 360; // Normalize angle

            // Calculate segment index; note that segments are 0-indexed but displayed as 1-indexed
            const segmentPerCircle = 12;
            let segmentIndex = Math.floor(angle / (360 / segmentPerCircle));

            // Adjust for clicks on the outer circle, if necessary
            if (distanceFromCenter < innerCircleRadius) {
                segmentIndex += 12; // For outer circle segments
            }

            setSelectedSegment(segmentIndex);
            selectHour(segmentMap.indexOf(segmentIndex))

            console.log("Hour Selected :", segmentMap.indexOf(segmentIndex))

        } else {
            setSelectedSegment(null)
            selectHour(-1)
        }
    };

    const [animatedSegments, setAnimatedSegments] = useState([
        {segment: new Date().getHours() - 1, color: 'white'},
        {segment: selectedSegment, color: 'black'}
    ]);


    // Assuming setSelectedSegment stores the selected segment index
    const DrawSelectedSegment = (segment, background, color, drawOverlay = false, visible = true) => {

        const pathRef = useRef(null); // Ref to access the SVG path element
        const [pathLength, setPathLength] = useState(0); // State to store the path length

        useEffect(() => {
            if (pathRef.current) {
                const length = pathRef.current.getTotalLength();
                setPathLength(length);
            }
        }, [segment]); // Depend on `segment` to recalculate when it changes

        if (segment == null) return null;

        // Start and end angles adjusted to start from the top
        const segmentAngleStart = ((segment % 12) * 30) - 60;
        const segmentAngleEnd = segmentAngleStart + 30;

        // Convert angles to radians
        const startRadians = (Math.PI / 180) * segmentAngleStart;
        const endRadians = (Math.PI / 180) * segmentAngleEnd;

        // Radii
        const outerRadius = radius * 0.98; // Outer circle radius
        const innerRadius = radius * 0.7; // Inner circle radius
        const innermostRadius = radius * 0.4; // New innermost circle radius, for drawing inner segments

        // Points on the outer arc
        const outerStartX = outerRadius * Math.cos(startRadians) + centerX;
        const outerStartY = outerRadius * Math.sin(startRadians) + centerY;
        const outerEndX = outerRadius * Math.cos(endRadians) + centerX;
        const outerEndY = outerRadius * Math.sin(endRadians) + centerY;

        // Points on the inner arc
        const innerStartX = innerRadius * Math.cos(startRadians) + centerX;
        const innerStartY = innerRadius * Math.sin(startRadians) + centerY;
        const innerEndX = innerRadius * Math.cos(endRadians) + centerX;
        const innerEndY = innerRadius * Math.sin(endRadians) + centerY;

        // Points on the innermost arc, for inner segment adjustments
        const innermostStartX = innermostRadius * Math.cos(startRadians) + centerX;
        const innermostStartY = innermostRadius * Math.sin(startRadians) + centerY;
        const innermostEndX = innermostRadius * Math.cos(endRadians) + centerX;
        const innermostEndY = innermostRadius * Math.sin(endRadians) + centerY;

        let pathD;
        if (segment >= 12) { // Adjusting only the inner segment drawing
            // Path for inner segments now draws to the innermostRadius instead of the center
            pathD = `M${innermostStartX},${innermostStartY} ` +
                `L${innerStartX},${innerStartY} ` +
                `A${innerRadius},${innerRadius} 0 0,1 ${innerEndX},${innerEndY} ` +
                `L${innermostEndX},${innermostEndY} ` +
                `A${innermostRadius},${innermostRadius} 0 0,0 ${innermostStartX},${innermostStartY} ` +
                `Z`;
        } else { // Outer segment drawing remains as originally intended
            pathD = `M${outerStartX},${outerStartY} ` +
                `A${outerRadius},${outerRadius} 0 0,1 ${outerEndX},${outerEndY} ` +
                `L${innerEndX},${innerEndY} ` +
                `A${innerRadius},${innerRadius} 0 0,0 ${innerStartX},${innerStartY} ` +
                `L${outerStartX},${outerStartY} ` + // Closing the path back to the outer start
                `Z`;
        }


        let overlayPathD = '';
        if (drawOverlay) {
            const now = new Date();
            const minutes = now.getMinutes();
            const fillPercentage = minutes / 60; // Convert current minutes to a percentage of the hour

            // Determine end angle for the overlay based on fill percentage
            const overlayAngleEnd = segmentAngleStart + (30 * fillPercentage);
            const overlayEndRadians = (Math.PI / 180) * overlayAngleEnd;

            if (segment >= 12) {
                // Inner segment overlay
                const overlayInnermostEndX = innermostRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayInnermostEndY = innermostRadius * Math.sin(overlayEndRadians) + centerY;
                const overlayInnerEndX = innerRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayInnerEndY = innerRadius * Math.sin(overlayEndRadians) + centerY;

                overlayPathD = `M${innermostStartX},${innermostStartY} ` +
                    `L${innerStartX},${innerStartY} ` +
                    `A${innerRadius},${innerRadius} 0 0,1 ${overlayInnerEndX},${overlayInnerEndY} ` +
                    `L${overlayInnermostEndX},${overlayInnermostEndY} ` +
                    `A${innermostRadius},${innermostRadius} 0 0,0 ${innermostStartX},${innermostStartY} ` +
                    `Z`;
            } else {
                // Outer segment overlay
                const overlayOuterEndX = outerRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayOuterEndY = outerRadius * Math.sin(overlayEndRadians) + centerY;
                const overlayInnerEndX = innerRadius * Math.cos(overlayEndRadians) + centerX;
                const overlayInnerEndY = innerRadius * Math.sin(overlayEndRadians) + centerY;

                overlayPathD = `M${outerStartX},${outerStartY} ` +
                    `A${outerRadius},${outerRadius} 0 0,1 ${overlayOuterEndX},${overlayOuterEndY} ` +
                    `L${overlayInnerEndX},${overlayInnerEndY} ` +
                    `A${innerRadius},${innerRadius} 0 0,0 ${innerStartX},${innerStartY} ` +
                    `L${outerStartX},${outerStartY} ` +
                    `Z`;
            }
        }

        const dashLength = pathLength / 4;
        const dashArray = `${dashLength} ${dashLength}`;

        if (!visible) {
            return null
        }

        let fillColor = 'white'
        if(isDarkMode){
            fillColor = '#1e1e1e'
        }

        return (

            <>
                {/* Original Path */}
                <path
                    d={pathD}
                    fill="none"
                    stroke={background}
                    strokeWidth="0.35vw" // Original stroke width
                />
                <path
                    d={pathD}
                    fill="none"
                    stroke={color}
                    strokeWidth="0.1vw" // Original stroke width
                />
                {/* Overlay path element */}
                {drawOverlay && (
                    <path
                        d={overlayPathD}
                        fill={fillColor}
                        fillOpacity="0.25" // Adjust opacity as needed
                    />
                )}
                {/*}  <path d={pathD}
                      fill="none"
                      stroke={'white'}
                      strokeWidth="0.1vw"
                      ref={pathRef}
                      strokeDasharray={dashArray}
                      strokeDashoffset={dashLength} // Initial offset
                >
                    {pathLength > 0 && (
                        <animate
                            attributeName="stroke-dashoffset"
                            from={dashLength}
                            to={`-${dashLength}`} // Move the dash along the path
                            dur="4s"
                            repeatCount="indefinite"
                        />
                    )}
                </path> */}
            </>
        );
    };

    const generateGradientString = (colors) => {
        const segmentSize = 360 / colors.length; // Assuming 24 colors for a full circle
        return colors.map((color, index) => `${color} ${index * segmentSize}deg ${(index + 1) * segmentSize}deg`).join(', ');
    };


    const toRoman = (num) => {
        const lookup = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
        };
        let roman = '';
        for (let i in lookup) {
            while (num >= lookup[i]) {
                roman += i;
                num -= lookup[i];
            }
        }
        return roman;
    };

    const drawLabels = ({hour, radius, currentIndex, useRomanNumerals = false, isDarkMode = false}) => {
        if (currentIndex < hour) return null;

        let color = '#1e1e1e'
        if(isDarkMode) {
            color = 'white'
        }
        return (
            <CalendarLabel key={hour} style={{
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                top: '50%',
                left: '50%',
                zIndex: 7,
                color:color
            }}>

                {hasLabels && [...Array(23).keys()].map(hour => (
                    <CalendarLabel key={hour} style={{
                        ...calculateLabelPosition(hour + 1, radius, hour >= 12),
                        fontSize: '1.5vw',
                        color:color// Simplified, adjust as necessary
                    }}>
                        <CalendarNumbers>
                            {useRomanNumerals ? toRoman(hour + 1) : hour + 1}
                        </CalendarNumbers>
                    </CalendarLabel>
                ))}
            </CalendarLabel>
        );
    };


    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const tick = () => {
            setCurrentTime(new Date());
        };

        // Update the time every second (1000 milliseconds)
        const intervalId = setInterval(tick, 1000);

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);


    const isToday = (date) => {
        const today = new Date();
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    };

    const formatDate = (date) => {
        const dayOfWeek = date.toLocaleDateString('en-GB', {weekday: 'long'});
        const month = date.toLocaleDateString('en-GB', {month: 'long'});
        const dayOfMonth = date.getDate();

        let ordinalIndicator;
        switch (dayOfMonth) {
            case 1:
            case 21:
            case 31:
                ordinalIndicator = 'st';
                break;
            case 2:
            case 22:
                ordinalIndicator = 'nd';
                break;
            case 3:
            case 23:
                ordinalIndicator = 'rd';
                break;
            default:
                ordinalIndicator = 'th';
        }

        const formattedDate = `${dayOfWeek}, ${month} ${dayOfMonth}${ordinalIndicator}`;

        return formattedDate;
    };

    const formatTime = (date) => {
        const options = {hour: '2-digit', minute: '2-digit'};
        return date.toLocaleTimeString('en-GB', options);
    };

    const SegmentTextPath = ({
                                 segmentIndex,
                                 text,
                                 centerX,
                                 centerY,
                                 inner,
                                 outer,
                                 fontsize,
                                 color = 'red',
                                 isOuter = true,
                                 noRotation = false,
                                 offset = 0
                             }) => {
        const totalSegments = 12;
        const degreesPerSegment = 360 / totalSegments;
        const outerRadius = isOuter ? outer : inner; // Adjust these radii as needed
        const pathRadius = centerX * outerRadius; // Assuming centerX is half of the clock's width

        const segmentAngleStart = ((segmentIndex % 12) * degreesPerSegment) - 90 - offset;
        const startAngleRadians = (Math.PI / 180) * segmentAngleStart;
        const endAngleRadians = (Math.PI / 180) * (segmentAngleStart + degreesPerSegment);

        const startX = centerX + pathRadius * Math.cos(startAngleRadians);
        const startY = centerY + pathRadius * Math.sin(startAngleRadians);
        const endX = centerX + pathRadius * Math.cos(endAngleRadians);
        const endY = centerY + pathRadius * Math.sin(endAngleRadians);

        const largeArcFlag = segmentIndex % 12 > 12 ? 1 : 0; // Use large arc flag for segments spanning more than half the circle

        const pathId = `path-${segmentIndex + text + startX + endX}`;
        const pathD = `M ${startX} ${startY} A ${pathRadius} ${pathRadius} 0 ${largeArcFlag} 1 ${endX} ${endY}`;

        const midAngleRadians = startAngleRadians + (endAngleRadians - startAngleRadians) / 2;
        const emojiX = centerX + (pathRadius * Math.cos(midAngleRadians)) - fontsize / 2;
        const emojiY = centerY + (pathRadius * Math.sin(midAngleRadians)) + fontsize / 2;

        return !noRotation ? (
                <g>
                    <path id={pathId} d={pathD} fill="none" stroke="transparent"/>
                    <text fontSize={fontsize} fill={color} style={{userSelect: "none"}}>
                        <textPath href={`#${pathId}`} startOffset="50%" style={{textAnchor: 'middle'}}>
                            {text}
                        </textPath>
                    </text>
                </g>
            ) :
            <text fontSize={fontsize} x={emojiX} y={emojiY} fill={color} style={{userSelect: "none"}}
                  transform={`rotate(${0},${emojiX},${emojiY})`}>
                {text}
            </text>
            ;
    };

    const generateGradientStringFromCombinedData = (data) => {
        const colors = Object.values(data).map(entry => entry.background);
        return generateGradientString(colors);
    };


    let fillcolor = 'white'
    if(isDarkMode){
        fillcolor = '#1e1e1e'
    }

    return (
        <HourCircleContainer
            ref={containerRef}
            onClick={handleCircleClick}
            onDoubleClick={onDoubleClick}
            style={{
                margin: 'auto',
                position: 'relative',
            }}
        >
            <Circle className="large"
                    style={{background: `conic-gradient(${generateGradientStringFromCombinedData(data.slice(0, 12))})`}}></Circle>
            <Circle style={{width: '70%', height: '70%'}}/>
            <Circle className="small"
                    style={{background: `conic-gradient(${generateGradientStringFromCombinedData(data.slice(12, 24))})`}}></Circle>
            <Circle style={{width: '40%', height: '40%', background :fillcolor}}/>
            {!isDisplayOption('nohands') && (
                <Circle style={{width: '5%', height: '5%', background :fillcolor}}/>)}

            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 6}}
                 pointerEvents="none">
                {DrawSelectedSegment(segmentMap[new Date().getHours()], 'black', 'white', true, isToday(currentDate))}
                {DrawSelectedSegment(selectedSegment, 'orange', 'white', false)}
            </svg>

            {hasLabels && [...Array(24).keys()].map(hour => (
                drawLabels({hour, radius, currentIndex, useRomanNumerals: isDisplayOption('numerals'), isDarkMode})
            ))}

            {isDisplayOption('labels') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}>
                    {[...Array(24).keys()].map(hour => (
                        <SegmentTextPath
                            key={hour}
                            segmentIndex={hour}
                            text={data[hour]?.label}
                            centerX={centerX}
                            centerY={centerY}
                            inner={0.63}
                            outer={0.9}
                            fontsize={'0.6vw'}
                            color={data[hour]?.foreground}
                            isOuter={hour < 12}/>
                    ))}
                </svg>
            )}

            {isDisplayOption('emojis') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}>
                    {[...Array(24).keys()].map(hour => (
                        <SegmentTextPath
                            key={hour}
                            segmentIndex={hour}
                            text={data[hour]?.emoji}
                            centerX={centerX}
                            centerY={centerY}
                            inner={0.53}
                            outer={0.8}
                            fontsize={'32'}
                            isOuter={hour < 12}
                            noRotation={isDisplayOption('aligned')}
                        />
                    ))}
                </svg>
            )}

            {isDisplayOption('labels') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 10}}>
                    {[...Array(24).keys()].map(hour => (
                        <SegmentTextPath
                            key={hour}
                            segmentIndex={hour}
                            text={data[hour]?.note && data[hour]?.note.trim() !== "" && data[hour]?.note.trim() !== "<p></p>" ? "🗒️" : ""}
                            centerX={centerX}
                            centerY={centerY}
                            inner={0.63}
                            outer={0.93}
                            fontsize={'16'}
                            color={data[hour]?.foreground}
                            isOuter={hour < 12}
                            offset={12}
                            noRotation={isDisplayOption('aligned')}/>
                    ))}
                </svg>
            )}

            <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 5}}
                 pointerEvents="none">
                <ClockDividers centerX={centerX} centerY={centerY} radius={radius} isDarkMode={isDarkMode}/>
                <ClockDividers centerX={centerX} centerY={centerY} radius={radius * 0.71} isDarkMode={isDarkMode}/>
                {!isDisplayOption('nohands') && (
                    <ClockDividers centerX={centerX} centerY={centerY} radius={radius * 0.4} isDarkMode={isDarkMode}/>
                )}
            </svg>

            {!isDisplayOption('nohands') && (
                <svg style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 15}}
                     pointerEvents="none">
                    <ClockHands style={{zIndex: 8}} centerX={centerX} centerY={centerY} radius={radius}
                                smooth={isDisplayOption('smooth')} isDarkMode={isDarkMode}/>
                </svg>
            )}
        </HourCircleContainer>

    );
};

const ClockHands = ({centerX, centerY, radius, smooth = true,isDarkMode  =false}) => {
    const now = new Date();
    const hours = now.getHours() % 12 + now.getMinutes() / 60; // Convert to 12-hour format and add fraction for minute
    const minutes = now.getMinutes(); // + now.getSeconds() / 60; // Add fraction for second
    const seconds = now.getSeconds();

    if (now.getHours() >= 12) {
        radius *= 0.7;
    }

    centerX = centerX || 0;
    centerY = centerY || 0;

    const hourAngle = (hours / 12) * 360;
    const minuteAngle = (minutes / 60) * 360;
    const secondAngle = (seconds / 60) * 360;
    const hourHandPath = `M ${centerX - 4} ${centerY} L ${centerX + 4} ${centerY} L ${centerX + 2} ${centerY - radius * 0.65} L ${centerX - 2} ${centerY - radius * 0.65} Z`;
    const minuteHandPath = `M ${centerX - 3} ${centerY} L ${centerX + 3} ${centerY} L ${centerX + 1.5} ${centerY - radius * 0.95} L ${centerX - 1.5} ${centerY - radius * 0.95} Z`;
    const secondHandPath = `M ${centerX - 1} ${centerY + 10} L ${centerX + 1} ${centerY + 10} L ${centerX} ${centerY - radius * 0.95} Z`;

    let color = 'black'
    if(isDarkMode){
        color = 'white'
    }

    return (
        <svg style={{
            transformOrigin: 'center center',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 5
        }}
             pointerEvents="none">

            {/* Hour Hand */}
            <path d={hourHandPath} fill={color} transform={`rotate(${hourAngle} ${centerX} ${centerY})`}/>

            {/* Minute Hand */}
            <path d={minuteHandPath} fill={color} transform={`rotate(${minuteAngle} ${centerX} ${centerY})`}/>

            {/* Second Hand */}

            {smooth && (<ClockHandSmooth centerX={centerX} centerY={centerY} radius={radius} now={now}/>)}

            {!smooth && (<path d={secondHandPath} fill="red" filter="url(#handShadow)"
                               transform={`rotate(${secondAngle} ${centerX} ${centerY})`}/>)}

        </svg>
    );
};


const ClockDividers = ({centerX, centerY, radius, isDarkMode = false}) => {
    const dividers = [];

    let color = 'black'
    if(isDarkMode){
        color = 'white'
    }

    // Total dividers: 60 (for each minute)
    for (let i = 0; i < 60; i++) {
        const angle = (i / 60) * 2 * Math.PI; // Convert divider index to radians
        const isHourMarker = i % 5 === 0; // Larger dividers for hours

        const outerRadius = radius * 0.98; // Outer point of divider on clock edge
        const innerRadius = isHourMarker ? radius * 0.91 : radius * 0.95; // Inner point set back a bit more for hours

        const x1 = centerX + outerRadius * Math.cos(angle);
        const y1 = centerY + outerRadius * Math.sin(angle);
        const x2 = centerX + innerRadius * Math.cos(angle);
        const y2 = centerY + innerRadius * Math.sin(angle);

        dividers.push(
            <line
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke={isHourMarker ? color : color} // Different color or thickness for hour markers
                strokeWidth={isHourMarker ? "2" : "1"}
                key={i}
            />
        );
    }

    return <>{dividers}</>;
};

const ClockHandSmooth = ({centerX, centerY, radius}) => {
    const requestRef = useRef();
    const [secondAngle, setSecondAngle] = useState(0);

    const updateSecondHand = () => {
        const now = new Date()
        const seconds = now.getSeconds() + now.getMilliseconds() / 1000;
        const newAngle = (seconds / 60) * 360;
        setSecondAngle(newAngle);
        requestRef.current = requestAnimationFrame(updateSecondHand);
    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(updateSecondHand);
        return () => cancelAnimationFrame(requestRef.current);
    }, );

    const secondHandPath = `M ${centerX} ${centerY + 10} L ${centerX + 0.5} ${centerY} L ${centerX} ${centerY - radius * 0.9} L ${centerX - 0.5} ${centerY} Z`;

    return (
        <path d={secondHandPath} fill="red" transform={`rotate(${secondAngle} ${centerX} ${centerY})`}/>
    );
};


export default HourCircle;
