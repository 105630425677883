import React from 'react';
import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
import { GraphContainerStyle } from "../Styles/reporting";

export const BarChart = ({ data }) => {
    const muiTheme = useTheme();

    // Dynamically set Nivo chart theme based on Material-UI theme
    const nivoTheme = {
        axis: {
            ticks: {
                text: {
                    fill: muiTheme.palette.text.primary, // Dynamically set tick text color
                }
            },
            legend: {
                text: {
                    fill: muiTheme.palette.text.primary, // There's no direct legend property for axis in Nivo, but kept for example completeness
                }
            },
        },
        legends: {
            text: {
                fill: muiTheme.palette.text.primary, // Dynamically set legends text color
            }
        },
        tooltip: {
            container: {
                background: muiTheme.palette.background.paper,
                color: muiTheme.palette.text.primary,
                border: `1px solid ${muiTheme.palette.divider}`,
            },
        },
    };

    return (
        <div style={GraphContainerStyle}>
            <ResponsiveBar
                data={data}
                margin={{ top: 40, right: 40, bottom: 80, left: 60 }}
                padding={0.3}
                colors={({ data }) => data.color}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                theme={nivoTheme}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 36,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={({ id, value, color }) => (
                    <div
                        style={{
                            padding: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            background: muiTheme.palette.background.paper,
                            border: `1px solid ${muiTheme.palette.divider}`,
                            borderRadius: '2px',
                        }}
                    >
                        <span style={{ backgroundColor: color, width: '20px', height: '20px' }} />
                        <strong>{id}:</strong> {value}
                    </div>
                )}
            />
        </div>
    );
};
