import styled from '@emotion/styled'
import {TableCell} from "@mui/material";


export const CustomTableCell = styled(TableCell, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'showTriangle' && prop !== 'triangleColor' && prop !== 'backgroundColor',
})(({ theme, isSelected, showTriangle, triangleColor, backgroundColor,color }) => ({
    position: 'relative',
    backgroundColor,
    color,
    // Overlay when selected
    '&::after': isSelected ? {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 255, 0, 0.2)', // Darker overlay for selection
        pointerEvents: 'none',
        zIndex: 1, // Ensure this overlays the background but is under the triangle
    } : {},
    // Triangle indicator
    '&::before': showTriangle ? {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        borderTop: `10px solid ${triangleColor || 'rgba(255, 255, 0, 0.8)'}`, // Fallback color if triangleColor is not provided
        borderLeft: '10px solid transparent',
        width: 0,
        height: 0,
        display: 'block',
        zIndex: 2, // Ensures triangle is above the overlay
    } : {}
}));
