import React, {useCallback, useEffect, useRef, useState} from "react";
import {DatePickerComponent} from "./DatePicker";
import MonthlyGrid, {getDataForDayHour, getFormattedDate} from "./MonthlyGrid";
import {createDefaultCode, useCodes} from "../Codes/data";
import UserListBox from "../ListBoxes/userListBox";
import {setHourCode, useFetchAndSubscribeMonthsData} from "./dataServce";
import {
    AppBar,
    Box,
    Button,
    ClickAwayListener, Dialog, DialogContent, DialogTitle, Divider, FormControl,
    IconButton, InputLabel, MenuItem,
    MenuList,
    Paper, Select, Slider, TextField,
    Toolbar, Tooltip,
    Typography,
    useTheme
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WorkingHoursSelector from "../WorkingHours/workinghours";
import {CircularProgress} from '@mui/material';
import {CreateDateRange, deleteMonthForTherapist} from "../Users/data";
import {useOnlineUsers, UsersWithSameActivity} from "../Presence/presence";
import CloseButton from "../Components/closeButton";
import GridActionsComponent from "../Components/gridAction";
import {TimeCircle} from "../SlotConfig/slotconfig";
import {NoteEditor} from "../Notes/noteeditor";
import {createTitleFromHour} from "../Notes/notes";
import {updateUserActivity, updateUserPosition, updateUserSelected} from "../App/App";

const CustomContextMenu = ({mouseX, mouseY, onClose, onApply, onClear, onCustomise}) => {
    const menuRef = useRef(null); // Create the ref here

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                onClose(); // Close the menu
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [onClose]); // Notice how menuRef is not a dependency

    if (mouseX === null || mouseY === null) return null;

    return (
        <Paper ref={menuRef} sx={{position: 'absolute', top: mouseY, left: mouseX, zIndex: 1000}}>
            <MenuList>
                <MenuItem onClick={(e) => {
                    onApply(e);
                    onClose();
                }}>Apply {}</MenuItem>
                <MenuItem onClick={(e) => {
                    onClear(e);
                    onClose();
                }}>Clear</MenuItem>
            </MenuList>
        </Paper>
    );
};


export function getHeatMapColor(changesCount) {
    switch (changesCount) {
        case 0:
            return '#e1f5fe'; // Lightest blue
        case 1:
            return '#b3e5fc';
        case 2:
            return '#81d4fa';
        case 3:
            return '#4fc3f7';
        case 4:
            return '#29b6f6';
        case 5:
            return '#ffccbc'; // Light orange, transition starts
        case 6:
            return '#ffab91';
        case 7:
            return '#ff8a65';
        case 8:
            return '#ff7043';
        case 9:
            return '#ff5722'; // Bright red
        default:
            return '#d84315'; // Darker red for more than 10 changes
    }
}


export const Monthly = ({onCloseWindow}) => {
    const {me} = useOnlineUsers();
    const sessionUser = me();
    const [hasData, setHasData] = useState(false);
    const {codes} = useCodes();
    const [selectedCode, setSelectedCode] = useState(null);
    const [selection, setSelection] = useState({start: null, end: null});
    const [displayMode, setDisplayMode] = useState('code'); // 'code' or 'emoji'

    const [zoom, setZoom] = useState(1);

    const [selectedActiveTherapist, setSelectedActiveTherapist] = useState('');
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substr(0, 10));
    const {data: gridData, loading, error} = useFetchAndSubscribeMonthsData(selectedActiveTherapist, year, month);

    const [contextMenu, setContextMenu] = useState({mouseX: null, mouseY: null});

    const [isTimeCircleModalOpen, setIsTimeCircleModalOpen] = useState(false);

    const [isHeatMapView, setIsHeatMapView] = useState(false);

    const [userData, setUserData] = useState(null);

    const [updateData, setUpdateData] = useState([]);

    const [overlay, setOverlay] = useState({
        visible: false,
        x: 0,
        y: 0,
        data: null,
        title: '',
    });

    const [isCollapsed, setIsCollapsed] = React.useState(false);

    const toggleHeatMapView = () => {
        setIsHeatMapView(!isHeatMapView);
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        overlay.visible = false
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
        });
    };

    const handleCloseContextMenu = () => {
        setContextMenu({mouseX: null, mouseY: null});
    };

    const toggleDisplayMode = () => {
        setDisplayMode(prevMode => prevMode === 'code' ? 'emoji' : 'code');
    };

    useEffect(() => {
        if (gridData && gridData.length > 0) {
            setHasData(true);
        } else {
            setHasData(false);
        }

        if (selectedDate && selection?.start?.day !== undefined && selection?.start?.hour !== undefined) {
            const data = getDataForDayHour(year, month, selection.start.day, selection.start.hour, gridData);
            setDayData(data);
        } else {
            setDayData(null);
        }
        console.log("Grid Data has changed.")

    }, [gridData]);

    const handleDateChange = (year, month, day) => {
        const date = new Date(Date.UTC(year, month, day));
        const formattedDate = date.toISOString()
        console.log("handleDateChange ", formattedDate)
        setSelectedDate(formattedDate);
    };

    useEffect(() => {
        if (selectedActiveTherapist && selectedDate) {
            const year = selectedDate.substring(0, 4);
            const month = selectedDate.substring(5, 7);
            setYear(year)
            setMonth(month)
        }
    }, [selectedActiveTherapist, selectedDate]);

    const applyCodeToSelection = async (e) => {
        e.preventDefault();
        if (hasData && selection.start) {
            const updatedData = await updateDataInRange(selection, selectedCode, gridData)
            updateGridData(updatedData);
        }
    };

    const clearSelection = async (e) => {
        e.preventDefault();
        if (hasData && selection.start) {
            const updatedData = await updateDataInRange(selection, '', gridData)
            updateGridData(updatedData);
        }
    };

    const customiseSelection = (e) => {
        e.preventDefault();
        setIsTimeCircleModalOpen(true); // Open the modal
    };


    const handleActiveTherapistSelection = (therapist) => {
        setSelectedActiveTherapist(therapist);
    }

    const [dayData, setDayData] = useState(null);


    useEffect(() => {
        console.log("Day Data has changed")
    }, [dayData]);



    const handleSelectionChange = (newSelection) => {
        // Ensure that start is always the earliest day and hour, and end is the latest
        if (newSelection) {
            const startDay = Math.min(newSelection.start.day, newSelection.end.day);
            const endDay = Math.max(newSelection.start.day, newSelection.end.day);
            const startHour = Math.min(newSelection.start.hour, newSelection.end.hour);
            const endHour = Math.max(newSelection.start.hour, newSelection.end.hour);

            // Create a normalized selection object
            const normalizedSelection = {
                start: { day: startDay, hour: startHour },
                end: { day: endDay, hour: endHour }
            };

            // Update the selection with normalized coordinates
            setSelection(normalizedSelection);

            if (selectedDate) {
                const data = getDataForDayHour(year, month, normalizedSelection.start.day, normalizedSelection.start.hour, gridData);
                setDayData(data);
            } else {
                setDayData(null);
            }

            // Use the normalized selection for updating user position as well
            updateUserPosition(normalizedSelection);
        } else {
            // Handle the case where there is no selection
            setSelection(null);
            setDayData(null);
        }
    };


    const updateGridData = useCallback((updatedData) => {
        setUpdateData(updatedData);
    }, []);


    async function updateDataInRange(selection, code, dataArray) {
        const startRow = selection.start.hour
        const endRow = selection.end.hour
        const startColumn = selection.start.day
        const endColumn = selection.end.day

        const year = selectedDate.substring(0, 4);
        const month = selectedDate.substring(5, 7);

        // Loop through the selected range of days (columns)
        for (let col = startColumn; col <= endColumn; col++) {
            // Adjust column index to match data array indices if needed
            const adjustedColIndex = col - 1;
            // Ensure the column index is within bounds
            if (adjustedColIndex >= 0 && adjustedColIndex < dataArray.length) {
                const dayData = dataArray[adjustedColIndex];
                // Loop through the selected range of hours (rows) for this day
                for (let row = startRow; row <= endRow; row++) {
                    // Ensure the row index is within bounds for the hours array
                    if (row >= 0 && row < 24) {
                        if (!dayData.hours[row]) {
                            dayData.hours[row] = {status: null};
                        }
                        const day = col.toString()
                        const hour = row.toString()

                        if (code?.id !== undefined) {
                            await setHourCode(sessionUser, selectedActiveTherapist, year, month, day, hour, [{
                                idx: 0,
                                code: code,
                                minutes: 60
                            }])
                                .then((operationSuccessful) => {
                                    if (operationSuccessful) {
                                        // Ensure you're setting the status correctly according to your data structure
                                        // This example assumes status is an array; adjust accordingly
                                        dayData.hours[hour].status = [{codeId: code.id}];
                                    }
                                });
                        } else {
                            // Check for a non-null status before attempting to delete or reset
                            const hasObjects = Object.keys(dayData?.hours?.[hour]?.status || {}).length;
                            if (hasObjects) {
                                await setHourCode(sessionUser, selectedActiveTherapist, year, month, day, hour, [{
                                    idx: 0,
                                    code: code,
                                    minutes: 60
                                }])
                                    .then((operationSuccessful) => {
                                        if (operationSuccessful) {
                                            // Assuming you want to clear or reset the status for the hour
                                            delete dayData.hours[hour].status;
                                        }
                                    });
                            }
                        }

                    }
                }
            }
        }
        return dataArray;
    }


    const handleCreate = () => {
        CreateDateRange(sessionUser, selectedActiveTherapist, codes, parseInt(month, 10), parseInt(year, 10), parseInt(month, 10), parseInt(year, 10), (progress) => {
        });
    }

    const handleReset = () => {
        deleteMonthForTherapist(selectedActiveTherapist.id, parseInt(year, 10), parseInt(month, 10))
    }

    const monthName = new Date(0, month - 1).toLocaleString('en-GB', {month: 'long'})
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';

    // Disable default RMB Menu
    useEffect(() => {
        const handleContextmenu = e => {
            e.preventDefault()
        }
        document.addEventListener('contextmenu', handleContextmenu)
        return function cleanup() {
            document.removeEventListener('contextmenu', handleContextmenu)
        }
    }, [])


    const handleCloseTimeCircleModal = () => {
        setIsTimeCircleModalOpen(false);
        updateUserActivity('therapists')
    };

    const [saveFunc, setSaveFunc] = useState(null);

    const handleSetSaveFunc = useCallback((fn) => {
        console.log("Recieved onSave from child", fn)
        setSaveFunc(() => fn);
    }, []);


    const handleSaveThenClose = async () => {
        console.log("About to execute saveFunc");
        if (saveFunc) {
            saveFunc();
        }
        handleCloseTimeCircleModal()
    };

    const handleDoubleClick = () => {
        setIsTimeCircleModalOpen(true);
    }

    const [popupVisible, setPopupVisible] = useState(false);
    const [popupPosition, setPopupPosition] = useState({x: 0, y: 0});
    const [noteInfo, setNoteInfo] = useState(null);
    const [notesTitle, setNotesTitle] = useState("");

    const handleNotes = (title) => {
        setPopupVisible(true)
        setNotesTitle(title)
    }

    const handleToggleSidebar = () => {
        setIsCollapsed(prev => !prev);
    };


    return (

        <Paper sx={{
            overflow: 'auto',
            width: '100%',
            maxWidth: '90vw',
            padding: 2,
            display: 'flex',
            flexDirection: 'column'
        }}>

            <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, flex: 1}}>
                <AppBar position="static">
                    <Toolbar variant='dense' padding={1}>
                        <Tooltip
                            title="Explore the Monthly Overview to see therapists' bookings at a glance. Each cell represents an hour of the day, organised by dates, providing a comprehensive view of availability and appointments throughout the month. Perfect for planning and managing your sessions efficiently."
                            arrow>
                            <IconButton>
                                <CalendarMonthIcon/>
                            </IconButton>
                        </Tooltip>
                        <Grid container alignItems="center" padding={1} spacing={2}>
                            <Grid item sx={{flexGrow: 1}}>
                                <Typography variant="h6">
                                    Monthly Overview
                                </Typography>
                            </Grid>
                            <Grid item>
                                <UsersWithSameActivity currentActivity={'therapists'}
                                                       email={selectedActiveTherapist.email}
                                                       setUserData={setUserData}/>
                            </Grid>

                            <Grid item>
                                <UserListBox
                                    isActive={true}
                                    onSelectUser={handleActiveTherapistSelection}
                                    selectedUser={selectedActiveTherapist}
                                    size={0}
                                />
                            </Grid>
                            <Grid item>
                                <GridActionsComponent
                                    selectedCode={selectedCode}
                                    setSelectedCode={setSelectedCode}
                                    onApply={applyCodeToSelection}
                                    onClear={clearSelection}
                                    displayMode={displayMode}
                                    toggleDisplayMode={toggleDisplayMode}
                                />
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    title="Show the most active areas of the grid, hover over the indivudal grid cells to see the history of a cell."
                                    arrow>
                                    <Button variant="contained" size="small" onClick={toggleHeatMapView}>
                                        {isHeatMapView ? 'Heat Map Off' : 'Heat Map On'}
                                    </Button>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <CloseButton onCloseWindow={onCloseWindow}/>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                {loading && (
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1}}>
                        <CircularProgress/>
                    </Box>
                )}

                {!loading &&
                    <Box sx={{
                        flex: 1,
                        flexGrow: 1,
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}>
                        {gridData && gridData.length > 0 ? (

                            <MonthlyGrid
                                onContextMenu={handleContextMenu}
                                selectedTherapist={selectedActiveTherapist}
                                selectedDate={selectedDate}
                                onSelectionChange={handleSelectionChange}
                                datesWithHours={gridData}
                                codes={codes}
                                displayMode={displayMode}
                                isDarkMode={isDarkMode}
                                isHeatMapView={isHeatMapView}
                                setOverlay={setOverlay}
                                overlay={overlay}
                                zoom={zoom}
                                updateData={updateData}
                                onDoubleClick={handleDoubleClick}
                                activeUsers={userData}
                                historyVisible={isCollapsed}
                            />


                        ) : (
                            <Typography variant="h5" color="text.secondary" align="center">
                                No data initialised for {monthName} {year}, adjust the working hours where necessary,
                                and press the button!
                                <WorkingHoursSelector selectedUser={selectedActiveTherapist}/>
                                <Button sx={{maxWidth: '300px'}} variant='contained' color='primary'
                                        onClick={handleCreate}>Initialise {monthName} {year}</Button>
                            </Typography>
                        )}
                    </Box>
                }
                <AppBar position="static" color="primary" sx={{top: 'auto', bottom: 0}}>
                    <Toolbar variant="dense">
                        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
                            <Grid item xs={4}>
                                <Paper elevation={3} sx={{display: 'flex', justifyContent: 'left', p: 0, width: '50%'}}>
                                    <Slider
                                        size="small"
                                        value={zoom}
                                        onChange={(event, newValue) => setZoom(newValue)}
                                        aria-label="Small steps"
                                        defaultValue={1}
                                        valueLabelDisplay="auto"
                                        step={0.25}
                                        marks
                                        min={1}
                                        max={10}
                                        sx={{width: '100%', mx: 2}}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                                <DatePickerComponent onDateChange={handleDateChange}/>
                            </Grid>
                            <Grid item xs={4} sx={{display: 'flex', justifyContent: 'right', p: 0}}>
                                <Button variant="contained" onClick={handleToggleSidebar}>History</Button>
                                <Tooltip arrow
                                         title="Resets the currents months data for the selected therapist - Development only. You can press it though, it'll all be fine, nothing is LIVE yet.">
                                    <Button variant="contained" onClick={handleReset}>Reset</Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </Box>
            {contextMenu.mouseX !== null && (
                <CustomContextMenu
                    mouseX={contextMenu.mouseX}
                    mouseY={contextMenu.mouseY}
                    onClose={handleCloseContextMenu}
                    onApply={applyCodeToSelection}
                    onClear={clearSelection}
                    onCustomise={customiseSelection}
                    selectCode={selectedCode}
                />
            )}

            <CustomiseHourSlotModal
                isTimeCircleModalOpen={isTimeCircleModalOpen}
                handleSaveThenClose={handleSaveThenClose}
                sessionUser={sessionUser}
                selectedActiveTherapist={selectedActiveTherapist}
                year={year}
                month={month}
                day={selection?.start?.day.toString()}
                hour={selection?.start?.hour.toString()}
                dayData={dayData}
                codes={codes}
                handleSetSaveFunc={handleSetSaveFunc}
                handleNotes={handleNotes}
            />

            <NoteEditor
                title={notesTitle}
                visible={popupVisible}
                position={popupPosition}
                onClose={() => setPopupVisible(false)}
                noteInfo={noteInfo}
            />

        </Paper>
    );
};



function CustomiseHourSlotModal({   isTimeCircleModalOpen,
                                    handleSaveThenClose,
                                    sessionUser,
                                    selectedActiveTherapist,
                                    year,
                                    month,
                                    day,
                                    hour,
                                    dayData,
                                    codes,
                                    handleSetSaveFunc,
                                    handleNotes,
                                }) {

    const [title, setTitle] = useState("");
    const [presenceLabel, setPresenceLabel] = useState("");

    const handleSegmentSelect = (segment) => {
        const dateString = getFormattedDate(year, month, day, hour, segment.start,segment.end);
        setTitle(dateString);
        setPresenceLabel( getFormattedDate(year, month, day, hour))
    }

    const openNotes = () => {
        console.log("openNotes")
        handleNotes(title)
    }

    useEffect(() => {
        if(isTimeCircleModalOpen){
            updateUserActivity(presenceLabel).then(r => console.log("Activity set to ",title))
        }
    }, [presenceLabel,isTimeCircleModalOpen])

    return (
        <Dialog open={isTimeCircleModalOpen} fullWidth maxWidth="md">
            <DialogTitle>
                <AppBar position="static">
                    <Toolbar>
                        <Grid container alignItems="center" padding={1} spacing={2}>
                            <Grid item sx={{ flexGrow: 1 }}>
                                <Typography variant="h6">{title}</Typography>
                            </Grid>
                            <Grid item>
                                <UsersWithSameActivity currentActivity={presenceLabel}
                                                       email={selectedActiveTherapist.email}
                                />
                            </Grid>
                            <Grid item>
                                <CloseButton onCloseWindow={handleSaveThenClose} />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TimeCircle
                            sessionUser={sessionUser}
                            selectedActiveTherapist={selectedActiveTherapist}
                            year={year}
                            month={month}
                            day={day}
                            hour={hour}
                            data={dayData}
                            codes={codes}
                            onSaveProp={handleSetSaveFunc}
                            onSegmentSelect={handleSegmentSelect}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            fullWidth
                            label="Email"
                            variant="outlined"
                            margin="normal"
                        />
                        <TextField
                            fullWidth
                            label="Organization"
                            variant="outlined"
                            margin="normal"
                        />
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Call Type</InputLabel>
                            <Select
                                label="Call Type"
                            >
                                <MenuItem value="phone">Phone</MenuItem>
                                <MenuItem value="video">Video</MenuItem>
                                <MenuItem value="chat">Chat</MenuItem>
                            </Select>
                        </FormControl>

                        <Button variant='contained' type="button" onClick={openNotes} >Notes</Button>

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}