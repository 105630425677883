import React, {useState} from 'react';
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';
import UserListBox from "../ListBoxes/userListBox";
import {Box, Button, useTheme} from "@mui/material";
import firebase from "firebase/compat/app";
import {userCollectionPath} from "../Firestore/paths";
import {useFirebase} from "../App/App";
import {InviteModal, InvitesList, sendEmail} from "./invite";
import {httpsCallable} from "firebase/functions";


export default function Users() {
    const [selectedActiveUser, setSelectedActiveUser] = useState('');
    const [selectedInActiveUser, setSelectedInActiveUser] = useState('');
    const [selectedInvite, setSelectedInvite] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();

    const { functions } = useFirebase();
    const sendEmailFunction = httpsCallable(functions, 'sendEmail');
    const deleteAuthUser = httpsCallable(functions, 'deleteAuthUser');


    const onActiveUserSelect = (user) => {
        setSelectedActiveUser(user);
    }

    const onInviteSelect = (user) => {
        setSelectedInvite(user);
    }

    const onInActiveUserSelect = (user) => {
        setSelectedInActiveUser(user);
    }

    const onClickSendInvite = () => {
        sendEmail(sendEmailFunction,selectedInvite.email)
    }

    const deleteDocument = async (collectionPath, documentId) => {
        try {
            await firebase.firestore().collection(collectionPath).doc(documentId).delete();
            console.log(`Document with ID ${documentId} has been deleted from ${collectionPath}.`);
        } catch (error) {
            console.error(`Error deleting document from ${collectionPath}:`, error);
            throw error;
        }
    };

    const deleteInvite = async () => {
        if (!selectedInvite) {
            alert("Please select an invite to delete.");
            return;
        }
        await deleteDocument('invites', selectedInvite.id);
    };


    const deactivateTherapist = async (e) => {
        e.preventDefault();
        if (!selectedActiveUser) {
            alert("Please select a therapist to deactivate.");
            return;
        }
        try {
            await firebase.firestore().collection(userCollectionPath).doc(selectedActiveUser.id).update({
                active: false
            });
            console.log(`Therapist with ID ${selectedActiveUser.id} has been deactivated.`);
            // Optionally, refresh your therapists list here to reflect the change
        } catch (error) {
            console.error("Error deactivating therapist: ", error);
        }
    };


    const activateTherapist = async (e) => {
        e.preventDefault();
        if (!selectedInActiveUser) {
            alert("Please select a therapist to activate.");
            return;
        }
        try {
            await firebase.firestore().collection(userCollectionPath).doc(selectedInActiveUser.id).update({
                active: true
            });

            console.log(`Therapist with ID ${selectedInActiveUser.id} has been activated.`);
            // Optionally, refresh your therapists list here to reflect the change
        } catch (error) {
            console.error("Error activating therapist: ", error);
        }
    };


    const deleteSelectedInActiveUser = async (e) => {
        e.preventDefault();
        if (!selectedInActiveUser) {
            alert("Please select a therapist to remove.");
            return;
        }
        deleteAuthUser({ userId: selectedInActiveUser.id })
            .then((result) => {
                // Read result of the Cloud Function.
                console.log(result.data); // { success: true, message: 'User deleted successfully.' }
            })
            .catch((error) => {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.error("Error calling deleteAuthUser function:", message, details, code);
            });
    };

    return (
        <Grid container spacing={2} sx={{ height: '100%', margin: 0 }}>
            <Grid item xs={4}>
                <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius={2}
                    position="relative"
                    p={2}
                    sx={{ height: '95%' }}
                >
                    <Typography variant="h6" sx={{ position: 'absolute', top: -14, left: '10px', background: `${theme.palette.background.default}`, px: 1 }}>
                        Active
                    </Typography>
                    <UserListBox
                        isActive={true}
                        onSelectUser={onActiveUserSelect}
                        selectedUser={selectedActiveUser}
                        size={10}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained" size="small"  color="error" onClick={deactivateTherapist}>Deactivate</Button>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius={2}
                    position="relative"
                    p={2}
                    sx={{ height: '95%' }}
                >
                    <Typography variant="h6" sx={{ position: 'absolute', top: -14, left: '10px', background: `${theme.palette.background.default}`, px: 1 }}>
                        Invited
                    </Typography>

                    <InvitesList
                        onSelectInvite={onInviteSelect}
                        selectedInvite={selectedInvite}
                    />

                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                    <Button variant="contained" size="small" color="success"  onClick={() => setModalOpen(true)}>Invite User</Button>
                    <InviteModal open={modalOpen} onClose={() => setModalOpen(false)} />
                    <Button variant="contained" size="small"   onClick={onClickSendInvite}>Resend Invite</Button>
                    <Button variant="contained" size="small" color="error" onClick={deleteInvite}>Delete</Button>
                </Box>
            </Grid>
            <Grid item xs={4}>
                <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius={2}
                    position="relative"
                    p={2}
                    sx={{ height: '95%' }}
                >
                    <Typography variant="h6" sx={{ position: 'absolute', top: -14, left: '10px', background: `${theme.palette.background.default}`, px: 1 }}>
                        Deactivated
                    </Typography>
                    <UserListBox
                        isActive={false}
                        onSelectUser={onInActiveUserSelect}
                        selectedUser={selectedInActiveUser}
                        size={10}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                    <Button variant="contained" size="small" color="success" onClick={activateTherapist}>Activate</Button>
                    <Button variant="contained" size="small" color="error" onClick={deleteSelectedInActiveUser}>Delete</Button>
                </Box>
            </Grid>
        </Grid>
    );

}
