import React, {useEffect, useState} from 'react';
import {
    Box, Collapse, IconButton, List, ListItem, ListItemText,
    MenuItem,
    TextField,
    Typography,
    useTheme
} from '@mui/material';
import UserListBox from "../ListBoxes/userListBox";
import {getTimeZones} from "@vvo/tzdb";
import firebase from "firebase/compat/app";
import {ssUserSessionId, useFirebase} from "../App/App";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import {httpsCallable} from "firebase/functions";


const UserTypeSelect = ({ userType, onChange }) => {
    return (
        <TextField
            id="userTypeSelect"
            select
            label="User Type"
            value={userType}
            onChange={onChange}
            fullWidth
            variant="outlined"
            sx={{mb: 2}}
        >
            <MenuItem value="viewer">Viewer</MenuItem>
            <MenuItem value="therapist">Therapist</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
        </TextField>
    );
};


const SessionInfo = ({ userId }) => {
    const [sessions, setSessions] = useState({});
    const [openSessionId, setOpenSessionId] = useState(null);

    useEffect(() => {
        if (!userId) return;
        const sessionsRef = firebase.database().ref(`/presence/${userId}/sessions`);
        const onSessionChange = sessionsRef.on('value', (snapshot) => {
            const sessionsData = snapshot.val();
            setSessions(sessionsData || {});
        });
        return () => sessionsRef.off('value', onSessionChange);
    }, [userId]);

    const handleClick = (sessionId) => {
        setOpenSessionId(openSessionId === sessionId ? null : sessionId);
    };

    const handleDeleteSession = async (sessionId) => {
        const sessionRef = firebase.database().ref(`/presence/${userId}/sessions/${sessionId}`);
        await sessionRef.update({ isValid: false });
    };

    const currentSessionId = sessionStorage.getItem(ssUserSessionId);

    return (
        <Box sx={{
            width: '100%',
            mt: 2,
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '4px',
            position: 'relative',
        }}>
            <Typography
                variant="overline"
                component="div"
                sx={{
                    position: 'absolute',
                    top: -12,
                    left: 14,
                    backgroundColor: 'background.paper',
                    px: 1,
                }}
            >
                Active Sessions
            </Typography>
            <Box sx={{
                maxHeight: '150px',
                overflowY: 'auto',
                margin: '10px',
                pt: 2,
            }}>
                <List component="nav" aria-labelledby="nested-list-subheader">
                    {Object.entries(sessions).map(([sessionId, sessionInfo]) => (
                        <React.Fragment key={sessionId}>
                            <ListItem button onClick={() => handleClick(sessionId)}>
                                <ListItemText primary={sessionInfo.os + " - " + sessionInfo.browser} />
                                {/* Only show the DeleteIcon if it's not the current session */}
                                {currentSessionId !== sessionId && (
                                    <IconButton onClick={() => handleDeleteSession(sessionId)} sx={{ color: 'red' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                )}
                                {openSessionId === sessionId ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={openSessionId === sessionId} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem>
                                        <Typography variant="body2" sx={{ pl: 4 }}>
                                            SessionID: {sessionId}
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant="body2" sx={{ pl: 4 }}>
                                            Status: {sessionInfo.status}
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant="body2" sx={{ pl: 4 }}>
                                            Last Active: {new Date(sessionInfo.lastActive).toLocaleString()}
                                        </Typography>
                                    </ListItem>
                                    <ListItem>
                                        <Typography variant="body2" sx={{ pl: 4 }}>
                                            Activity: {sessionInfo?.activity || 'none'}
                                        </Typography>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </Box>
    );
};



export default function Profiles() {
    const [selectedActiveUser, setSelectedActiveUser] = useState('');
    const theme = useTheme();
    const [selectedTimeZone, setSelectedTimeZone] = React.useState('');

    const { functions } = useFirebase();
    const setUserTypeFunction = httpsCallable(functions, 'setUserType');


    const handleTimeZoneChange = async (event) => {
        setSelectedTimeZone(event.target.value);
        if (selectedActiveUser && selectedActiveUser.id) {
            try {
                const userDocRef = firebase.firestore().collection('users').doc(selectedActiveUser.id);
                await userDocRef.update({
                    timeZone: event.target.value
                });
                console.log('Time zone updated successfully');
            } catch (error) {
                console.error('Error updating time zone:', error);
            }
        }
    };

    const onActiveUserSelect = (user) => {
        setSelectedActiveUser(user);
        setUserType(user.userType);
    };

    const handleNameChange = (event) => {
        setSelectedActiveUser(prevState => ({ ...prevState, name: event.target.value }));
    };

    const timeZones = getTimeZones();
    const [userType, setUserType] = useState('viewer');

    const updateUserType = async (userType) => {
        if (selectedActiveUser) {
            setUserTypeFunction({uid: selectedActiveUser.id, userType: userType})
                .then((result) => {
                    // Handle result
                    console.log(result.data);
                    try {

                    } catch (error) {
                        console.error("Error refreshing ID token:", error);
                    }
                })
                .catch((error) => {
                    // Handle error
                    console.error(error);
                });
        }
    };


    const handleUserChange = (event) => {
        setUserType(event.target.value);
        updateUserType(event.target.value)
    };


    // Listen and update any Profile UI accordingly
    useEffect(() => {
        if (selectedActiveUser && selectedActiveUser.id) {
            const userRef = firebase.firestore().collection('users').doc(selectedActiveUser.id);
            const unsubscribe = userRef.onSnapshot(doc => {
                const updatedUserData = doc.data();
                if (updatedUserData) {
                    setSelectedActiveUser(prevState => ({
                        ...prevState,
                        ...updatedUserData, // Merge any updates into the current user object
                    }));

                    setUserType(updatedUserData.userType || 'viewer');
                }
            });

            return () => unsubscribe(); // Clean up the listener on unmount or when selectedActiveUser changes
        }
    }, [selectedActiveUser]);



    return (
        <Box display="flex" p={2} height="80vh"> {/* Set the overall container height */}
            <Box
                border={1}
                borderColor="grey.500"
                borderRadius={2}
                position="relative"
                p={2}
                sx={{
                    width: '30%', // Adjust the width as needed
                    height: '90%', // Keep the height constant relative to the parent
                    flex: 'none' // Prevents flexbox from adjusting its size
                }}
            >
                <Typography variant="h6" sx={{ position: 'absolute', top: -20, left: '10px', background: theme.palette.background.default, px: 1 }}>
                    Active
                </Typography>
                <UserListBox
                    isActive={true}
                    onSelectUser={onActiveUserSelect}
                    selectedUser={selectedActiveUser}
                    size={10}
                    sx={{ overflow: 'auto', height: '100%' }} // Ensures ListBox takes up the full height of its container
                />
            </Box>

            <Box
                border={1}
                borderColor="grey.500"
                borderRadius={2}
                position="relative"
                p={2}
                sx={{
                    width: '70%', // Adjust the width as needed
                    height: '90%', // Keep the height constant relative to the parent
                    flex: 'none' // Prevents flexbox from adjusting its size
                }}
            >
                <Box sx={{ position: 'relative', p: 2 }}> {/* Adjust padding as needed */}
                    <Typography variant="h6" sx={{ position: 'absolute', top: -20, left: '10px', background: theme.palette.background.default, px: 1 }}>
                        Details
                    </Typography>
                    <Box mt={4}>
                        <TextField
                            label="Name"
                            value={selectedActiveUser.name || ''}
                            variant="filled"
                            fullWidth
                            sx={{mb: 2}}
                        />
                        <TextField
                            label="Email"
                            value={selectedActiveUser.email || ''}
                            variant="filled"
                            fullWidth
                            sx={{mb: 2}}
                        />

                        <UserTypeSelect userType={userType} onChange={handleUserChange}/>

                        <TextField
                            select
                            label="Time Zone"
                            value={selectedActiveUser.timeZone || ''}
                            onChange={handleTimeZoneChange}
                            variant="outlined"
                            fullWidth   sx={{mb: 2}}>
                            {timeZones.map((tz) => (
                                <MenuItem key={tz.name} value={tz.name}>
                                    {`${tz.currentTimeFormat}`}
                                </MenuItem>
                            ))}
                        </TextField>

                        <SessionInfo userId={selectedActiveUser.id}></SessionInfo>


                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
