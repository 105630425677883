import React, {useState, useEffect} from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Button, Tooltip} from "@mui/material";

const CustomInput = React.forwardRef(({value, onClick}, ref) => (
    <Button variant="contained" size="small" onClick={onClick} ref={ref} style={{}} readOnly>
        {value}
    </Button>
));

export const DatePickerComponent = ({onDateChange, mode = 'monthYear'}) => {
    const [startDate, setStartDate] = useState(new Date());

    useEffect(() => {
        if (onDateChange !== undefined) {
            const year = startDate.getUTCFullYear();
            const month = startDate.getUTCMonth();
            const day = startDate.getUTCDate();
            onDateChange(year, month, day);
        }
    }, [startDate]);

    const adjustPeriod = (amount) => {
        const dateAdjustment = mode === 'monthYear'
            ? new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth() + amount, 1))
            : new Date(Date.UTC(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate() + amount));
        setStartDate(dateAdjustment);
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%', // Ensuring the div is centered itself
        }}>
            <Tooltip title={mode === 'monthYear'
                ? "Previous Month"
                : "Previous Day"} arrow>
                <Button variant="contained" size="small" onClick={() => adjustPeriod(mode === 'monthYear' ? -1 : -1)}>
                    {mode === 'monthYear' ? '←' : '←'}
                </Button>
            </Tooltip>
            <Tooltip title={mode === 'monthYear'
                ? "Directly select the month to view."
                : "Directly set the day to view."} arrow>
                <div style={{display: 'inline-block'}}>
                    <ReactDatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        dateFormat={mode === 'monthYear' ? "MMMM yyyy" : "EEEE, MMMM d"}
                        showMonthYearPicker={mode === 'monthYear'}
                        customInput={<CustomInput/>}
                        utcOffset={0}
                    />
                </div>
            </Tooltip>
            <Tooltip title={mode === 'monthYear'
                ? "Next Month"
                : "Next Day"} arrow>
                <Button variant="contained" size="small" onClick={() => adjustPeriod(mode === 'monthYear' ? 1 : 1)}>
                    {mode === 'monthYear' ? '→' : '→'}
                </Button>
            </Tooltip>
        </div>
    );
};
