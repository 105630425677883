import React, {useCallback, useEffect, useState} from "react";
import { saveUser } from "../Users/create";
import {
    Paper, Typography, TextField, Button, Box,
    AppBar, Toolbar, IconButton, FormControl, FormLabel,
    RadioGroup, FormControlLabel, Radio, Switch
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Grid from "@mui/material/Grid";
import {debounce} from "lodash";
import {useOnlineUsers} from "../Presence/presence";

export const Profile = ({ onCloseWindow, setUserMode, handleSignOut }) => {
    const { me } = useOnlineUsers();
    const user = me();
    const [dailyQuoteColor, setDailyQuoteColor] = useState(user?.dailyQuoteColor || '#FFFFFF');
    const [mode, setMode] = useState(user?.darkmode || 'default');
    const [dailyQuote, setDailyQuote] = useState(user?.dailyQuote || false);
    const [movieBackground, setMovieBackground] = useState(user?.movieBackground || false);
    const [backgroundColors, setBackgroundColors] = useState(user?.backgroundColors || { top: '#ffffff',  bottom: '#ffffff' });

    // Debounced save function
    const debouncedSaveUser = useCallback(debounce((changes) => {
        updateProfile(changes);
    }, 2000), []);

    useEffect(() => {
        // Cleanup function that runs when the component unmounts
        return () => {
            debouncedSaveUser.flush();
        };
    }, [debouncedSaveUser]);


    useEffect(() => {
        setMode(user?.darkmode || 'default');
        setDailyQuote(user?.dailyQuote || false);
        setMovieBackground(user?.movieBackground || false);
        setDailyQuoteColor(user?.dailyQuoteColor || '#FFFFFF');
        setBackgroundColors(user?.backgroundColors || { top: '#ffffff', bottom: '#ffffff' });
    }, [user]);

    const handleModeChange = (event) => {
        updateProfile({ darkmode: event.target.value });
    };

    const toggleDailyQuote = (event) => {
        updateProfile({ dailyQuote: event.target.checked });
    };

    const toggleMovieBackground = (event) => {
        updateProfile({ movieBackground: event.target.checked });
    };

    const handleBackgroundColorChange = (corner, color) => {
        const newBackgroundColors = { ...backgroundColors, [corner]: color };
        setBackgroundColors(newBackgroundColors);
        debouncedSaveUser({ backgroundColors: newBackgroundColors });
    };

    const updateProfile = (changes) => {
        const updatedUser = { ...user, ...changes };
        saveUser(updatedUser);
    };

    const handleDailyQuoteColorChange = (event) => {
        const newColor = event.target.value;
        setDailyQuoteColor(newColor);
        debouncedSaveUser({ dailyQuoteColor: newColor });
    };


    return (
        <Paper elevation={3} sx={{ padding: 3 }}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Profile
                    </Typography>
                    <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box component="form" noValidate autoComplete="off">
                <TextField id="userName" label="Name" type="text" fullWidth margin="normal" value={user?.name || ''} InputProps={{ readOnly: true }} />
                <TextField id="userEmail" label="Email" type="text" fullWidth margin="normal" value={user?.email || ''} InputProps={{ readOnly: true }} />
                <TextField id="userType" label="User Type" type="text" fullWidth margin="normal" value={user?.userType || 'viewer'} InputProps={{ readOnly: true }} />

                <FormControl component="fieldset" margin="normal">
                    <FormLabel component="legend">Dark Mode</FormLabel>
                    <RadioGroup row aria-label="dark-mode" name="dark-mode" value={mode} onChange={handleModeChange}>
                        <FormControlLabel value="default" control={<Radio />} label="System Default" />
                        <FormControlLabel value="light" control={<Radio />} label="Light" />
                        <FormControlLabel value="dark" control={<Radio />} label="Dark" />
                    </RadioGroup>

                    <FormControlLabel
                        control={<Switch checked={dailyQuote} onChange={toggleDailyQuote} name="dailyQuote" />}
                        label="Daily Quote"
                    />

                    {/* Color picker for Daily Quote Color */}
                    {dailyQuote && ( // This will only show when dailyQuote is enabled
                        <TextField
                            id="dailyQuoteColor"
                            label="Daily Quote Color"
                            type="color"
                            fullWidth
                            margin="normal"
                            value={dailyQuoteColor}
                            onChange={handleDailyQuoteColorChange}
                        />
                    )}

                    <FormControlLabel control={<Switch checked={movieBackground} onChange={toggleMovieBackground} name="movieBackground" />} label="Movie Background" />

                </FormControl>

                {/* Conditional rendering for top and bottom color pickers */}
                {!movieBackground && (
                    <Grid container spacing={2} alignItems="center" justifyContent="center" marginY={2}>
                        {/* Color picker for Top Color */}
                        <Grid item xs={6}>
                            <TextField
                                id="background-top"
                                label="Top Color"
                                type="color"
                                fullWidth
                                value={backgroundColors.top}
                                onChange={(e) => handleBackgroundColorChange('top', e.target.value)}
                                variant="outlined"
                                size="small"
                                margin="dense"
                            />
                        </Grid>
                        {/* Color picker for Bottom Color */}
                        <Grid item xs={6}>
                            <TextField
                                id="background-bottom"
                                label="Bottom Color"
                                type="color"
                                fullWidth
                                value={backgroundColors.bottom}
                                onChange={(e) => handleBackgroundColorChange('bottom', e.target.value)}
                                variant="outlined"
                                size="small"
                                margin="dense"
                            />
                        </Grid>
                    </Grid>
                )}


                <Box mt={2} display="flex" justifyContent="flex-end">
                    <Button variant="contained" onClick={handleSignOut}>Sign Out</Button>
                </Box>
            </Box>
        </Paper>
    );
};
