import React, {useState} from 'react';
import {
    Box,
    Button,
    Typography,
    IconButton,
    Card,
    CardContent,
    Menu,
    MenuItem,
    CardActions,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UserListBox from "../ListBoxes/userListBox";
import Grid from "@mui/material/Grid";

const days = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

const hours = Array.from({length: 24}, (_, i) => `${i < 10 ? `0${i}` : i}:00`);

const initialHours = {
    'Sunday': [],
    'Monday': [{start: '08:00', end: '20:00'}],
    'Tuesday': [{start: '08:00', end: '20:00'}],
    'Wednesday': [{start: '08:00', end: '20:00'}],
    'Thursday': [{start: '08:00', end: '20:00'}],
    'Friday': [{start: '08:00', end: '20:00'}],
    'Saturday': [],
};

export const WorkingHours = ({onCloseWindow}) => {
    const [workingHours, setWorkingHours] = useState(initialHours);
    const [currentSelection, setCurrentSelection] = useState({day: '', index: -1, type: ''});
    const [selectedActiveUser, setSelectedActiveUser] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuPosition, setMenuPosition] = useState({left: 0, top: 0});

    const onActiveUserSelect = (user) => {
        setSelectedActiveUser(user);
    }

    const openMenu = (event, day, index, type) => {
        setMenuPosition({
            left: event.currentTarget.getBoundingClientRect().left,
            top: event.currentTarget.getBoundingClientRect().bottom,
        });
        setCurrentSelection({day, index, type});
        setMenuOpen(true);
    };

    const handleMenuItemClick = (hour) => {
        const updatedHours = {...workingHours};
        if (currentSelection.index !== -1) {
            updatedHours[currentSelection.day][currentSelection.index][currentSelection.type] = hour;
            setWorkingHours(updatedHours);
        }
        setMenuOpen(false);
    };

    const addTimeRange = (day) => {
        const updatedHours = {...workingHours, [day]: [...workingHours[day], {start: '09:00', end: '17:00'}]};
        setWorkingHours(updatedHours);
    };

    const removeTimeRange = (day, index) => {
        const updatedRanges = [...workingHours[day]];
        updatedRanges.splice(index, 1);
        setWorkingHours({...workingHours, [day]: updatedRanges});
    };

    return (



        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius={2}
                    position="relative"
                    p={2}
                    sx={{height: '400px'}}
                >
                    <UserListBox
                        isActive={true}
                        onSelectUser={onActiveUserSelect}
                        selectedUser={selectedActiveUser}
                        size={10}
                    />
                </Box>
            </Grid>

            <Grid item xs={12} md={9} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box
                    sx={{
                        maxHeight: '800px', // Set a maximum height for the container
                        overflow: 'auto', // Enable scrolling for overflow content
                        width: '100%', // Ensure the container takes full width of its parent
                    }}
                >


            {/* Working hours on the right */}
            <Grid item xs={12} md={9} sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 1}}>
                {days.map(day => (
                    <Card key={day} variant="outlined" sx={{
                        minWidth: 200,
                        maxWidth: '100%', // Adjusted for responsive layout
                        flexBasis: 'calc(33% - 8px)', // Adjust based on how many cards per row you want
                        flexGrow: 1,
                        m: 1
                    }}>
                        <CardContent sx={{
                            '&:last-child': {pb: '8px'},
                            pt: '8px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 0.5
                        }}>
                            <Typography variant="subtitle1" gutterBottom>{day}</Typography>
                            {workingHours[day].length === 0 ? (
                                <Typography>No ranges set</Typography>
                            ) : (
                                workingHours[day].map((range, index) => (
                                    <Box key={index} sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
                                        <IconButton onClick={(e) => openMenu(e, day, index, 'start')} size="small">
                                            <AccessTimeIcon fontSize="inherit"/>
                                        </IconButton>
                                        <Typography sx={{
                                            minWidth: '40px',
                                            textAlign: 'center',
                                            fontSize: '0.875rem'
                                        }}>{range.start}</Typography>
                                        <IconButton onClick={(e) => openMenu(e, day, index, 'end')} size="small">
                                            <AccessTimeIcon fontSize="inherit"/>
                                        </IconButton>
                                        <Typography sx={{
                                            minWidth: '40px',
                                            textAlign: 'center',
                                            fontSize: '0.875rem'
                                        }}>{range.end}</Typography>
                                        <IconButton onClick={() => removeTimeRange(day, index)} size="small">
                                            <DeleteIcon fontSize="inherit"/>
                                        </IconButton>
                                    </Box>
                                ))
                            )}
                        </CardContent>
                        <CardActions sx={{justifyContent: 'flex-start', pb: '8px'}}>
                            <Button size="small" startIcon={<AddIcon/>} onClick={() => addTimeRange(day)}>Add
                                Range</Button>
                        </CardActions>
                    </Card>
                ))}
            </Grid>
                </Box>
            </Grid>
            <Menu
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                anchorReference="anchorPosition"
                anchorPosition={menuOpen ? {top: menuPosition.top, left: menuPosition.left} : undefined}
            >
                {hours.map((hour) => (
                    <MenuItem key={hour} onClick={() => handleMenuItemClick(hour)}>
                        {hour}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>

    );
}