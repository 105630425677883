import {DatePickerComponent} from "../Booking/DatePicker";
import {TherapistSelect} from "./therapistselect";
import React, {useEffect, useState} from "react";
import {useFetchAllTherapistsData} from "../Booking/dataServce";
import {useCodes} from "../Codes/data";
import {
    countTherapistDataIDs,
    countTherapistDataPerID,
    transformCountsToChartData,
    transformForStackedBarChart
} from "./helpers";
import {CodeSelect} from "./codeselect";
import {PieChart} from "./piechart";
import {StackedBarChart} from "./stackedbar";
import {ReportOptions} from "./reportoptions";
import {BarChart} from "./barchart";
import {useUsers} from "../ListBoxes/userService";
import html2canvas from "html2canvas";
import {AppBar, Button, IconButton, Paper, Toolbar, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import {UsersWithSameActivity} from "../Presence/presence";


export const OverviewPieChart = ({onCloseWindow}) => {
    const [selectedTherapists, setSelectedTherapists] = useState([]);
    const [selectedCodes, setSelectedCodes] = useState([]);
    const [selectedReportOptions, setSelectedReportOptions] = useState(null);
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substr(0, 10));
    const [formattedTherapists, setFormattedTherapists] = useState([])
    const {allTherapistsData, isLoading, error} = useFetchAllTherapistsData(formattedTherapists, year, month);
    const {codes} = useCodes();
    const {users: therapists} = useUsers(true);
    const [data, setData] = useState([])
    const [keys, setKeys] = useState([])


    useEffect(() => {
        const formattedTherapists = selectedTherapists.map(therapist => ({
            id: therapist.value,
            name: therapist.label
        }));
        setFormattedTherapists(formattedTherapists)
    }, [selectedTherapists, therapists]);


    useEffect(() => {
        if (codes === undefined || allTherapistsData.length === 0) {
            setData([])
            return
        }

        let counts = countTherapistDataIDs(allTherapistsData, selectedCodes)
        let chartData = transformCountsToChartData(counts, codes)
        let individualCounts = countTherapistDataPerID(allTherapistsData, selectedCodes)
        let individualChartData = transformForStackedBarChart(individualCounts, therapists, codes)

        if (selectedReportOptions.value === 'stacked' || selectedReportOptions.value === 'group') {
            if (individualChartData.length) {
                // Initialize a Set to collect unique keys
                const keySet = new Set();
                // Iterate over each entry to collect keys
                individualChartData.forEach(entry => {
                    Object.keys(entry).forEach(key => {
                        // Add key if it does not match the exclusion criteria
                        if (key !== 'therapist' && !key.endsWith('Color')) {
                            keySet.add(key);
                        }
                    });
                });

                // Convert the Set of keys to an array
                const newKeys = Array.from(keySet);
                // Now set the data and keys in state
                setData(individualChartData);
                setKeys(newKeys); // Set keys based on the new data
            } else {
                setData([])
            }
        } else {
            setData(chartData)
        }

    }, [allTherapistsData, selectedCodes, codes, selectedReportOptions]);

    const handleTherapistSelection = (options) => {
        setSelectedTherapists(options)
    };
    const handleCodeSelection = (options) => {
        setSelectedCodes(options)
    };
    const handleReportOptions = (options) => {
        setSelectedReportOptions(options)
    };
    const handleDateChange = (year, month, day) => {
        const date = new Date(Date.UTC(year, month, day));
        const formattedDate = date.toISOString()
        setSelectedDate(formattedDate);
        setMonth(month + 1)
        setYear(year)
    };

    const exportToPNG = async () => {
        const input = document.getElementById('graphContainer');
        const exportButton = document.getElementById('exportButton'); // Ensure your export button has this ID

        // Temporarily hide the export button
        if (exportButton) exportButton.style.visibility = 'hidden';

        const canvas = await html2canvas(input);

        // Show the export button again after capturing
        if (exportButton) exportButton.style.visibility = 'visible';

        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = 'graph.png'; // Set the download file name
        document.body.appendChild(link); // Append the link to the document
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up and remove the link
    };

    return (<Paper elevation={3} sx={{padding: 3}} id="graphContainer">
            <AppBar position="static">
                <Toolbar variant="dense">
                    <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                        Monthly Report
                    </Typography>
                    <UsersWithSameActivity currentActivity={'chart'}/>
                    <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                        <CloseIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>


            <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}
                  sx={{width: '100%', margin: 'auto'}}>
                <Grid sx={{flexGrow: 1}} item>
                    <TherapistSelect onOptionsChange={handleTherapistSelection}/>
                </Grid>
                <Grid sx={{flexGrow: 1}} item>
                    <CodeSelect onOptionsChange={handleCodeSelection}/>
                </Grid>
                <Grid sx={{flexGrow: 1}} item>
                    <ReportOptions onOptionsChange={handleReportOptions}
                           customStyles={{
                               control: (provided) => ({
                                   ...provided,
                                   minWidth: '150px',
                                   maxWidth: '150px',
                               }),
                           }}/>
                </Grid>
            </Grid>

            {
                selectedReportOptions && selectedReportOptions.value === 'bar' ? (
                    <BarChart data={data} key="bar-chart"/>
                ) : selectedReportOptions && selectedReportOptions.value === 'pie' ? (
                    <PieChart data={data} key="pie-chart"/>
                ) : selectedReportOptions && selectedReportOptions.value === 'stacked' ? (
                    <StackedBarChart data={data} keys={keys} key="stacked-chart"/>
                ) : selectedReportOptions && selectedReportOptions.value === 'group' ? (
                    <StackedBarChart data={data} keys={keys} style={'grouped'} key="grouped-chart"/>
                ) : <>No Selection Made</>
            }

            <AppBar position="static" color="primary" sx={{top: 'auto', bottom: 0}}>
                <Toolbar variant='dense'>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item sx={{flexGrow: 1}}>
                            <DatePickerComponent onDateChange={handleDateChange} style={{flex: 0}}/>
                        </Grid>
                        <Grid item>
                            <Button id="exportButton" variant="contained" size="small" onClick={exportToPNG}>Export</Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </Paper>
    )
};

