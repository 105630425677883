import styled from '@emotion/styled'

export const sidebarDefaultWidth = '300px'

export const SidebarHeader = styled.div`
    background: ${(props) => props.theme.titlebg};
    color: ${(props) => props.theme.titlefg};
    padding: 10px;
    font-size: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Nunito', sans-serif;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Sidebar = styled.div`
    position: fixed;
    width: ${({ width }) => width || '200px'};
    height: 100%; /* Full height */
    ${({ alignment }) => alignment === 'right' ? 'right: 0;' : 'left: 0;'}
    top: 0; /* Align to the top */
    overflow-y: auto; /* Scrollable if content overflows */
    background-color: ${(props) => props.theme.solidbg};
    border-top-${({ alignment }) => alignment === 'right' ? 'left' : 'right'}-radius: 32px;
    border-bottom-${({ alignment }) => alignment === 'right' ? 'left' : 'right'}-radius: 32px;
`;

export const ShrinkSidebarContainer = styled.div`
    position: fixed;
    width: ${({ isVisible }) => (isVisible ? '200px' : '20px')}; // Ensure proper concatenation
    height: 100%;
    ${({ alignment }) => alignment === 'right' ? 'right: 0;' : 'left: 0;'}
    top: 0;
    overflow-y: auto;
    background-color:  ${(props) => props.theme.solidbg};
    transition: width 1.0s; /* Smooth transition for sliding effect */
    z-index: 100;
    &:hover {
        width: 200px; /* Adjust based on your requirement or use a prop */
    }
    border-top-${({ alignment }) => alignment === 'right' ? 'left' : 'right'}-radius: 32px;
    border-bottom-${({ alignment }) => alignment === 'right' ? 'left' : 'right'}-radius: 32px;
`;

export const ShrinkContent = styled.div`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1.0s ease-in-out;
`;




