import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import Users from "./users";
import {WorkingHours} from "./hours";
import Profiles from "./profile";
import {UsersWithSameActivity} from "../Presence/presence";

export function UserManagementApp({ onCloseWindow }) {
    const [screen, setScreen] = useState('users'); // Default screen

    const renderContent = () => {
        switch (screen) {
            case 'users':
                return <Users/>
            case 'profile':
                return <Profiles/>
            case 'hours':
                return <WorkingHours onCloseWindow={onCloseWindow}/>
            case 'edit':
                return <div>Edit Users content</div>;
            default:
                return <div>Default content</div>;
        }
    };

    return (
        <Paper sx={{ width: '90%', maxHeight: '85%', padding: 3, overflow: 'auto' }}>
        <AppBar position="static">
                <Toolbar>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                User Management
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" size="small" onClick={() => setScreen('users')}>Users</Button>
                        </Grid>
                        <Grid item>
                            <Button color="inherit" size="small" onClick={() => setScreen('profile')}>Profiles</Button>
                        </Grid>
                        <Grid item>
                            <UsersWithSameActivity currentActivity={'users'}/>
                        </Grid>
                    </Grid>
                    <IconButton edge="end" color="inherit" onClick={onCloseWindow}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style ={{marginTop:25}}>
                {renderContent()}
            </div>
        </Paper>
    );
}
