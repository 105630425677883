import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCodes } from "../Codes/data";
import { Box, Chip } from '@mui/material';

export const CodeSelect = ({ onOptionsChange, defaultSelectedOptions }) => {
    const { codes } = useCodes();
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        const formattedOptions = codes.map(code => ({
            value: code.id,
            label: code.text,
            code: code.code,
            emoji: code.emoji,
            background: code.background,
            foreground: code.foreground
        }));
        setSelectOptions(formattedOptions);
    }, [codes]);

    return (
        <Autocomplete
            multiple
            options={selectOptions}
            getOptionLabel={(option) => option.label}
            defaultValue={defaultSelectedOptions}
            onChange={(event, newValue) => {
                onOptionsChange(newValue);
            }}
            renderOption={(props, option) => (
                <li {...props} key={option.value}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Chip label={option.code} size="small" sx={{ color: option.foreground, bgcolor: option.background, marginRight: 1 }} />
                        <span>{option.label} {option.emoji ? `(${option.emoji})` : ''}</span>
                    </Box>
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Codes"
                    placeholder="Select Codes"
                />
            )}
        />
    );
};
