// NotePopupComponent.js
import React, {useState} from 'react';
import {NotePopup} from "./notes";
import {setNoteEntry} from "../Booking/dataServce";

export const NoteEditor = ({ title, visible, position, onClose, noteInfo }) => {
   if (!visible || noteInfo?.hour < 0) return null;
   return (
        <NotePopup
            title={title}
            visible={visible}
            position={position}
            onClose={onClose}
            onSave={(noteContent, cellInfo) => {
                if(cellInfo != null) {
                    setNoteEntry(cellInfo, noteContent);
                }
            }}
            noteInfo={noteInfo}
        />
    );
};
