import React, {useMemo} from "react";
import styled from "@emotion/styled";
import {TableCell} from "@mui/material";
import {getContrastingColor} from "../Color/color";
import {getHeatMapColor} from "./monthly";
import {halfLuminance, RenderCodeBadge} from "./codebadge";
import Avatar from "@mui/material/Avatar";

export const CustomGridCell = styled(TableCell, {
    shouldForwardProp: (prop) => !['isSelected', 'showTriangle', 'triangleColor', 'backgroundColor', 'color','cellIsToday'].includes(prop),
})(({theme, isSelected, showTriangle, triangleColor, backgroundColor, color}) => ({
    position: 'relative',
    backgroundColor,
    userSelect: 'none', // Disable text selection
    cursor: 'pointer',
    color,
    // Overlay when selected
    '&::after': isSelected.selected ? {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: isSelected.source === "me" ? 'rgba(0, 255, 0, 0.3)' : 'rgba(220, 220, 220, 0.3)' , // Darker overlay for selection
        pointerEvents: 'none',
        zIndex: 1,
    } : {},
    // Triangle indicator
    '&::before': showTriangle ? {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        borderTop: `10px solid ${triangleColor || 'rgba(255, 255, 0, 0.8)'}`, // Fallback color if triangleColor is not provided
        borderLeft: '10px solid transparent',
        width: 0,
        height: 0,
        display: 'block',
        zIndex: 2,
    } : {}
}));


export function getCellStatus(hour, idx) {
    // Check if 'status' exists, is not undefined, and is an object
    if (hour && hour.status !== undefined && typeof hour.status === 'object') {
        // Check if the key 'idx' exists in the 'status' map
        if (hour.status.hasOwnProperty(idx)) {
            return hour.status[idx].codeId;
        }
    }
    // Return undefined if 'status' is undefined, not an object, or 'idx' is not a key in the map
    return undefined;
}


export const useGridCellData = (dayData, hour, codes, displayMode, isDarkMode, isHeatMap) => {
    return useMemo(() => {
        const hourData = dayData?.hours[hour] || { status: {}, notes: '' };
        const statusEntries = Object.entries(hourData.status); // Convert status map to an array of entries
        const baseColor = isDarkMode ? '#333' : '#fff';
        const baseTextColor = isDarkMode ? '#fff' : '#000';

        // Create an array of data objects, one for each status entry
        const dataArray = statusEntries.map(([index, status]) => {
            const noteForHour = hourData.notes || '';

            const codeEntry = codes.find(c => c.id === status.codeId);
            if (codeEntry && codeEntry.emoji === '') {
                codeEntry.emoji = codeEntry.code;
            }

            const hasNotes = noteForHour.trim() && noteForHour.trim() !== "" && noteForHour.trim() !== "<p></p>";

            let hl = '';
            if (codeEntry && codeEntry.background) {
                hl = halfLuminance(codeEntry.background);
            }

            return {
                code: codeEntry?.code || '',
                content: displayMode === 'code' ? codeEntry?.code : codeEntry?.emoji,
                backgroundColor: codeEntry?.background || baseColor,
                foregroundColor: codeEntry?.foreground || baseTextColor,
                description: codeEntry?.text || '',
                emoji: codeEntry?.emoji || '',
                hasNote: hasNotes,
                showTriangle: hasNotes,
                triangleColor: getContrastingColor(codeEntry?.background || baseColor) || baseTextColor,
                halfLuminance: hl,
                duration: status.minutes
            };
        });

        return dataArray;
    }, [dayData, hour, codes, displayMode, isDarkMode, isHeatMap]);
};

export function getCellCodeDataForHour(dayData, hour, idx, codes, displayMode, isDarkmode = false) {

    let codeData = {
        code: '',
        backgroundColor: '#fff',
        foregroundColor: '#000',
        description: "",
        content: ""
    };

    if (isDarkmode) {
        codeData.backgroundColor = '#0'
        codeData.foregroundColor = '#fff'
    }

    if (dayData && dayData.hours[hour]) {
        const codeEntry = codes.find(c => c.id === getCellStatus(dayData.hours[hour], 0));
        if (codeEntry) {
            codeData = {
                code: codeEntry.code,
                content: displayMode === 'code' ? codeEntry.code : codeEntry.emoji ?
                    <span style={{fontSize: "24px"}}>{codeEntry.emoji}</span> : codeEntry.code,
                backgroundColor: codeEntry.background,
                foregroundColor: codeEntry.foreground,
                description: codeEntry.text,
                emoji: codeEntry.emoji,
                halfLuminance: codeEntry.halfLuminance,
            };
        }
    }
    return codeData;
}


function isToday(selectedDate, day) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth(); // 0-based index
    const currentDate = today.getDate();
    const dateParts = selectedDate.split('-');
    const year = parseInt(dateParts[0], 10)
    const month = parseInt(dateParts[1], 10) - 1
    return currentYear === year && currentMonth === month && currentDate === day;
}



export const GridCell = ({
                             selectedDate,
                             day,
                             dayData,
                             hour,
                             codes,
                             displayMode,
                             isDarkMode,
                             onContextMenu,
                             onMouseDown,
                             onMouseEnter,
                             onMouseLeave,
                             onClick,
                             onDoubleClick,
                             isSelected,
                             isHeatMap,
                             cellSize
                         }) => {

    const cellData = useGridCellData(dayData, hour, codes, displayMode, isDarkMode, isHeatMap);

    const cellIsToday = isToday(selectedDate, day) && !isSelected.selected;

    const overlayStyle = cellIsToday ? {
        backgroundColor: 'rgba(0, 147, 203, 0.2)',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
    } : {};

    const commonStyles = {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    if (dayData?.hours[hour]) {
        let changesCount = dayData?.hours[hour]?.changes?.length
        if (changesCount === undefined) {
            changesCount = 0
        }
        if (cellData && cellData.length > 0) {
            const heatMapColor = getHeatMapColor(changesCount);
            cellData[0].backgroundColor = isHeatMap ? heatMapColor : cellData[0].backgroundColor;
            cellData[0].halfLuminance = halfLuminance(cellData[0].backgroundColor);
            if (isHeatMap && cellData[0].code === "") {
                cellData[0].code = " ";
            }
        }
    }

    // Aggregate all codes from cellData into a single array
    let allCodes = cellData.map(data => ({
        code: displayMode === "code" ? data.code : data.emoji,
        duration: data.duration || 60,
        background: data.backgroundColor,
        foreground: data.foregroundColor,
        halfLuminance: data.halfLuminance,
    }));
    if (allCodes.length === 1 && !allCodes[0].code) {
        allCodes = []; // Clear the array if the only entry has no code
    }

    return (
        <CustomGridCell
            sx={(theme) => ({
                borderLeft: isSelected.selected ? '2px solid rgba(224, 224, 224, 1)' : '1px dashed rgba(224, 224, 224, 1)',
                borderRight: isSelected.selected ? '2px solid rgba(224, 224, 224, 1)' : '1px dashed rgba(224, 224, 224, 1)',
                borderTop: isSelected.selected ? '2px solid rgba(224, 224, 224, 1)' : '1px dashed rgba(224, 224, 224, 1)',
                borderBottom: isSelected.selected ? '2px solid rgba(224, 224, 224, 1)' : '1px dashed rgba(224, 224, 224, 1)',
                height: `${cellSize}px`,
                backgroundColor: 'defaultColor', // Ensure 'defaultColor' is a valid color or variable
                justifyContent: 'center',
                alignItems: 'center'
            })}

            showTriangle={allCodes?.length && cellData[0].showTriangle}
            triangleColor={allCodes?.length && cellData[0].triangleColor}
            onContextMenu={onContextMenu}
            onMouseDown={onMouseDown}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
            isSelected={isSelected}
            cellIsToday={cellIsToday}>
            {cellIsToday && <div style={overlayStyle}></div>}

            {allCodes.length !== 0 && (
            <div style={{
                ...commonStyles,
            }}>
                <RenderCodeBadge width={cellSize} height={cellSize} radius={(cellSize/2)-2} codes={allCodes}/>
            </div>
            )}

            {isSelected.selected &&  isSelected.source !== "me" && (
                <div style={{
                    ...commonStyles,
                }}>
                    <Avatar src={isSelected.photoURL} alt="User Profile" sx={{width: 24, height: 24, opacity:1.0}} ></Avatar>
                </div>
            )}

        </CustomGridCell>
    );
};
